<template>
  <div class="view-ranking-customer">
    <h5 class="mb-4">Ranking de Clientes de Analistas</h5>

    <div class="row">
      <div class="col-lg-6">
        <search-filter
          placeholder="Pesquisar por nome do cliente"
          :params="params"
          @search="search"
        />
      </div>
      <div class="col-1">
        <button
          v-if="!!listOfActiveFilters.length"
          class="btn btn-sm btn-outline-warning"
          @click="removeFilters"
        >
          Limpar
        </button>
      </div>
      <div class="col">
        <button class="btn btn-success" @click="onDownloadAllClients">
          <b-spinner v-if="loadingDownloadClients" />
          <i v-if="!loadingDownloadClients" class="icon-download mr-1" />
          <span v-if="!loadingDownloadClients">
            Download de todos os clientes
          </span>
        </button>
      </div>
      <div class="col">
        <button class="btn btn-success" @click="onDownloadAllAnswers">
          <b-spinner v-if="loadingDownloadAnswers" />
          <i v-if="!loadingDownloadAnswers" class="icon-download mr-1" />
          <span v-if="!loadingDownloadAnswers">
            Download de todas as respostas
          </span>
        </button>
      </div>
    </div>

    <div v-if="!!selected.length" class="row pt-2">
      <div class="col-2 m-0 text-muted">Clientes selecionados:</div>
      <div v-for="(it, index) in selected" :key="index" class="col p-0 m-0">
        <button class="btn btn-sm customer-indicator">
          <span class="text-dark">{{ it.name }}</span>
        </button>
      </div>
    </div>

    <button
      v-if="!!selected.length"
      class="btn btn-outline-dark"
      @click="downloadSelecteds"
    >
      <b-spinner v-if="loadingDonwloadSelecteds" />
      <i v-if="!loadingDonwloadSelecteds" class="icon-download mr-1" />
      <span v-if="!loadingDonwloadSelecteds">
        Fazer download dos selecionados
      </span>
    </button>

    <div v-if="!!listOfActiveFilters.length" class="row pt-2">
      <div class="col-2 m-0 text-muted">Filtros ativos:</div>
      <div
        v-for="(filter, index) in listOfActiveFilters"
        :key="index"
        class="col p-0 m-0"
      >
        <button class="btn btn-sm letter-indicator">
          <span class="text-dark">{{ filter }}</span>
        </button>
      </div>
    </div>

    <div
      v-if="!loading && !rankingCustomers.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há clientes cadastrados</strong>
      <span class="text-secondary">Cadastre um cliente para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :loading="loading"
      :columns="columns"
      :data="rankingCustomers"
      :is-selectable="false"
    />

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </div>
</template>

<script>
import SearchFilter from "@/components/adminRanking/SearchFilter";
import TableCellName from "@/components/adminRanking/TableCellName";
import TableCellCharMapPercent from "@/components/adminRanking/TableCellCharMapPercent";
import TableCellCharMap from "@/components/adminRanking/TableCellCharMap";
import TableCellMasterKey from "@/components/adminRanking/TableCellMasterKey";
import TableCellIQVForm from "@/components/adminRanking/TableCellIQVForm";
import { createCustomersCSVHref } from "@/functions/csv";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { SearchFilter },
  data() {
    return {
      loading: false,
      loadingDownloadClients: false,
      loadingDownloadAnswers: false,
      loadingDonwloadSelecteds: false,
      error: false,
      params: {
        page: 1,
        search: "",
        sortedBy: "",
        order: "",
        analyst: "",
        company: "",
        sector: "",
        country: "",
        state: "",
        city: "",
        client: ""
      },
      columns: [
        {
          label: "NOME",
          component: TableCellName
        },
        {
          label: "MAPA DOS CARACTERES (%)",
          component: TableCellCharMapPercent
        },
        {
          label: "FORMULÁRIO DE ACOMPANHAMENTO INDIVIDUAL",
          component: TableCellIQVForm
        },
        {
          label: "MAPA DOS CARACTERES",
          component: TableCellCharMap
        },
        {
          label: "CHAVE MESTRA",
          component: TableCellMasterKey
        }
      ]
    };
  },
  computed: {
    ...mapState("ranking", ["rankingCustomers", "selected", "pagination"]),
    listOfActiveFilters() {
      const active = [];
      const MAP_NAMES = {
        country: "País",
        uf: "UF",
        city: "Cidade",
        company: "Empresa",
        sector: "Setor",
        sortedBy: "Ordenando por:",
        order: "Ordenação ativa:"
      };

      for (let key in this.params) {
        if (key === "client" || key === "analyst") continue;
        if (this.params[key].length) {
          active.push(`${MAP_NAMES[key]} ${this.params[key]}`);
        }
      }

      return active;
    }
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("ranking/getCustomers", this.params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(params) {
      this.params = params;
      this.dispatch();
    },
    onDownloadAllClients() {
      this.loadingDownloadClients = true;
      this.$store
        .dispatch("ranking/downloadAllClients")
        .then(response => {
          const a = document.createElement("a");
          const blobUrl = URL.createObjectURL(response.data);
          a.href = blobUrl;
          const csvName = `all_clients_${moment().format(
            "YYYYMMDD[_]HH[h]_mm[m]"
          )}.csv`;
          a.setAttribute("download", csvName);
          a.click();
          URL.revokeObjectURL(blobUrl);
          this.$message.success("Relatório gerado");
        })
        .catch(error => {
          this.$message.error("Erro ao gerar relatório de todos os clientes");
        })
        .finally(() => {
          this.loadingDownloadClients = false;
        });
    },
    onDownloadAllAnswers() {
      this.loadingDownloadAnswers = true;
      this.$store
        .dispatch("ranking/downloadAllAnswers")
        .then(response => {
          const a = document.createElement("a");
          const blobUrl = URL.createObjectURL(response.data);
          a.href = blobUrl;
          const csvName = `all_answers_${moment().format(
            "YYYYMMDD[_]HH[h]_mm[m]"
          )}.csv`;
          a.setAttribute("download", csvName);
          a.click();
          URL.revokeObjectURL(blobUrl);
          this.$message.success("Relatório gerado");
        })
        .catch(error => {
          this.$message.error("Erro ao gerar relatório de todos as respostas");
        })
        .finally(() => {
          this.loadingDownloadAnswers = false;
        });
    },
    downloadSelecteds() {
      try {
        this.loadingDonwloadSelecteds = true;
        const fileName = `${moment().format(
          "YYYYMMDD"
        )}-ranking_de_clientes_de_analistas.csv`;
        const a = document.createElement("a");
        const blobUrl = createCustomersCSVHref(this.selected);
        a.href = blobUrl;
        a.setAttribute("download", fileName);
        a.click();
        URL.revokeObjectURL(blobUrl);
        this.$message.success("Relatório gerado");
      } catch (error) {
        this.$$message.error("Erro ao gerar CSV com clientes selecionados");
      } finally {
        this.loadingDonwloadSelecteds = false;
      }
    },
    removeFilters() {
      this.params = {
        page: 1,
        search: "",
        sortedBy: "",
        order: "",
        analyst: "",
        company: "",
        sector: "",
        country: "",
        state: "",
        city: "",
        client: ""
      };
      this.dispatch();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-ranking-customer {
  .empty {
    font-size: 14px;
    text-align: center;
  }

  .letter-indicator {
    background: rgba(1, 65, 108, 0.1);
  }

  .customer-indicator {
    border: 1px solid rgba(1, 65, 108, 0.1);
    border-radius: 3px;
  }
}
</style>
