<template>
  <action-dispatcher
    action="analysts/getAnalyse"
    :parameters="{
      analyst_id: $route.params.analyst_id,
      analyse_id: $route.params.analyse_id
    }"
  >
    <div class="view-combined-analyse">
      <div class="col-lg-12">
        <h5 class="mb-4">
          <router-link
            class="back"
            :to="{
              name: 'admin-analyses-analyst-list',
              params: { id: $route.params.analyst_id }
            }"
          >
            <i class="icon-chevron-left"></i>
          </router-link>
          Resultado da análise
        </h5>

        <div
          class="d-flex flex-column flex-lg-row justify-content-end align-items-center mb-4"
        >
          <div class="d-flex mr-3">
            <b-form-checkbox v-model="averageLineVisible" class="mr-2" />
            <span>Exibir linha média do resultado</span>
          </div>
          <button-send-report :analyse="analyse" />
        </div>

        <div class="row justify-content-center align-items-center">
          <div class="col-lg-1">
            <span class="text-muted">Clientes:</span>
          </div>
          <div class="col">
            <participants-list :participants="analyse.participants" />
          </div>
        </div>
        <div class="card-aspect mb-4">
          <combined-analyses-bar-chart
            v-if="!!analyse.participants && !!analyse.participants.length"
            class="pt-4"
            :averageLineVisible="averageLineVisible"
            :customers="analyse.participants"
          />
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import ButtonSendReport from "@/components/analyses/ButtonSendReport";
import CombinedAnalysesBarChart from "@/components/charts/CombinedAnalysesBarChart";
import { mapState } from "vuex";

export default {
  components: {
    CombinedAnalysesBarChart,
    ButtonSendReport
  },
  data() {
    return {
      averageLineVisible: true,
      loading: false
    };
  },
  computed: {
    ...mapState("analysts", ["analyse"])
  }
};
</script>
