<template>
  <div class="char-map-body-part-selector">
    <h6 class="mb-3">Partes do corpo</h6>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <button
          v-for="(item, index) in items"
          :key="index"
          class="btn btn-light"
          :class="{ selected: value === index }"
          :disabled="index > current"
          @click="$emit('input', index)"
        >
          <img :src="item.icon" />
          {{ item.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    points: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      items: [
        {
          name: "Cabeça",
          value: "head",
          icon: require("@/assets/chart/head.svg")
        },
        {
          name: "Olhos",
          value: "eyes",
          icon: require("@/assets/chart/eye.svg")
        },
        {
          name: "Boca",
          value: "mouth",
          icon: require("@/assets/chart/mouth.svg")
        },
        {
          name: "Tronco",
          value: "back",
          icon: require("@/assets/chart/back.svg")
        },
        {
          name: "Quadril",
          value: "hip",
          icon: require("@/assets/chart/breech.svg")
        },
        {
          name: "Pernas",
          value: "legs",
          icon: require("@/assets/chart/legs.svg")
        }
      ]
    };
  },
  computed: {
    current() {
      for (let i in this.points) {
        if (this.points[i] > 0) {
          return i;
        }
      }
      return 9999;
    }
  }
};
</script>

<style lang="scss" scoped>
.char-map-body-part-selector {
  button {
    display: flex;
    align-items: center;
    border: none;

    img {
      height: 24px;
      width: 24px;
      margin-right: 4px;
    }

    &:not(.selected) {
      background: transparent;
      opacity: 0.6;

      &:hover {
        background: #f0f0f0;
      }
    }
  }
}
</style>
