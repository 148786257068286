export default {
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCustomer(state, customer) {
    state.customer = customer;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },

  setCountries(state, countries) {
    state.countries = countries.map(c => ({ id: c, name: c }));
  },
  setStates(state, states) {
    state.states = states.map(s => ({ id: s, name: s }));
  },
  setCities(state, cities) {
    state.cities = cities.map(c => ({ id: c, name: c }));
  },
  setCompanies(state, companies) {
    state.companies = companies.map(c => ({ id: c, name: c }));
  },
  setSectors(state, sectors) {
    state.sectors = sectors.map(c => ({ id: c, name: c }));
  }
};
