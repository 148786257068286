<template>
  <div class="iqv-step-1">
    <h6 class="mb-4">Etapa {{ step }}: {{ name }}</h6>

    <form-question
      v-for="(question, index) in questions"
      :key="index"
      v-model="answers[index]"
      :id="question.id"
      :title="question.title"
      :type="question.type"
      :min="question.min"
      :max="question.max"
      :min-text="question.min_text"
      :max-text="question.max_text"
      :alternatives="question.alternatives"
      :has-other="question.has_other"
      :mandatory="question.mandatory"
      :show-error="showErrors"
    />

    <div class="d-flex">
      <button
        v-if="!first"
        class="btn px-4 btn-outline-success"
        @click="$emit('back')"
      >
        Voltar
      </button>
      <div class="flex-fill"></div>
      <button
        class="btn px-4"
        :class="{
          'btn-outline-success': isValid,
          'btn-outline-secondary disabled': !isValid
        }"
        @click="next"
      >
        <loading v-if="last" :show="loading">Enviar</loading>
        <span v-else> Próxima etapa <i class="icon-arrow-right"></i> </span>
      </button>
    </div>
  </div>
</template>

<script>
import FormQuestion from "./FormQuestion";
import { get, difference } from "lodash";

export default {
  components: { FormQuestion },
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    step: {
      type: Number,
      default: 1
    },
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const nQuestions = get(this.form, "questions.length", 0);

    return {
      showErrors: false,
      answers: {
        ...Array(nQuestions).fill(null)
      }
    };
  },
  computed: {
    answersArray() {
      return Object.values(this.answers);
    },
    name() {
      return get(this.form, "name");
    },
    description() {
      return get(this.form, "description");
    },
    questions() {
      return get(this.form, "questions", []);
    },
    isValid() {
      const answersIds = this.answersArray.map(a => a && a.question_id);

      const isMandatoryFilled = this.questions
        .filter(q => q.mandatory)
        .reduce((acc, cur) => {
          return answersIds.indexOf(cur.id) !== -1 && acc;
        }, true);

      const isOtherOptionEmpty = this.answersArray
        .filter(a => a)
        .filter(a => a.other_text == "" && a.other_text.length === 0);

      return isMandatoryFilled && !isOtherOptionEmpty.length;
    }
  },
  methods: {
    next() {
      if (!this.isValid) {
        this.showErrors = true;
        const diff = difference(
          this.questions.filter(q => q.mandatory).map(({ id }) => id),
          this.answersArray
            .filter(q => !!q)
            .map(({ question_id }) => question_id)
        );

        //Check the answers that user checked "other" option and not filled other
        const answersWithOther = this.answersArray.filter(a => {
          if (!a) return false;
          return a.other_text == "" && a.other_text.length === 0;
        });
        if (answersWithOther.length) {
          const el2 = document.getElementById(answersWithOther[0].question_id);
          el2.scrollIntoView();
        }

        if (diff.length) {
          const el = document.getElementById(diff[0]);
          el.scrollIntoView();
        }
        // window.scrollTo(0, 0);
      } else {
        let event = "next";
        if (this.last) {
          event = "save";
        }
        this.$emit(event, this.answersArray);
      }
    }
  }
};
</script>
