<template>
  <div class="calendar-view">
    <div v-if="loadingPage" class="col text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>

    <div class="col text-center p-5" v-else-if="errorPage">
      Erro!
      <button class="btn-link bg-transparent border-0" @click="loadCustomer">
        Tentar novamente
      </button>
    </div>
    <div v-else>
      <h4 class="mb-3">Agenda</h4>
      <div class="row">
        <div class="col-lg-12">
          <div class="row mb-5">
            <div class="col-10 col-lg-2 d-flex align-items-center pr-0">
              <h5>
                {{ dateText }}
              </h5>
            </div>
            <div class="col-1 d-flex align-items-center p-0">
              <button class="btn" @click="previous">
                <i class="icon-chevron-left" />
              </button>
            </div>
            <div class="col-1 d-flex align-items-center p-0">
              <button class="btn" @click="next">
                <i class="icon-chevron-right" />
              </button>
            </div>
          </div>

          <schedules-table />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SchedulesTable from "@/components/schedules/SchedulesTable";
import { MAP_MONTH_NAME } from "@/functions/helpers";
import { mapState } from "vuex";

export default {
  components: { SchedulesTable },
  data() {
    return {
      loadingPage: false,
      errorPage: false
    };
  },
  computed: {
    ...mapState("schedules", ["month", "year"]),
    dateText() {
      return `${MAP_MONTH_NAME[this.month].name} ${this.year}`;
    }
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loadingPage = true;
      this.$store
        .dispatch("schedules/get", {})
        .then()
        .catch(error => {
          this.$message.error("Erro ao solicitar agendamentos");
        })
        .finally(() => {
          this.loadingPage = false;
        });
    },
    next() {
      if (this.month === 12) {
        this.$store.commit("schedules/setMonth", 1);
        this.$store.commit("schedules/setYear", this.year + 1);
        this.year++;
      } else if (this.month + 1 <= 12)
        this.$store.commit("schedules/setMonth", this.month + 1);
    },
    previous() {
      if (this.month === 1) {
        this.$store.commit("schedules/setMonth", 12);
        this.$store.commit("schedules/setYear", this.year - 1);
      } else if (this.month - 1 > 0)
        this.$store.commit("schedules/setMonth", this.month - 1);
    }
  }
};
</script>
<style lang="scss" scoped>
.calendar-view {
  i {
    font-size: 22px;
    min-width: 30px;
    @media screen and (max-width: 991px) {
      color: #2178fb;
    }
  }
}
</style>
