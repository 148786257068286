import { saveUserInfo } from "../../setup/auth";

export default {
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/analysts", data)
        .then(resolve)
        .catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/analysts/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analysts?${queryString}`)
        .then(response => {
          context.commit("setAnalysts", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  delete(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .delete(`/analysts/${id}`)
        .then(resolve)
        .catch(reject)
    );
  },
  getDetails(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analysts/${id}`)
        .then(response => {
          context.commit("setAnalyst", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getClients(context, { id, params }) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analysts/${id}/clients?${queryString}`)
        .then(response => {
          context.commit("setCustomers", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  resendPassword(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .post("/password/resend_password", { analyst_id: id })
        .then(resolve)
        .catch(reject)
    );
  },
  upload(context, csv) {
    var formData = new FormData();
    formData.append("csv", csv);

    return new Promise((resolve, reject) => {
      window.axios
        .post("/analysts/uploadAnalysts", formData, {
          headers: { "Content-Type": "multipart/form-data" }
        })
        .then(resolve)
        .catch(reject);
    });
  },
  storeBatch(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/analysts/storeBatch", data)
        .then(resolve)
        .catch(reject);
    });
  },
  updateProfile(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/analysts/${data.id}`, data) // data is { ...allUserInformation }
        .then(function(response) {
          saveUserInfo(response.data.data);
          context.commit("user/setUser", response.data.data, { root: true });
          resolve(response);
        })
        .catch(error => {
          //This code will repeat in all requests that user is logged
          if (error.response.status === 401 || error.response.status === 403) {
            // Logout user because he is unauthorized for any reason
            removeToken();
            context.commit("user/reset", null, { root: true });
          }
          reject(error);
        });
    });
  },
  deleteBatch(context, ids) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/analysts/deleteBatch", { ids })
        .then(resolve)
        .catch(reject);
    });
  },
  getAnalyses(context, { analyst_id, params }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/analysts/${analyst_id}/analyses`, { params, limit: 20 })
        .then(response => {
          context.commit("setAnalyses", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  getAnalyse(context, { analyst_id, analyse_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/analysts/${analyst_id}/analyses/${analyse_id}`)
        .then(response => {
          context.commit("setAnalyse", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  downloadAnalyse(context, { analyst_id, analyse_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/analysts/${analyst_id}/analyses/${analyse_id}/report`, {
          responseType: "blob"
        })
        .then(resolve)
        .catch(reject);
    });
  }
};
