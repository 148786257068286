import Vue from "vue";

import ActionDispatcher from "./ActionDispatcher.vue";
import HollowButton from "./HollowButton.vue";
import FormGroup from "./FormGroup.vue";
import Loading from "./Loading.vue";
import Snackbar from "./Snackbar.vue";
import Confirmation from "./Confirmation.vue";
import Warning from "./Warning.vue";
import ImageInput from "./ImageInput.vue";
import DataTable from "./DataTable.vue";
import Pagination from "./Pagination.vue";
import ActionsDropdown from "./ActionsDropdown.vue";
import SearchSelect from "./SearchSelect.vue";

Vue.component("action-dispatcher", ActionDispatcher);
Vue.component("hollow-button", HollowButton);
Vue.component("form-group", FormGroup);
Vue.component("loading", Loading);
Vue.component("snackbar", Snackbar);
Vue.component("confirmation", Confirmation);
Vue.component("warning", Warning);
Vue.component("image-input", ImageInput);
Vue.component("data-table", DataTable);
Vue.component("pagination", Pagination);
Vue.component("actions-dropdown", ActionsDropdown);
Vue.component("search-select", SearchSelect);
