<template>
  <div class="profile-iqv-forms-answers">
    <data-table
      class="mt-3"
      v-if="!!iqvForms.length"
      :isSelectable="false"
      :columns="columns"
      :data="iqvForms.slice(0, 4)"
    >
      <template #action="{ item }">
        <button class="btn" @click="openFormAnswer(item)">
          <i class="icon-eye" /> Ver Resposta
        </button>
      </template>
    </data-table>
    <div v-else>Este cliente ainda não respondeu o formulário</div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      iqvForms: [],
      columns: [
        {
          label: "ID",
          value: "id",
          formatter: item => `<strong>${item.id}</strong>`
        },
        {
          label: "IQV",
          value: "iqv",
          formatter: item => `<strong>${item.iqv}</strong>`
        },
        {
          label: "Data",
          value: "created_at",
          formatter: item =>
            `<strong>${moment(item.created_at).format("DD/MM/YYYY")}</strong>`
        },
        {
          label: "Versão do formulário",
          value: "created_at",
          formatter: item => `<strong>#${item.form_version_id}</strong>`
        }
      ]
    };
  },
  mounted() {
    this.$store
      .dispatch("lifeQuality/customerAnswers", this.customer.id)
      .then(response => {
        this.iqvForms = response.data.data;
      })
      .catch(error => {
        this.$message.error("Erro ao solicitar versões dos formulários");
      });
  },
  methods: {
    openFormAnswer(item) {}
  }
};
</script>

<style lang="scss" scoped>
.profile-iqv-forms-answers {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;

  @media screen and (max-width: 991px) {
    padding: 8px;
    span {
      font-size: 12px;
    }
    small {
      font-size: 10px;
    }
  }
}
</style>
