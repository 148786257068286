<template>
  <div class="analyses-participants-list d-flex flex-wrap mx-n2 my-2">
    <small v-if="participants.length === 0" class="text-secondary mx-2"
      >Nenhum cliente selecionado</small
    >
    <div
      v-for="(customer, index) in participants"
      :key="index"
      class="rounded-bg mx-2 my-1 d-flex justify-content-center align-items-center"
    >
      <strong class="mr-2">
        {{
          customer.name
            .match(/\b(\w)/g)
            .join("")
            .slice(0, 2)
            .toUpperCase()
        }}
      </strong>
      {{ customer.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    participants: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
.analyses-participants-list {
  .rounded-bg {
    border: 1px solid #e9edf2;
    box-sizing: border-box;
    border-radius: 4px;
    background: #ffffff;
    padding: 4px 8px;
    white-space: nowrap;
  }
}
</style>
