<template>
  <button class="btn btn-sm d-flex">
    <span v-if="!item.has_master_facts">Não possui nenhuma chave mestra</span>
    <div v-else class="d-flex flex-column justify-content-start">
      <strong class="text-left">Realizado</strong>
      <span class="text-left text-muted">{{ stepUpdate }}</span>
      <span class="text-left text-muted">{{ reportSend }}</span>
      <span class="text-left text-muted">{{ driveds }}</span>
      <span class="text-left text-muted">{{ completeds }}</span>
    </div>
  </button>
</template>

<script>
import moment from "moment";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stepUpdate() {
      const { last_fact_step_update } = this.item;
      if (!last_fact_step_update) return "Nenhuma etapa finalizada";

      return moment(last_fact_step_update).format(
        "[Último passo concluído em:] DD/MM/YYYY [às] HH:mm"
      );
    },
    reportSend() {
      const { last_master_key_report_send } = this.item;
      if (!last_master_key_report_send) return "Nenhum relatório enviado";

      return moment(last_master_key_report_send).format(
        "[Último relatório enviado:] DD/MM/YYYY [às] HH:mm"
      );
    },
    driveds() {
      const { master_key_driven_count } = this.item;
      if (!master_key_driven_count) return "";

      return `${master_key_driven_count} conduzida${
        master_key_driven_count !== 1 ? "s" : ""
      }`;
    },
    completeds() {
      const { master_key_completed_count } = this.item;
      if (!master_key_completed_count) return "";

      return `${master_key_completed_count} completa${
        master_key_completed_count !== 1 ? "s" : ""
      }`;
    }
  }
};
</script>
