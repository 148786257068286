<template>
  <action-dispatcher
    action="analyses/getDetails"
    :parameters="$route.params.id"
  >
    <div class="view-combined-analyse">
      <div class="col-lg-12">
        <h5 class="mb-4">
          <router-link class="back" :to="{ name: 'analyses' }">
            <i class="icon-chevron-left"></i>
          </router-link>
          Resultado da análise
        </h5>

        <div
          class="d-flex flex-column flex-lg-row justify-content-end align-items-center mb-4"
        >
          <div class="d-flex">
            <b-form-checkbox v-model="averageLineVisible" class="mr-2" />
            <span>Exibir linha média do resultado</span>
          </div>
          <button type="button" class="btn btn-primary px-5 m-3" @click="save">
            <b-spinner v-if="loading" variant="light" class="mx-4" />
            <i v-if="!loading" class="icon-save mr-1" />
            <span v-if="!loading">Salvar análise</span>
          </button>
          <button-send-report :analyse="analyse" />
        </div>

        <div class="row justify-content-center align-items-center">
          <div class="col-lg-1">
            <span class="text-muted">Clientes:</span>
          </div>
          <div class="col">
            <participants-list :participants="analyse.participants" />
          </div>
        </div>
        <div class="card-aspect mb-4">
          <combined-analyses-bar-chart
            v-if="!!analyse.participants && !!analyse.participants.length"
            class="pt-4"
            :averageLineVisible="averageLineVisible"
            :customers="analyse.participants"
          />
        </div>

        <table-selectable-customer :participants="analyse.participants" />
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import TableSelectableCustomer from "@/components/analyses/TableSelectableCustomer";
import ButtonSendReport from "@/components/analyses/ButtonSendReport";
import CombinedAnalysesBarChart from "@/components/charts/CombinedAnalysesBarChart";
import ParticipantsList from "@/components/analyses/ParticipantsList.vue";
import { mapState } from "vuex";

export default {
  name: "AnalysesAnalystDetails",
  components: {
    CombinedAnalysesBarChart,
    TableSelectableCustomer,
    ButtonSendReport,
    ParticipantsList
  },
  data() {
    return {
      averageLineVisible: true,
      loading: false
    };
  },
  computed: {
    ...mapState("analyses", ["analyse"])
  },
  methods: {
    save() {
      if (this.analyse.participants.length < 2) {
        this.$message.error(
          "Escolha ao menos 2 clientes como participantes da análise"
        );
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("analyses/update", {
          analyse_id: this.analyse.id,
          data: {
            ...this.analyse,
            participants: this.analyse.participants.map(p => p.client_id),
            receivers: this.analyse.receivers.map(r => r.client_id)
          }
        })
        .then(response => {
          this.$store.commit("analyses/setAnalyse", {
            ...this.analyse,
            ...response.data.data
          });
          this.$message.success("A análise foi salva com sucesso");
        })
        .catch(errors => {
          this.$message.error("Erro: " + errors.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
