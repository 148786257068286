import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      error: false,
      params: {
        page: 1,
        search: "",
        // letter: undefined,
        orderBy: "name"
        // sortedBy: undefined
      }
    };
  },
  watch: {
    "params.search"() {
      this.dispatch();
    }
  },
  computed: {
    ...mapState("customer", ["customers", "pagination"]),
    listOfActiveFilters() {
      const splitted = this.params.search.split(";");
      if (splitted[0] === "") return [];

      const names = {
        country: "País",
        uf: "UF",
        city: "Cidade",
        company: "Empresa",
        sector: "Setor"
      };
      const notIncludeName = splitted.filter(s => !s.match("name"));
      const prepared = notIncludeName.map(
        field => `${names[field.split(":")[0]]}: ${field.split(":")[1]}`
      );
      return prepared;
    }
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("customer/get", this.params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    }
  }
};
