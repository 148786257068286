export default {
  setAnalysts(state, analysts) {
    state.analysts = analysts;
  },
  setAnalyst(state, analyst) {
    state.analyst = analyst;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  setSelected(state, selected) {
    state.selected = selected;
  },
  setAnalyses(state, analyses) {
    state.analyses = analyses;
  },
  setAnalyse(state, analyse) {
    state.analyse = analyse;
  }
};
