import { setToken, setTokenInfo } from "../../setup/api";
import { saveUserInfo } from "../../setup/auth";

export default {
  me() {
    return new Promise((resolve, reject) => {
      window.axios
        .get("/me")
        .then(resolve)
        .catch(reject);
    });
  },

  login(context, { email, password }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/oauth/token", {
          client_id: 1,
          client_secret: "Y0P4JK0IN3GiL4WdEvcEGnPPB3ztJOrlHp4e0c1b",
          username: email,
          password: password,
          grant_type: "password",
          scope: "*"
        })
        .then(function(response) {
          setTokenInfo(response.data);
          setToken(response.data.access_token);
          saveUserInfo(response.data.user);
          context.commit("setUser", response.data.user);
          context.commit("setLogged", true);
          resolve();
        })
        .catch(reject);
    });
  },

  passwordRecovery(context, { email }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/password/forgot", { email })
        .then(resolve)
        .catch(reject);
    });
  },

  changePassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/password/change", data) // data is { old_password, new_password }
        .then(resolve)
        .catch(reject);
    });
  },

  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/password/reset", data) // data is { email, token, password }
        .then(response => {
          setTokenInfo(response.data);
          setToken(response.data.access_token);
          saveUserInfo(response.data.user);
          context.commit("setUser", response.data.user);
          context.commit("setLogged", true);
          resolve(response);
        })
        .catch(reject);
    });
  },

  resendAccess(context, ids) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/password/resendBatch", { ids })
        .then(resolve)
        .catch(reject);
    });
  }
};
