<template>
  <div class="view-analyses-new">
    <h5 class="mb-4">Nova análise</h5>

    <step-1 v-if="step === 1" @next="nextStep" />
    <step-2 v-else />
  </div>
</template>

<script>
import Step1 from "@/components/analyses/Step1";
import Step2 from "@/components/analyses/Step2";

export default {
  components: { Step1, Step2 },
  data() {
    return {
      step: 1
    };
  },
  mounted() {
    this.$store.commit("analyses/setAnalyse", {
      receivers: [],
      participants: []
    });
  },
  methods: {
    nextStep() {
      this.step = 2;
    }
  }
};
</script>
