<template>
  <div class="template-default d-flex flex-column flex-lg-row">
    <account-menu @logout="logout" />

    <nav class="d-lg-none bg-white">
      <div class="container-fluid d-flex align-items-center">
        <button class="btn btn-menu mr-3" @click="showingMenu = true">
          <i class="icon-menu"></i>
        </button>

        <router-link :to="{ name: 'home' }">
          <img class="py-2" src="../assets/logo.png" />
        </router-link>
      </div>
    </nav>

    <aside
      class="bg-white py-4 d-flex flex-column"
      :class="{ showing: showingMenu }"
    >
      <div class="mb-5 px-4 d-flex align-items-center">
        <button
          class="d-lg-none btn btn-menu mr-3"
          @click="showingMenu = false"
        >
          <i class="icon-x"></i>
        </button>

        <router-link
          :to="{ name: 'home' }"
          class="link-logo"
          @click.native="showingMenu = false"
        >
          <img src="../assets/logo.png" />
        </router-link>
      </div>

      <router-link
        v-if="$user.user && $user.user.user_type === 'admin'"
        class="btn text-secondary"
        :to="{ name: 'admin-analysts' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-oce-analysts"></i>
        Analistas
      </router-link>

      <router-link
        v-if="$user.user && $user.user.user_type === 'admin'"
        class="btn text-secondary"
        :to="{ name: 'ranking' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-ranking"></i>
        Ranking de analistas
      </router-link>

      <router-link
        v-if="$user.user && $user.user.user_type === 'admin'"
        class="btn text-secondary"
        :to="{ name: 'ranking-customers' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-ranking"></i>
        Ranking de clientes
      </router-link>

      <router-link
        class="btn text-secondary"
        :to="{ name: 'schedules' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-oce-calendar"></i>
        Agenda
      </router-link>

      <router-link
        class="btn text-secondary"
        :to="{ name: 'customer' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-oce-customer"></i>
        Clientes
      </router-link>

      <router-link
        v-if="$user.user && $user.user.user_type !== 'admin'"
        class="btn text-secondary"
        :to="{ name: 'analyses' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-oce-analyses"></i>
        Análise combinada
      </router-link>

      <router-link
        v-if="$user.user && $user.user.user_type === 'admin'"
        class="btn text-secondary"
        :to="{ name: 'admins' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-admin"></i>
        Administradores
      </router-link>

      <router-link
        v-if="$user.user && $user.user.user_type === 'admin'"
        class="btn text-secondary"
        :to="{ name: 'lq-versions' }"
        @click.native="showingMenu = false"
      >
        <i class="icon-pencil"></i>
        Formulários IQV
      </router-link>
    </aside>

    <main class="bg-light flex-fill p-4">
      <action-dispatcher action="user/me" @error="logout">
        <slot />
      </action-dispatcher>
    </main>

    <div
      v-if="showingMenu"
      class="d-lg-none backdrop"
      @click="showingMenu = false"
    ></div>
  </div>
</template>

<script>
import AccountMenu from "../components/template/AccountMenu";

export default {
  components: { AccountMenu },
  data() {
    return {
      showingMenu: false
    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.template-default {
  min-height: 100vh;
  overflow: hidden;

  .btn-menu {
    font-size: 24px;
  }

  main {
    @media screen and (min-width: 992px) {
      max-width: calc(100vw - 250px);
    }
  }

  aside {
    max-width: 250px;
    min-width: 250px;

    @media screen and (max-width: 991px) {
      transform: translateX(-100%);
      position: fixed;
      top: 0;
      bottom: 0;
      z-index: 1;
      box-shadow: 4px 0 16px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s;

      &.showing {
        transform: translateX(0);
      }
    }

    a:not(.link-logo) {
      text-align: left;
      padding-left: 24px;
      padding-right: 24px;
      border-radius: 0;
      display: flex;
      align-items: center;
      font-weight: 400;

      &.router-link-active {
        font-weight: 700;
        background: rgba(1, 65, 108, 0.05);
        border-right: 2px solid #01416c;
        color: #212631 !important;
      }

      i {
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }

  .backdrop {
    background: rgba(33, 38, 49, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
</style>
