<template>
  <div
    class="master-key-stage-details-item card"
    @click="onFocus"
    :class="{ 'showing-comment': showComment }"
  >
    <div class="card-body">
      <div class="d-flex align-items-center">
        <div class="letter mr-2 align-items-center" :class="bgColorType">
          {{ letter }}
        </div>

        <div
          class="letter-outlined mr-2 align-items-center"
          :class="borderColorType"
        >
          {{ percent }}
        </div>
        <button
          v-if="!!isSameLastAction"
          class="btn btn-outline-warning btn-block"
          @click="openConfirmationKey"
        >
          {{ MAP_MODAL_SUBMIT_TEXT[selected] }}
        </button>
        <b-form-radio-group
          v-else
          class="d-flex align-items-center justify-content-around flex-fill"
        >
          <b-form-radio v-model="selected" value="pain">Dor</b-form-radio>
          <b-form-radio v-model="selected" value="resource">
            Recurso
          </b-form-radio>
        </b-form-radio-group>

        <button class="bg-transparent border-0 button-arrow" @click="close">
          <i class="icon-chevron-right" />
        </button>
      </div>
    </div>
    <div v-if="showComment && !isSameLastAction" class="p-4">
      <div class="mb-2">
        <strong>
          {{ MAP_TRAIT_TEXT[letter][selected] || "Selecione dor ou recurso" }}
        </strong>
      </div>
      <textarea
        v-model="comment"
        class="form-control pt-4 mb-4"
        placeholder="Digite aqui o seu comentário"
        rows="3"
      />
      <button
        v-if="!!selected && !!comment.length"
        class="btn btn-block"
        :class="btnColorType"
        @click="openSubmit"
      >
        {{ MAP_BTN_TEXT[selected] }}
      </button>
    </div>
    <b-modal
      v-model="visible"
      :visible="visible"
      @close="closeModal"
      size="lg"
      hide-footer
      hide-header
      centered
    >
      <div class="confirmation p-3">
        <div class="row justify-content-center mb-4">
          <img src="@/assets/images/warning-icon.svg" />
        </div>

        <h3 class="text-center px-4 pt-2 mb-3">
          {{
            `${MAP_MODAL_TITLE[selected]}: ${MAP_TRAIT_NAME[letter]} ${percent}`
          }}
        </h3>

        <h5 class="mb-3">{{ MAP_ACTION_TYPE[selected] }}</h5>
        <textarea
          v-model="action"
          class="form-control pt-4 mb-4"
          :placeholder="MAP_ACTION_PLACEHOLDER[selected]"
          rows="3"
        />
        <h5 class="mb-3">Dicas, anotações e observações</h5>
        <textarea
          v-model="notes"
          class="form-control pt-4 mb-4"
          placeholder="Digite aqui dicas, anotações ou observações que você desejar"
          rows="3"
        />

        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="cancel"
            >
              {{ MAP_MODAL_CANCEL_TEXT[selected] }}
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="submit"
            >
              <b-spinner variant="light" v-if="loading" />
              <span v-if="!loading">{{ MAP_MODAL_SUBMIT_TEXT[selected] }}</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="lastAction"
      v-model="confirmKeyChangeVisible"
      @close="confirmKeyChangeVisible = false"
      size="lg"
      hide-footer
      hide-header
      centered
    >
      <div class="confirmation p-3">
        <div class="row justify-content-center mb-4">
          <img src="@/assets/images/warning-icon.svg" />
        </div>

        <h3 class="text-center px-4 pt-2 mb-3">
          {{
            `${MAP_MODAL_TITLE[selected]}: ${MAP_TRAIT_NAME[letter]} ${percent}`
          }}
        </h3>

        <h5 class="mb-3">{{ MAP_ACTION_TYPE[selected] }}</h5>
        <p class="text-muted">{{ lastAction.action || "" }}</p>
        <h5 class="mb-3">Dicas, anotações e observações</h5>
        <p class="text-muted">{{ lastAction.notes || "" }}</p>

        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-danger btn-lg font-weight-bold"
              @click="keepTraitInPain"
            >
              {{ MAP_MODAL_CANCEL_TEXT[selected] }}
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="confirmKeyChange"
            >
              <b-spinner variant="light" v-if="loading" />
              <span v-if="!loading">{{ MAP_MODAL_SUBMIT_TEXT[selected] }}</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import charMapUtils from "@/functions/charactermap";
import { mapState } from "vuex";
export default {
  props: {
    letter: {
      type: String,
      default: ""
    },
    showComment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      confirmKeyChangeVisible: false,
      selected: null,
      comment: "",
      action: "",
      notes: "",
      loading: false,
      errors: {
        notes: null
      },
      MAP_MODAL_TITLE: {
        pain: "Virada de chave",
        resource: "Reforço de recurso"
      },
      MAP_MODAL_SUBMIT_TEXT: {
        pain: "Confirmar virada de chave",
        resource: "Confirmar reforço de recurso"
      },
      MAP_MODAL_CANCEL_TEXT: {
        pain: "Manter traço na dor",
        resource: "Manter a ação anterior"
      },
      MAP_ACTION_TYPE: {
        pain: "Ação de cuidado",
        resource: "Ação de proteção"
      },
      MAP_ACTION_PLACEHOLDER: {
        resource: "Digite o que fazer para manter esse traço no recurso",
        pain: "Digite o que fazer para trazer esse traço para o recurso"
      },
      MAP_TRAIT_NAME: charMapUtils.MAP_TRAIT_TO_NAME,
      MAP_TRAIT_INDEX: {
        E: 0,
        O: 1,
        P: 2,
        M: 3,
        R: 4
      },
      MAP_BTN_TEXT: {
        pain: "Virada de Chave",
        resource: "Reforçar Recurso"
      },
      MAP_TRAIT_TEXT: {
        E: {
          pain: "Se sentir rejeitado. Como:",
          resource: "Criatividade, imaginação e capacidade de raciocinar. Como:"
        },
        O: {
          pain: "Se sentir abandonado. Como:",
          resource: "Comunicação, sensibilidade e acolhimento. Como:"
        },
        P: {
          pain: "Se sentir manipulado. Como:",
          resource: "Negociar, liderar e articular coisas e pessoas. Como:"
        },
        M: {
          pain: "Se sentir humilhado. Como:",
          resource:
            "Suportar as coisas difíceis, repetir processos, atenção a detalhes e planejamento. Como:"
        },
        R: {
          pain: "Se sentir traído. Como:",
          resource: "Competitividade, pró atividade e agilidade. Como:"
        }
      }
    };
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("masterKey", [
      "currentFact",
      "currentStep",
      "traitsPercent",
      "lastAction"
    ]),
    percent() {
      if (!this.traitsPercent[this.letter]) return 0;

      return `${Math.round(this.traitsPercent[this.letter].percent)}%`;
    },
    isSameLastAction() {
      if (!this.lastAction || this.currentStep.step === this.lastAction.step)
        return false;

      return this.lastAction.action_character === this.letter;
    },
    borderColorType() {
      if (this.isSameLastAction) return "border-custom-warning text-warning";

      return {
        "border-custom-success text-success": this.selected === "resource",
        "border-custom-danger text-danger": this.selected === "pain",
        "border-custom-secondary text-secondary": !this.selected
      };
    },
    bgColorType() {
      if (this.isSameLastAction) return "bg-custom-warning";

      return {
        "bg-custom-success": this.selected === "resource",
        "bg-custom-danger": this.selected === "pain",
        "bg-custom-secondary": !this.selected
      };
    },
    btnColorType() {
      if (this.isSameLastAction) return "btn-outline-warning";

      return {
        "btn-outline-success": this.selected === "resource",
        "btn-outline-danger": this.selected === "pain",
        "btn-outline-secondary": !this.selected
      };
    }
  },
  mounted() {
    this.fill(this.currentStep);
  },
  watch: {
    currentStep(newStep) {
      this.fill(newStep);
    },
    selected(newVal) {
      const characters = [...this.currentStep.characters];

      characters[this.MAP_TRAIT_INDEX[this.letter]].type = newVal;

      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        characters
      });
    },
    comment(newVal) {
      const characters = [...this.currentStep.characters];

      characters[this.MAP_TRAIT_INDEX[this.letter]].comment = newVal;

      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        characters
      });
    },
    action(newVal) {
      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        action: newVal
      });
    },
    notes(newVal) {
      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        notes: newVal
      });
    }
  },
  methods: {
    openConfirmationKey() {
      this.confirmKeyChangeVisible = true;
    },
    keepTraitInPain() {
      this.$store.commit("masterKey/setLastAction", null);
      this.confirmKeyChangeVisible = false;
      setTimeout(() => {
        this.selected = "pain";
      }, 1);
    },
    confirmKeyChange() {
      this.confirmKeyChangeVisible = false;

      const index = this.currentStep.characters.findIndex(
        c => c.character === this.lastAction.action_character
      );

      const characters = [...this.currentStep.characters];

      characters[index].comment = this.lastAction.action;

      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        characters
      });

      this.$store.commit("masterKey/setLastAction", null);

      setTimeout(() => {
        this.selected = "resource";
      }, 1);
    },
    closeModal() {
      this.notes = "";
      this.action = "";
      this.visible = true;
    },
    close() {
      if (this.showComment) {
        setTimeout(() => {
          this.$emit("close");
        }, 1);
      }
    },
    fill(stepData) {
      if (!stepData.characters && !stepData.action_character) return;

      this.comment =
        stepData.characters[this.MAP_TRAIT_INDEX[this.letter]].comment || "";

      this.selected =
        stepData.characters[this.MAP_TRAIT_INDEX[this.letter]].type || null;
    },
    onFocus(newType = null) {
      if (this.isSameLastAction) return;

      const type = newType ? newType : this.selected;
      this.$emit("open");
      this.$store.commit("masterKey/setTraitIndicator", {
        text: `${this.MAP_TRAIT_NAME[this.letter]} ${this.percent}`,
        type
      });
    },
    openSubmit() {
      const valids = this.currentStep.characters.filter(
        c => !!c.type && !!c.comment.length
      );
      if (valids.length !== 5) {
        this.$message.error(
          "Preencha os comentários e indicação de dor ou recurso de todos os traços antes de realizar esta ação"
        );
        return;
      }
      this.notes = "";
      this.action = "";
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },
    submit() {
      const { action, notes } = this.currentStep;
      if (!action || !action.length) {
        this.$message.error(
          "Primeiro, digite algo para ação tomada e se desejar um comentário ou nota."
        );
        return;
      }
      this.loading = true;
      this.$store.commit("masterKey/setCurrentStep", {
        ...this.currentStep,
        action_character: this.letter,
        action,
        notes
      });
      this.$store
        .dispatch("masterKey/publishStep", {
          customer_id: this.customer.id,
          fact_Id: this.currentFact.id,
          step: this.currentStep
        })
        .then(response => {
          const steps = [...this.currentFact.steps];
          steps[this.currentStep.step - 1] = response.data.data;
          this.$store.commit("masterKey/setCurrentFact", response.data.data);
          this.$store.commit("masterKey/setLastAction", this.currentStep);
          this.visible = false;
          this.$message.success(
            `O passo ${this.currentStep.step} foi publicado`
          );
        })
        .catch(error => {
          this.$message.error(
            `Erro ao publicar o passo: ${error.data.errors.join(", ")}`
          );
          // reseting because action_character is used to make last trait published to yellow
          this.$store.commit("masterKey/setCurrentStep", {
            ...this.currentStep,
            action_character: "",
            action: "",
            notes: ""
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.master-key-stage-details-item {
  .card-body {
    padding: 8px;
  }

  .letter {
    background: rgba(60, 193, 59, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    max-width: 56px;
    min-width: 56px;
    max-height: 56px;
    min-height: 56px;
    font-weight: bold;
  }

  .letter-outlined {
    @extend .letter;
    background: transparent;
    font-size: 16px;
  }

  .bg-custom-success {
    background: rgba(60, 193, 59, 0.1);
  }
  .bg-custom-danger {
    background: rgba(249, 96, 82, 0.1);
  }
  .bg-custom-warning {
    background: rgba(255, 211, 42, 0.1);
  }

  .bg-custom-secondary {
    background: rgba(1, 65, 108, 0.1);
  }

  .border-custom-success {
    border: 2px solid rgba(60, 193, 59, 0.1);
  }
  .border-custom-danger {
    border: 2px solid rgba(249, 96, 82, 0.1);
  }

  .border-custom-secondary {
    border: 2px solid rgba(1, 65, 108, 0.1);
  }

  .border-custom-warning {
    border: 2px solid rgba(255, 211, 42, 0.1);
  }

  label {
    font-weight: 800;
    font-size: 14px;
    line-height: 24px;
    color: #212631;
  }

  .custom-control:first-child
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background: #f96052;
    border-color: #f96052;
  }

  .custom-control:last-child
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background: #3cc13b;
    border-color: #3cc13b;
  }

  textarea {
    border: 1px solid #e9edf2;
    border-radius: 4px;
  }

  .button-arrow {
    transition: 0.2s transform;
  }
  &.showing-comment {
    .button-arrow {
      transform: rotate(90deg);
    }
  }
}
</style>
