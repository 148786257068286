<template>
  <div class="pagination d-flex align-items-center justify-content-end">
    <button v-if="page > 1" class="btn btn-outline-primary mr-3" @click="prev">
      <i class="icon-arrow-left"></i> Anterior
    </button>
    <the-mask v-model="page" mask="####" type="tel" />
    <span class="mx-3">de {{ totalPages }} páginas</span>
    <button
      v-if="page < totalPages"
      class="btn btn-outline-primary"
      @click="next"
    >
      Próxima <i class="icon-arrow-right"></i>
    </button>
  </div>
</template>

<script>
let timeout = null;

export default {
  props: {
    totalPages: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      page: 1
    };
  },
  watch: {
    page(val) {
      if (val < 1) {
        this.page = 1;
      }
      if (val > this.totalPages) {
        this.page = this.totalPages;
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$emit("set-page", this.page);
      }, 300);
    }
  },
  methods: {
    prev() {
      this.page = parseInt(this.page) - 1;
    },
    next() {
      this.page = parseInt(this.page) + 1;
    }
  }
};
</script>

<style lang="scss">
.pagination {
  padding-top: 32px;

  input {
    min-height: 42px;
    border: 1px solid #aeb6c4;
    border-radius: 4px;
    max-width: 50px;
    text-align: center;
    background: transparent;
  }
}
</style>
