import charMapUtils from "@/functions/charactermap";
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  mounted() {
    if (!this.customer.characters_map) return;

    const data = ["E", "O", "P", "M", "R"].map(letter =>
      Math.round(
        (charMapUtils.sumTraitsByKey(this.customer.characters_map, letter) *
          100) /
          60
      )
    );

    const colors = ["#0099FF", "#FFCC00", "#FF8A00", "#00FF00", "#FF00FF"];

    const chartData = {
      labels: ["E", "O", "P", "M", "R"],
      datasets: [
        {
          data,
          label: "",
          legend: true,
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 0,
          hoverBackgroundColor: colors,
          hoverBorderColor: colors,
          barPercentage: 0.5
        }
      ]
    };
    const options = {
      legend: {
        display: false
      },
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 60,
              beginAtZero: true,
              fontColor: "#060620",
              fontSize: 16,
              padding: 10
            },
            gridLines: {
              display: false,
              color: "#99999955",
              drawBorder: false,
              drawTicks: false,
              offsetGridLines: false
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              maxTicksLimit: 10,
              beginAtZero: true,
              fontColor: "#AEB6C4",
              drawOnChartArea: true
            }
          }
        ]
      }
    };

    this.renderChart(chartData, options);
  }
};
