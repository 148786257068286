<template>
  <div class="view-traits-table">
    <div class="table-responsive">
      <table class="table mb-0">
        <tr v-for="(element, index) in elements" :key="index">
          <td>
            <img v-if="element.icon" :src="element.icon" class="trait-img" />
            <span v-if="element.text">
              {{ element.text }}
            </span>
          </td>
          <td
            v-for="cel in cells"
            :key="`cel:${cel}`"
            :class="getColors(index, cel)"
          >
            <span>
              {{ getCellInfo(index, cel) }}
            </span>
            <small v-if="!!getSidesInfo(index, cel, 'left')">
              E
            </small>
            <small v-if="!!getSidesInfo(index, cel, 'right')">
              D
            </small>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import charMapUtils from "@/functions/charactermap";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      cells: [0, 1, 2, 3, 4],
      elements: [
        { name: undefined, icon: undefined, text: undefined },
        {
          name: "head",
          icon: require("@/assets/chart/head.svg"),
          text: undefined
        },
        {
          name: "eyes",
          icon: require("@/assets/chart/eye.svg"),
          text: undefined
        },
        {
          name: "mouth",
          icon: require("@/assets/chart/mouth.svg"),
          text: undefined
        },
        {
          name: "back",
          icon: require("@/assets/chart/back.svg"),
          text: undefined
        },
        {
          name: "hip",
          icon: require("@/assets/chart/breech.svg"),
          text: undefined
        },
        {
          name: "legs",
          icon: require("@/assets/chart/legs.svg"),
          text: undefined
        },
        { name: undefined, icon: undefined, text: "Total", strong: true },
        { name: undefined, icon: undefined, text: "Percentual", strong: true }
      ],
      initials: ["E", "O", "P", "M", "R"],
      values: charMapUtils.createLateralityArray(this.customer.characters_map),
      data: []
    };
  },
  mounted() {
    this.values = charMapUtils.createLateralityArray(
      this.customer.characters_map
    );
  },
  methods: {
    getCellInfo(index, cellIndex) {
      if (index === 0) {
        return this.initials[cellIndex];
      }

      if (index === this.elements.length - 1) {
        const traitsValues = this.values[index - 2].map(it =>
          Math.round((it.value * 100) / (10 * 6))
        );
        const percent = traitsValues[cellIndex];
        return `${percent}%`;
      }

      const traitsValues = this.values[index - 1].map(it => it.value);
      return traitsValues[cellIndex];
    },
    getSidesInfo(index, cellIndex, sideKey) {
      if (
        index === 0 ||
        index === this.elements.length - 1 ||
        index === this.elements.length - 2 ||
        !this.values.length
      ) {
        return undefined;
      }

      const sideValues = this.values[index - 1].map(it => ({
        left: it.left ?? false,
        right: it.right ?? false
      }));

      return sideValues[cellIndex][sideKey];
    },
    getColors(index, cel) {
      const isLeft = !!this.getSidesInfo(index, cel, "left");
      const isRight = !!this.getSidesInfo(index, cel, "right");
      return {
        "font-weight-bold text-success": isLeft && !isRight,
        "font-weight-bold text-primary": !isLeft && isRight
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.view-traits-table {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;

  small {
    font-size: 14px;
  }

  .trait-img {
    width: 44px;
    height: 44px;
  }

  tr {
    td {
      text-align: center;
      vertical-align: middle;
      border: none;
      border-left: 1px solid #e9edf2;
      color: #78848c;
      padding: 4px 12px;

      &:first-child {
        border-left: none;
        text-align: left;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      td {
        border-top: 1px solid #e9edf2;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2),
    &:first-child {
      td {
        font-weight: 900;
        color: #212631;
        padding: 12px;
      }
    }

    &:first-child {
      td {
        border-left: none;
        padding-top: 0;
      }
    }
  }

  small {
    font-size: 10px;
    transform: translateY(-5px);
    display: inline-block;
  }
}
</style>
