const USER_KEY = "_oce_analyst_key@";

const getUserInfo = () => {
  const data = localStorage.getItem(USER_KEY);

  if (!data) return undefined;

  return JSON.parse(data);
};

const saveUserInfo = user => {
  localStorage.setItem(
    USER_KEY,
    JSON.stringify({
      ...(getUserInfo() || {}),
      ...user
    })
  );
};

const removeUserInfo = () => localStorage.removeItem(USER_KEY);

const initUserInfo = () => {
  const user = getUserInfo();
  if (user) {
    // SET JSO.parse(user) to VUEX
  }
};

export { saveUserInfo, getUserInfo, removeUserInfo, initUserInfo };
