export default {
  JSON: {
    BRAZILIAN_STATES:
      "https://raw.githubusercontent.com/matheusnpenna/files/main/brazilian-states-and-cities.json",
    COUNTRIES: [
      {
        dialCode: "93",
        code: "AFG",
        name: "Afeganistão"
      },
      {
        dialCode: "27",
        code: "ZAF",
        name: "África do Sul"
      },
      {
        dialCode: "355",
        code: "ALB",
        name: "Albânia"
      },
      {
        dialCode: "49",
        code: "DEU",
        name: "Alemanha"
      },
      {
        dialCode: "213",
        code: "DZA",
        name: "Algéria"
      },
      {
        dialCode: "376",
        code: "AND",
        name: "Andorra"
      },
      {
        dialCode: "244",
        code: "AGO",
        name: "Angola"
      },
      {
        dialCode: "1264",
        code: "AIA",
        name: "Anguilla"
      },
      {
        dialCode: "672",
        code: "ATA",
        name: "Antártida"
      },
      {
        dialCode: "1268",
        code: "ATG",
        name: "Antigua e Barbuda"
      },
      {
        dialCode: "599",
        code: "ANT",
        name: "Antilhas Holandesas"
      },
      {
        dialCode: "966",
        code: "SAU",
        name: "Arábia Saudita"
      },
      {
        dialCode: "54",
        code: "ARG",
        name: "Argentina"
      },
      {
        dialCode: "374",
        code: "ARM",
        name: "Armênia"
      },
      {
        dialCode: "297",
        code: "ABW",
        name: "Aruba"
      },
      {
        dialCode: "61",
        code: "AUS",
        name: "Austrália"
      },
      {
        dialCode: "43",
        code: "AUT",
        name: "Áustria"
      },
      {
        dialCode: "994",
        code: "AZE",
        name: "Azerbaijão"
      },
      {
        dialCode: "1242",
        code: "BHS",
        name: "Bahamas"
      },
      {
        dialCode: "973",
        code: "BHR",
        name: "Bahrein"
      },
      {
        dialCode: "880",
        code: "BGD",
        name: "Bangladesh"
      },
      {
        dialCode: "246",
        code: "BRB",
        name: "Barbados"
      },
      {
        dialCode: "32",
        code: "BEL",
        name: "Bélgica"
      },
      {
        dialCode: "501",
        code: "BLZ",
        name: "Belize"
      },
      {
        dialCode: "229",
        code: "BEN",
        name: "Benin"
      },
      {
        dialCode: "1441",
        code: "BMU",
        name: "Bermuda"
      },
      {
        dialCode: "375",
        code: "BLR",
        name: "Bielorrússia"
      },
      {
        dialCode: "95",
        code: "MMR",
        name: "Birmânia"
      },
      {
        dialCode: "591",
        code: "BOL",
        name: "Bolívia"
      },
      {
        dialCode: "387",
        code: "BIH",
        name: "Bósnia e Herzegovina"
      },
      {
        dialCode: "267",
        code: "BWA",
        name: "Botswana"
      },
      {
        dialCode: "55",
        code: "BRA",
        name: "Brasil"
      },
      {
        dialCode: "673",
        code: "BRN",
        name: "Brunei"
      },
      {
        dialCode: "359",
        code: "BGR",
        name: "Bulgária"
      },
      {
        dialCode: "226",
        code: "BFA",
        name: "Burkina Faso"
      },
      {
        dialCode: "257",
        code: "BDI",
        name: "Burundi"
      },
      {
        dialCode: "975",
        code: "BTN",
        name: "Butão"
      },
      {
        dialCode: "238",
        code: "CPV",
        name: "Cabo Verde"
      },
      {
        dialCode: "237",
        code: "CMR",
        name: "Camarões"
      },
      {
        dialCode: "855",
        code: "KHM",
        name: "Camboja"
      },
      {
        dialCode: "1",
        code: "CAN",
        name: "Canadá"
      },
      {
        dialCode: "974",
        code: "QAT",
        name: "Catar"
      },
      {
        dialCode: "7",
        code: "KAZ",
        name: "Cazaquistão"
      },
      {
        dialCode: "235",
        code: "TCD",
        name: "Chade"
      },
      {
        dialCode: "56",
        code: "CHL",
        name: "Chile"
      },
      {
        dialCode: "86",
        code: "CHN",
        name: "China"
      },
      {
        dialCode: "357",
        code: "CYP",
        name: "Chipre"
      },
      {
        dialCode: "57",
        code: "COL",
        name: "Colômbia"
      },
      {
        dialCode: "269",
        code: "COM",
        name: "Comores"
      },
      {
        dialCode: "242",
        code: "COG",
        name: "Congo"
      },
      {
        dialCode: "242",
        code: "COD",
        name: "Congo (DR)"
      },
      {
        dialCode: "850",
        code: "PRK",
        name: "Coreia do Norte"
      },
      {
        dialCode: "82",
        code: "KOR",
        name: "Coreia do Sul"
      },
      {
        dialCode: "225",
        code: "CIV",
        name: "Costa do Marfim"
      },
      {
        dialCode: "506",
        code: "CRI",
        name: "Costa Rica"
      },
      {
        dialCode: "385",
        code: "HRV",
        name: "Croácia"
      },
      {
        dialCode: "53",
        code: "CUB",
        name: "Cuba"
      },
      {
        dialCode: "45",
        code: "DNK",
        name: "Dinamarca"
      },
      {
        dialCode: "253",
        code: "DJI",
        name: "Djibuti"
      },
      {
        dialCode: "1767",
        code: "DMA",
        name: "Dominica"
      },
      {
        dialCode: "20",
        code: "EGY",
        name: "Egito"
      },
      {
        dialCode: "503",
        code: "SLV",
        name: "El Salvador"
      },
      {
        dialCode: "971",
        code: "ARE",
        name: "Emirados Árabes"
      },
      {
        dialCode: "593",
        code: "ECU",
        name: "Equador"
      },
      {
        dialCode: "291",
        code: "ERI",
        name: "Eritreia"
      },
      {
        dialCode: "421",
        code: "SVK",
        name: "Eslováquia"
      },
      {
        dialCode: "386",
        code: "SVN",
        name: "Eslovênia"
      },
      {
        dialCode: "34",
        code: "ESP",
        name: "Espanha"
      },
      {
        dialCode: "1",
        code: "USA",
        name: "Estados Unidos"
      },
      {
        dialCode: "372",
        code: "EST",
        name: "Estônia"
      },
      {
        dialCode: "47",
        code: "SJM",
        name: "Esvalbarde"
      },
      {
        dialCode: "251",
        code: "ETH",
        name: "Etiópia"
      },
      {
        dialCode: "679",
        code: "FJI",
        name: "Fiji"
      },
      {
        dialCode: "63",
        code: "PHL",
        name: "Filipinas"
      },
      {
        dialCode: "358",
        code: "FIN",
        name: "Finlândia"
      },
      {
        dialCode: "33",
        code: "FRA",
        name: "França"
      },
      {
        dialCode: "241",
        code: "GAB",
        name: "Gabão"
      },
      {
        dialCode: "220",
        code: "GMB",
        name: "Gâmbia"
      },
      {
        dialCode: "233",
        code: "GHA",
        name: "Gana"
      },
      {
        dialCode: "995",
        code: "GEO",
        name: "Geórgia"
      },
      {
        dialCode: "350",
        code: "GIB",
        name: "Gibraltar"
      },
      {
        dialCode: "1473",
        code: "GRD",
        name: "Granada"
      },
      {
        dialCode: "30",
        code: "GRC",
        name: "Grécia"
      },
      {
        dialCode: "299",
        code: "GRL",
        name: "Groelândia"
      },
      {
        dialCode: "590",
        code: "GLP",
        name: "Guadalupe"
      },
      {
        dialCode: "1671",
        code: "GUM",
        name: "Guão"
      },
      {
        dialCode: "502",
        code: "GTM",
        name: "Guatemala"
      },
      {
        dialCode: "592",
        code: "GUY",
        name: "Guiana"
      },
      {
        dialCode: "594",
        code: "GUF",
        name: "Guiana Francesa"
      },
      {
        dialCode: "224",
        code: "GIN",
        name: "Guiné"
      },
      {
        dialCode: "240",
        code: "GNQ",
        name: "Guiné Equatorial"
      },
      {
        dialCode: "245",
        code: "GNB",
        name: "Guiné-Bissau"
      },
      {
        dialCode: "509",
        code: "HTI",
        name: "Haiti"
      },
      {
        dialCode: "31",
        code: "NLD",
        name: "Holanda"
      },
      {
        dialCode: "504",
        code: "HND",
        name: "Honduras"
      },
      {
        dialCode: "852",
        code: "HKG",
        name: "Hong Kong"
      },
      {
        dialCode: "36",
        code: "HUN",
        name: "Hungria"
      },
      {
        dialCode: "967",
        code: "YEM",
        name: "Iêmen"
      },
      {
        dialCode: "47",
        code: "BVT",
        name: "Ilha Bouvet"
      },
      {
        dialCode: "61",
        code: "CXR",
        name: "Ilha Christmas"
      },
      {
        dialCode: "672",
        code: "NFK",
        name: "Ilha Norfolk"
      },
      {
        dialCode: "1345",
        code: "CYM",
        name: "Ilhas Cayman"
      },
      {
        dialCode: "672",
        code: "CCK",
        name: "Ilhas Cocos (Keeling)"
      },
      {
        dialCode: "682",
        code: "COK",
        name: "Ilhas Cook"
      },
      {
        dialCode: "298",
        code: "FRO",
        name: "Ilhas Faroe"
      },
      {
        dialCode: "500",
        code: "SGS",
        name: "Ilhas Geórgia do Sul e Sandwich do Sul"
      },
      {
        dialCode: "672",
        code: "HMD",
        name: "Ilhas Heard e McDonald"
      },
      {
        dialCode: "500",
        code: "FLK",
        name: "Ilhas Malvinas"
      },
      {
        dialCode: "1670",
        code: "MNP",
        name: "Ilhas Marianas do Norte"
      },
      {
        dialCode: "692",
        code: "MHL",
        name: "Ilhas Marshall"
      },
      {
        dialCode: "1",
        code: "UMI",
        name: "Ilhas Menores Distantes dos Estados Unidos"
      },
      {
        dialCode: "672",
        code: "PCN",
        name: "Ilhas Picárnia"
      },
      {
        dialCode: "677",
        code: "SLB",
        name: "Ilhas Salomão"
      },
      {
        dialCode: "1649",
        code: "TCA",
        name: "Ilhas Turks e Caicos"
      },
      {
        dialCode: "1340",
        code: "VIR",
        name: "Ilhas Virgens"
      },
      {
        dialCode: "1284",
        code: "VGB",
        name: "Ilhas Virgens Inglesas"
      },
      {
        dialCode: "91",
        code: "IND",
        name: "Índia"
      },
      {
        dialCode: "62",
        code: "IDN",
        name: "Indonésia"
      },
      {
        dialCode: "98",
        code: "IRN",
        name: "Iran"
      },
      {
        dialCode: "964",
        code: "IRQ",
        name: "Iraque"
      },
      {
        dialCode: "353",
        code: "IRL",
        name: "Irlanda"
      },
      {
        dialCode: "354",
        code: "ISL",
        name: "Islândia"
      },
      {
        dialCode: "972",
        code: "ISR",
        name: "Israel"
      },
      {
        dialCode: "39",
        code: "ITA",
        name: "Italia"
      },
      {
        dialCode: "1876",
        code: "JAM",
        name: "Jamaica"
      },
      {
        dialCode: "81",
        code: "JPN",
        name: "Japão"
      },
      {
        dialCode: "962",
        code: "JOR",
        name: "Jornânia"
      },
      {
        dialCode: "686",
        code: "KIR",
        name: "Kiribati"
      },
      {
        dialCode: "965",
        code: "KWT",
        name: "Kuwait"
      },
      {
        dialCode: "856",
        code: "LAO",
        name: "Laos"
      },
      {
        dialCode: "266",
        code: "LSO",
        name: "Lesoto"
      },
      {
        dialCode: "371",
        code: "LVA",
        name: "Letônia"
      },
      {
        dialCode: "961",
        code: "LBN",
        name: "Líbano"
      },
      {
        dialCode: "231",
        code: "LBR",
        name: "Libéria"
      },
      {
        dialCode: "218",
        code: "LBY",
        name: "Líbia"
      },
      {
        dialCode: "423",
        code: "LIE",
        name: "Liechtenstein"
      },
      {
        dialCode: "370",
        code: "LTU",
        name: "Lituânia"
      },
      {
        dialCode: "352",
        code: "LUX",
        name: "Luxemburgo"
      },
      {
        dialCode: "853",
        code: "MAC",
        name: "Macao"
      },
      {
        dialCode: "389",
        code: "MKD",
        name: "Macedônia"
      },
      {
        dialCode: "261",
        code: "MDG",
        name: "Madagascar"
      },
      {
        dialCode: "60",
        code: "MYS",
        name: "Malásia"
      },
      {
        dialCode: "265",
        code: "MWI",
        name: "Malawi"
      },
      {
        dialCode: "960",
        code: "MDV",
        name: "Maldivas"
      },
      {
        dialCode: "223",
        code: "MLI",
        name: "Mali"
      },
      {
        dialCode: "356",
        code: "MLT",
        name: "Malta"
      },
      {
        dialCode: "212",
        code: "MAR",
        name: "Marrocos"
      },
      {
        dialCode: "596",
        code: "MTQ",
        name: "Martinica"
      },
      {
        dialCode: "230",
        code: "MUS",
        name: "Maurício"
      },
      {
        dialCode: "222",
        code: "MRT",
        name: "Mauritânia"
      },
      {
        dialCode: "269",
        code: "MYT",
        name: "Mayotte"
      },
      {
        dialCode: "52",
        code: "MEX",
        name: "México"
      },
      {
        dialCode: "691",
        code: "FSM",
        name: "Micronésia"
      },
      {
        dialCode: "258",
        code: "MOZ",
        name: "Moçambique"
      },
      {
        dialCode: "373",
        code: "MDA",
        name: "Moldova"
      },
      {
        dialCode: "377",
        code: "MCO",
        name: "Mônaco"
      },
      {
        dialCode: "976",
        code: "MNG",
        name: "Mongólia"
      },
      {
        dialCode: "1664",
        code: "MSR",
        name: "Montserrat"
      },
      {
        dialCode: "264",
        code: "NAM",
        name: "Namíbia"
      },
      {
        dialCode: "674",
        code: "NRU",
        name: "Nauru"
      },
      {
        dialCode: "977",
        code: "NPL",
        name: "Nepal"
      },
      {
        dialCode: "505",
        code: "NIC",
        name: "Nicarágua"
      },
      {
        dialCode: "227",
        code: "NER",
        name: "Niger"
      },
      {
        dialCode: "234",
        code: "NGA",
        name: "Nigéria"
      },
      {
        dialCode: "683",
        code: "NIU",
        name: "Niue"
      },
      {
        dialCode: "47",
        code: "NOR",
        name: "Noruega"
      },
      {
        dialCode: "687",
        code: "NCL",
        name: "Nova Caledônia"
      },
      {
        dialCode: "64",
        code: "NZL",
        name: "Nova Zelândia"
      },
      {
        dialCode: "968",
        code: "OMN",
        name: "Omã"
      },
      {
        dialCode: "680",
        code: "PLW",
        name: "Palau"
      },
      {
        dialCode: "970",
        code: "PSE",
        name: "Palestina"
      },
      {
        dialCode: "507",
        code: "PAN",
        name: "Panamá"
      },
      {
        dialCode: "675",
        code: "PNG",
        name: "Papua-Nova Guiné"
      },
      {
        dialCode: "92",
        code: "PAK",
        name: "Paquistão"
      },
      {
        dialCode: "595",
        code: "PRY",
        name: "Paraguai"
      },
      {
        dialCode: "51",
        code: "PER",
        name: "Peru"
      },
      {
        dialCode: "689",
        code: "PYF",
        name: "Polinésia Francesa"
      },
      {
        dialCode: "48",
        code: "POL",
        name: "Polônia"
      },
      {
        dialCode: "1787",
        code: "PRI",
        name: "Porto Rico"
      },
      {
        dialCode: "351",
        code: "PRT",
        name: "Portugal"
      },
      {
        dialCode: "254",
        code: "KEN",
        name: "Quênia"
      },
      {
        dialCode: "996",
        code: "KGZ",
        name: "Quirguistão"
      },
      {
        dialCode: "44",
        code: "GBR",
        name: "Reino Unido"
      },
      {
        dialCode: "236",
        code: "CAF",
        name: "República Centro-Africana"
      },
      {
        dialCode: "232",
        code: "SLE",
        name: "República da Serra Leoa"
      },
      {
        dialCode: "1809",
        code: "DOM",
        name: "República Dominicana"
      },
      {
        dialCode: "420",
        code: "CZE",
        name: "República Tcheca"
      },
      {
        dialCode: "262",
        code: "REU",
        name: "Reunião"
      },
      {
        dialCode: "40",
        code: "ROM",
        name: "Romênia"
      },
      {
        dialCode: "250",
        code: "RWA",
        name: "Ruanda"
      },
      {
        dialCode: "70",
        code: "RUS",
        name: "Rússia"
      },
      {
        dialCode: "212",
        code: "ESH",
        name: "Saara Ocidental"
      },
      {
        dialCode: "684",
        code: "WSM",
        name: "Samoa"
      },
      {
        dialCode: "1684",
        code: "ASM",
        name: "Samoa Americana"
      },
      {
        dialCode: "290",
        code: "SHN",
        name: "Santa Helena"
      },
      {
        dialCode: "1758",
        code: "LCA",
        name: "Santa Lúcia"
      },
      {
        dialCode: "1869",
        code: "KNA",
        name: "São Cristóvão"
      },
      {
        dialCode: "378",
        code: "SMR",
        name: "São Marino"
      },
      {
        dialCode: "508",
        code: "SPM",
        name: "São Pedro e Miquelon"
      },
      {
        dialCode: "239",
        code: "STP",
        name: "Sao Tomé e Príncipe"
      },
      {
        dialCode: "1784",
        code: "VCT",
        name: "São Vicente e Granadinas"
      },
      {
        dialCode: "248",
        code: "SYC",
        name: "Seicheles"
      },
      {
        dialCode: "221",
        code: "SEN",
        name: "Senegal"
      },
      {
        dialCode: "381",
        code: "SRB",
        name: "Sérvia e Montenegro"
      },
      {
        dialCode: "65",
        code: "SGP",
        name: "Singapura"
      },
      {
        dialCode: "963",
        code: "SYR",
        name: "Síria"
      },
      {
        dialCode: "252",
        code: "SOM",
        name: "Somália"
      },
      {
        dialCode: "94",
        code: "LKA",
        name: "Sri Lanka"
      },
      {
        dialCode: "268",
        code: "SWZ",
        name: "Suazilândia"
      },
      {
        dialCode: "249",
        code: "SDN",
        name: "Sudão"
      },
      {
        dialCode: "46",
        code: "SWE",
        name: "Suécia"
      },
      {
        dialCode: "41",
        code: "CHE",
        name: "Suiça"
      },
      {
        dialCode: "597",
        code: "SUR",
        name: "Suriname"
      },
      {
        dialCode: "66",
        code: "THA",
        name: "Tailândia"
      },
      {
        dialCode: "886",
        code: "TWN",
        name: "Taiwan"
      },
      {
        dialCode: "992",
        code: "TJK",
        name: "Tajiquistão"
      },
      {
        dialCode: "255",
        code: "TZA",
        name: "Tanzânia"
      },
      {
        dialCode: "33",
        code: "ATF",
        name: "Terras Austrais e Antárticas Francesas"
      },
      {
        dialCode: "246",
        code: "IOT",
        name: "Território Britânico do Oceano Índico"
      },
      {
        dialCode: "670",
        code: "TLS",
        name: "Timor-Leste"
      },
      {
        dialCode: "228",
        code: "TGO",
        name: "Togo"
      },
      {
        dialCode: "676",
        code: "TON",
        name: "Tonga"
      },
      {
        dialCode: "690",
        code: "TKL",
        name: "Toquelau"
      },
      {
        dialCode: "1868",
        code: "TTO",
        name: "Trinidad e Tobago"
      },
      {
        dialCode: "216",
        code: "TUN",
        name: "Tunísia"
      },
      {
        dialCode: "7370",
        code: "TKM",
        name: "Turcomenistão"
      },
      {
        dialCode: "90",
        code: "TUR",
        name: "Turquia"
      },
      {
        dialCode: "688",
        code: "TUV",
        name: "Tuvalu"
      },
      {
        dialCode: "380",
        code: "UKR",
        name: "Ucrânia"
      },
      {
        dialCode: "256",
        code: "UGA",
        name: "Uganda"
      },
      {
        dialCode: "598",
        code: "URY",
        name: "Uruguai"
      },
      {
        dialCode: "998",
        code: "UZB",
        name: "Uzbequistão"
      },
      {
        dialCode: "678",
        code: "VUT",
        name: "Vanuatu"
      },
      {
        dialCode: "39",
        code: "VAT",
        name: "Vaticano"
      },
      {
        dialCode: "58",
        code: "VEN",
        name: "Venezuela"
      },
      {
        dialCode: "84",
        code: "VNM",
        name: "Vietnam"
      },
      {
        dialCode: "681",
        code: "WLF",
        name: "Wallis e Futuna"
      },
      {
        dialCode: "260",
        code: "ZMB",
        name: "Zâmbia"
      },
      {
        dialCode: "263",
        code: "ZWE",
        name: "Zimbábue"
      }
    ]
  }
};
