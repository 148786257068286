<template>
  <div class="view-edit-profile row">
    <div class="col-lg-8">
      <h5 class="mb-4">Editar perfil</h5>

      <form @submit.prevent="submit">
        <form-group
          v-model="form.name"
          id="name"
          label="NOME COMPLETO"
          placeholder="Digite seu nome"
          :errors="errors.name"
        />
        <form-group
          v-model="form.email"
          id="email"
          type="email"
          label="E-MAIL"
          placeholder="Digite seu e-mail"
          :is-login="true"
          :errors="errors.email"
        />
        <div v-if="$user.user.user_type !== 'admin'">
          <div class="row justify-content-between">
            <div class="col-lg-6">
              <form-group
                v-model="form.phone"
                id="phone"
                type="phone"
                label="TELEFONE"
                placeholder="Ex: (11) 9 9999-9999"
                :mask="isBrasil ? '(##) # ####-####' : '################'"
                :errors="errors.phone"
              />
            </div>
            <div class="col-lg-6">
              <form-group
                v-model="form.cpf"
                id="cpf"
                type="text"
                placeholder="Ex: 123.456.789-01"
                :label="isBrasil ? 'CPF' : 'Documento Estrangeiro'"
                :masked="isBrasil"
                :mask="isBrasil ? '###.###.###-##' : ''"
                :errors="errors.cpf"
              />
            </div>
          </div>

          <div class="row justify-content-between">
            <div class="col-lg-6">
              <form-group
                v-model="form.country"
                id="country"
                label="PAÍS"
                placeholder="Clique para selecionar o país"
                :select="true"
                :options="countryOptions"
                :errors="errors.country"
              />
            </div>
            <div class="col-lg-6">
              <form-group
                v-model="form.uf"
                id="uf"
                label="UF"
                placeholder="Clique para selecionar o estado"
                :select="isBrasil"
                :options="stateOptions"
                :errors="errors.uf"
              />
            </div>
          </div>

          <form-group
            v-model="form.city"
            id="city"
            label="CIDADE"
            placeholder="Clique para selecionar a cidade"
            :select="isBrasil"
            :options="cityOptions"
            :errors="errors.city"
          />
        </div>

        <button class="btn btn-sm" type="button" @click="goToChangePassword">
          <h6 class="text-primary mb-5">
            Alterar senha de acesso
          </h6>
        </button>

        <div class="row justify-content-between pt-lg-5">
          <div class="col-5 col-lg-3"></div>
          <div class="col-5 col-lg-3">
            <hollow-button
              class=""
              title="Salvar"
              type="submit"
              :loading="loading"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { formFields, removeMask } from "@/functions/helpers";
import AddressForm from "@/mixins/AddressForm";

export default {
  name: "EditProfile",
  mixins: [AddressForm],
  data() {
    const userInfo = this.$user.user;
    return {
      ...formFields(
        ["name", "email", "phone", "cpf", "country", "city", "uf"],
        userInfo
      ),
      countryOptions: [{ name: userInfo.country, id: userInfo.country }],
      stateOptions: [{ name: userInfo.uf, id: userInfo.uf }],
      cityOptions: [{ name: userInfo.city, id: userInfo.city }],
      loading: false
    };
  },
  mounted() {
    this.loadCountries();
    this.loadStatesAndCities(brazilianStates => {
      const { country, uf } = this.form;
      if (country === "Brasil" && uf && uf.length) {
        const state = brazilianStates.states.find(st => st.uf === uf);
        this.cityOptions = state.cities.map(ct => ({ name: ct, id: ct }));
      }
    });
  },
  methods: {
    resetErrors() {
      for (let i in this.errors) this.errors[i] = [];
    },
    submit() {
      this.loading = true;
      this.resetErrors();

      let payload = {};
      let actionName = "";

      if (this.$user.user.user_type === "admin") {
        payload = {
          ...this.$user.user,
          email: this.form.email,
          name: this.form.name
        };
        actionName = "admins/updateProfile";
      } else {
        payload = {
          ...this.$user.user,
          ...this.form,
          cpf: removeMask(this.form.cpf),
          phone: removeMask(this.form.phone)
        };
        actionName = "analysts/updateProfile";
      }

      this.$store
        .dispatch(actionName, payload)
        .then(() => {
          this.$message.success("Os dados foram atualizados");
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    goToChangePassword() {
      this.$router.push({ name: "change-password" });
    }
  }
};
</script>
