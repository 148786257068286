import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("masterKey", ["currentStep", "traitsPercent"]),
    traitsData() {
      if (!this.currentStep.action_character) return [];

      return this.currentStep.characters.map(el => ({
        char: el.character,
        percent: Math.round(
          this.traitsPercent[el.character].percent
        ).toString(),
        status: el.type
      }));
    },
    painData() {
      return this.traitsData.filter(t => t.status === "pain");
    },
    resourceData() {
      return this.traitsData.filter(t => t.status === "resource");
    }
  }
};
