<template>
  <div class="profile-iqv-form-data">
    <!-- personal info end -->
    <div class="row align-items-center mb-3">
      <div class="col-2">
        <img class="img-fluid" src="@/assets/images/profile-chart-icon.svg" />
      </div>
      <div class="col-5 col-lg d-flex flex-column">
        <span :class="scoreClasses"> {{ improvementScore }}% </span>
        <small class="text-muted">Índice de resultado</small>
      </div>
      <div class="col-5 col-lg text-right">
        <button
          type="button"
          class="btn btn-outline-success"
          @click="onClickSendForm"
        >
          <span class="d-lg-none">Enviar form</span>
          <span class="d-none d-lg-block">Enviar formulário</span>
        </button>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-6 col-lg-5 d-flex flex-column">
        <span class="text-dark font-weight-bold">{{
          customer.forms_answered
        }}</span>
        <small class="text-muted">Formulários preenchidos</small>
      </div>
      <div class="col d-flex flex-column">
        <span class="text-dark font-weight-bold">{{
          customer.forms_overview.previous_iqv || 0
        }}</span>
        <small class="text-muted">IQV inicial</small>
      </div>
      <div class="col d-flex flex-column">
        <span class="text-dark font-weight-bold">{{ customer.iqv || 0 }}</span>
        <small class="text-muted">IQV atual</small>
      </div>
    </div>

    <confirmation
      iconType="warning"
      title="Tem certeza que deseja enviar o formulário?"
      text="Ao enviar, o cliente receberá o formulário de acompanhamento individual para gerar comparação de evolução (índice de resultado). Tem certeza que deseja enviar o formulário?"
      cancelButtonText="Cancelar"
      confirmButtonText="Enviar formulário"
      confirmButtonClass="btn-success"
      :loading="loading"
      :visible="sendFormConfirmShow"
      @confirm="onSubmitFormSuccess"
      @cancel="onCancelSubmitForm"
    />
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      sendFormConfirmShow: false,
      loading: false
    };
  },
  computed: {
    improvementScore() {
      return Math.round(
        get(this.customer, "forms_overview.improvement_score", 0) * 100
      );
    },
    scoreClasses() {
      return {
        "text-danger": this.improvementScore < 0,
        "text-success": this.improvementScore > 0,
        "text-dark": this.improvementScore === 0,
        "font-weight-bold": true
      };
    }
  },
  methods: {
    onClickSendForm() {
      this.sendFormConfirmShow = true;
    },
    onCancelSubmitForm() {
      this.sendFormConfirmShow = false;
    },
    onSubmitFormSuccess() {
      this.loading = true;
      this.$store
        .dispatch("lifeQuality/sendForm", { client_id: this.customer.id })
        .then(response => {
          this.sendFormConfirmShow = false;
          this.$message.success("Formulário enviado para o cliente");
        })
        .catch(error => {
          this.$message.error("Erro ao enviar formulário para o cliente");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-iqv-form-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;

  @media screen and (max-width: 991px) {
    span {
      font-size: 12px;
    }
    small {
      font-size: 10px;
    }
  }
}
</style>
