<template>
  <div class="iqv-edit-form-questions">
    <draggable tag="div" :list="questions" handle=".handle">
      <div
        v-for="(question, index) in questions"
        :key="index"
        class="card mb-4"
      >
        <div class="card-body">
          <div class="d-flex justify-content-between">
            <i class="icon-menu handle"></i>
            <i class="icon-x close" @click="removeAt(index)"></i>
          </div>

          <form-group
            v-model="question.title"
            label="TÍTULO"
            placeholder="Título"
            :id="'title' + index + id"
            class="mb-3"
            @input="update"
          />

          <b-form-checkbox v-model="question.mandatory" @input="update">
            Obrigatório
          </b-form-checkbox>

          <form-group
            v-model="question.type"
            label="TIPO"
            placeholder="Título"
            :id="'type' + index + id"
            class="mb-3"
            select
            :options="[
              { id: 'short_answer', name: 'Resposta simples' },
              { id: 'linear_scale', name: 'Nota de 0 a 10' },
              { id: 'single', name: 'Múltipla escolha - Uma resposta' },
              { id: 'multiple', name: 'Múltipla escolha - Múltiplas respostas' }
            ]"
            @input="update"
          />

          <edit-question-alternatives
            v-if="question.type !== 'linear_scale'"
            v-model="question.alternatives"
            @input="update"
          />

          <form-group
            v-if="question.type === 'linear_scale'"
            v-model="question.min_text"
            label="TEXTO DO MÍNIMO"
            placeholder="Texto da nota mínima"
            :id="'min_text' + index + id"
            class="mb-3"
            @input="update"
          />

          <form-group
            v-if="question.type === 'linear_scale'"
            v-model="question.max_text"
            label="TEXTO DO MÁXIMO"
            placeholder="Texto da nota máxima"
            :id="'max_text' + index + id"
            class="mb-3"
            @input="update"
          />

          <b-form-checkbox
            v-if="question.type !== 'linear_scale'"
            v-model="question.has_other"
            class="pt-3"
            @input="update"
          >
            Opção "Outro"
          </b-form-checkbox>
        </div>
      </div>
    </draggable>

    <button class="btn btn-primary" @click="addQuestion">
      Adicionar questão
    </button>
  </div>
</template>

<script>
import EditQuestionAlternatives from "./EditQuestionAlternatives";
import Draggable from "vuedraggable";
import get from "lodash/get";

export default {
  components: { EditQuestionAlternatives, Draggable },
  props: {
    section: {
      type: Object,
      default: () => ({})
    },
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      questions: get(this.section, "questions", []).map(q => ({
        alternatives: [],
        ...q
      }))
    };
  },
  methods: {
    update() {
      this.$emit("change", Object.values(this.questions));
    },
    removeAt(index) {
      this.questions.splice(index, 1);
    },
    addQuestion() {
      this.questions = [
        ...Object.values(this.questions),
        {
          mandatory: true,
          max: 10,
          max_text: "",
          min: 0,
          min_text: "",
          title: "",
          type: "linear_scale",
          alternatives: [],
          has_other: false
        }
      ];
    }
  }
};
</script>
