import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import state from "./state";
import mutations from "./mutations";

import user from "./user";
import customer from "./customer";
import lifeQuality from "./lifeQuality";
import charactersMap from "./charactersMap";
import analyses from "./analyses";
import analysts from "./analysts";
import masterKey from "./masterKey";
import ranking from "./ranking";
import admins from "./admins";
import schedules from "./schedules";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    user,
    customer,
    lifeQuality,
    charactersMap,
    analyses,
    analysts,
    masterKey,
    ranking,
    admins,
    schedules
  }
});
