<template>
  <div>
    <button
      type="button"
      class="btn btn-success px-5"
      :class="{ disabled }"
      @click="open"
    >
      {{ btnText }}
    </button>
    <b-modal v-model="visible" hide-footer hide-header centered>
      <div class="confirmation p-2">
        <div class="row justify-content-center mb-4">
          <img src="@/assets/images/rounded-mail-icon.svg" />
        </div>

        <h3 class="text-center px-4 pt-2 mb-3">
          Gerar Relatório
        </h3>

        <div class="text-center px-4 pt-2 pb-3 mb-4 text">
          Você pode fazer o download do PDF da análise combinada ou enviar por
          e-mail.
        </div>

        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="download"
            >
              <b-spinner variant="light" v-if="loading.download" />
              <span v-if="!loading.download">Fazer Download</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="sendToReceivers"
            >
              <b-spinner variant="light" v-if="loading.receivers" />
              <span v-if="!loading.receivers">Enviar para destinatários</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="sendToAnalyst"
            >
              <b-spinner variant="light" v-if="loading.analyst" />
              <span v-if="!loading.analyst">Enviar para analista</span>
            </button>
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="cancel"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    analyse: {
      type: Object,
      default: null
    },
    analystId: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      loading: {
        download: false,
        receivers: false,
        analyst: false
      }
    };
  },
  computed: {
    btnText() {
      return this.$user.user.user_type === "admin"
        ? "Gerar relatório"
        : "Enviar relatório";
    }
  },
  methods: {
    open() {
      if (this.disabled) {
        this.$message.error("Necessário primeiro salvar análise");
      } else {
        this.visible = true;
      }
    },
    cancel() {
      this.visible = false;
    },
    download() {
      if (this.loading.download) return;

      const action =
        this.$user.user.user_type === "admin"
          ? "analysts/downloadAnalyse"
          : "analyses/download";

      const payload =
        this.$user.user.user_type === "admin"
          ? {
              analyst_id: this.$route.params.analyst_id,
              analyse_id: this.analyse.id
            }
          : { analyse_id: this.analyse.id };

      this.loading.download = true;

      this.$store
        .dispatch(action, payload)
        .then(response => {
          const a = document.createElement("a");
          const blobURL = URL.createObjectURL(response.data);
          a.href = blobURL;

          const customerNameSplit = this.analyse.receivers[0].name.split(" ");

          const customerLastLetter =
            customerNameSplit.length > 1
              ? `-${customerNameSplit[customerNameSplit.length - 1]}`
              : "";

          const analystNameSplit = this.$user.user.name.split(" ");

          const analystLastLetter =
            analystNameSplit.length > 1
              ? `-${analystNameSplit[analystNameSplit.length - 1]}`
              : "";

          const pdfName = `${moment(this.analyse.last_update).format(
            "YYYYMMDD"
          )}-${customerNameSplit[0]}${customerLastLetter}-${
            analystNameSplit[0]
          }${analystLastLetter}.pdf`;

          a.setAttribute("download", pdfName);

          a.click();
          URL.revokeObjectURL(blobURL);
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.download = false;
        });
    },
    sendToAnalyst() {
      if (this.loading.analyst) return;
      this.loading.analyst = true;
      this.$store
        .dispatch("analyses/sendReportToAnalyst", {
          analyse_id: this.analyse.id
        })
        .then(response => {
          this.$message.success("Relatório enviado para o analista");
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.analyst = false;
        });
    },
    sendToReceivers() {
      if (this.loading.receivers) return;
      this.loading.receivers = true;
      this.$store
        .dispatch("analyses/sendReportToReceivers", {
          analyse_id: this.analyse.id
        })
        .then(response => {
          this.$message.success("Relatório enviado para o destinatário");
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.receivers = false;
        });
    }
  }
};
</script>
