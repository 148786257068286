<template>
  <div class="calendar-component-view">
    <table class="table mb-5">
      <thead>
        <tr>
          <th
            v-for="(name, idx) in DAYS_NAME"
            :key="`head-th-${idx}`"
            scope="col"
            class="text-muted text-center bg-lg-white p-2"
          >
            <span class="d-none d-lg-block">{{ name }}</span>
            <span class="d-lg-none">{{ name[0] }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(line, i) in lines" :key="`body-th-${i}`">
          <th
            v-for="(day, index) in DAYS_LIST.slice(line.start, line.end)"
            :key="`body-th-${index}`"
            class="bg-lg-white"
          >
            <schedules-table-item :day="day" @update="dispatch" />
          </th>
        </tr>
      </tbody>
    </table>

    <div
      v-if="currentDay"
      class="schedules-mobile-view bg-white mb-4 d-lg-none"
    >
      <div class="custom-light d-block text-center py-3">
        {{ completedCurrentDate }}
      </div>
      <div v-if="daySchedules.length">
        <div
          v-for="(schedule, index) in daySchedules"
          :key="`schedule-${index}`"
          class="d-flex flex-row py-3 px-2"
        >
          <span class="text-muted text-truncate flex-fill">
            {{ schedule.starts_in | formatDate }}
            <strong class="text-dark ml-3">
              {{ schedule.client.name }}
            </strong>
          </span>
          <button class="btn btn-sm mx-0 py-0 px-1" @click="openEdit(schedule)">
            <i class="icon-pencil text-primary" />
          </button>
          <button
            class="btn btn-sm mx-0 py-0 px-1"
            @click="openDelete(schedule)"
          >
            <i class="icon-trash text-danger" />
          </button>
        </div>
      </div>
      <div v-else class="text-center">
        Não há compromissos marcados neste dia
      </div>
    </div>

    <button
      v-if="currentDay"
      class="btn btn-success rounded-circle floating-button d-lg-none"
      @click="dialog = true"
    >
      <i class="icon-plus" />
    </button>

    <b-modal v-model="dialog" size="xl" centered hide-header hide-footer>
      <div class="p-3 d-flex flex-column">
        <div class="d-flex align-items-center mb-3">
          <h3 class="text-left flex-fill">Novo compromisso</h3>
          <button class="btn close-btn" @click="dialog = false">
            <i class="icon-x" style="font-size:26px;" />
          </button>
        </div>
        <search-select
          id="customer"
          label="CLIENTE"
          placeholder="Digite o nome do cliente"
          v-model="form.customer"
          url="clients"
          :errors="errors.customer"
        />
        <form-group
          v-model="mobileCurrentDate"
          id="date"
          label="DATA"
          disabled
        />
        <div class="row mb-5">
          <div class="col-6">
            <form-group
              v-model="form.starts_in"
              id="date"
              mask="##:##"
              masked
              maxlength="6"
              placeholder="Ex: 09:00 ou 15:00"
              label="HORÁRIO INICIAL"
              :errors="errors.starts_in"
            />
          </div>
          <div class="col-6">
            <form-group
              v-model="form.ends_in"
              id="date"
              mask="##:##"
              masked
              maxlength="6"
              placeholder="Ex: 10:00 ou 16:00"
              label="HORÁRIO FINAL"
              :errors="errors.ends_in"
            />
          </div>
        </div>
        <div class="row fixed-bottom pb-4 px-5">
          <div class="col-6">
            <button
              class="btn btn-outline-secondary btn-block"
              @click="closeManipulation"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button
              v-if="scheduleToManipulate"
              class="btn btn-outline-success btn-block"
              @click="submitUpdate(currentDay, dispatch)"
            >
              <b-spinner v-if="loading" />
              <span v-if="!loading">Atualizar</span>
            </button>
            <button
              v-if="!scheduleToManipulate"
              class="btn btn-outline-success btn-block"
              @click="submitSave(currentDay, dispatch)"
            >
              <b-spinner v-if="loading" />
              <span v-if="!loading">Agendar</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <confirmation
      class="d-lg-none"
      iconType="warning"
      title="Tem certeza que deseja realizar esta ação?"
      cancelButtonText="Cancelar"
      v-model="deleteDialog"
      confirmButtonClass="btn-danger"
      confirmButtonText="Excluir"
      :loading="loading"
      :text="removeScheduleInfo"
      @confirm="submitDelete(dispatch)"
      @cancel="closeManipulation"
    />
  </div>
</template>
<script>
import SchedulesTableItem from "@/components/schedules/SchedulesTableItem";
import { getArrayOfDaysInMonth } from "@/functions/helpers";
import ScheduleMixin from "@/mixins/Schedule";
import moment from "moment";

export default {
  components: { SchedulesTableItem },
  mixins: [ScheduleMixin],
  data() {
    return {
      DAYS_NAME: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
      DAYS_LIST: [],
      lines: [
        { start: 0, end: 7 },
        { start: 7, end: 14 },
        { start: 14, end: 21 },
        { start: 21, end: 28 },
        { start: 28, end: 35 }
      ]
    };
  },
  computed: {
    completedCurrentDate() {
      if (!this.currentDay) return "";

      return moment(
        `${this.year}-${this.month}-${this.currentDay.day}`,
        "YYYY-MM-DD"
      ).format(
        `${
          this.isSameDay(this.currentDay.day) ? "[Hoje,]" : ""
        } DD [de] MMMM [de] YYYY`
      );
    }
  },
  mounted() {
    this.DAYS_LIST = getArrayOfDaysInMonth(this.year, this.month);
  },
  watch: {
    month(newVal) {
      this.DAYS_LIST = getArrayOfDaysInMonth(this.year, newVal);
    },
    year(newVal) {
      this.DAYS_LIST = getArrayOfDaysInMonth(newVal, this.month);
    }
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("schedules/get", {})
        .then()
        .catch(error => {
          this.$message.error("Erro ao solicitar agendamentos");
        })
        .finally(() => {
          this.loading = false;
          if (this.currentDay) {
            const pattern = "YYYY-MM-DD";
            const today = moment(
              `${this.year}-${this.month}-${this.currentDay.day}`,
              pattern
            );

            const daySchedules = this.schedules.filter(ap =>
              today.isSame(moment(ap.date, pattern), "day")
            );
            this.$store.commit("schedules/setDaySchedules", daySchedules);
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.calendar-component-view {
  table {
    width: 100%;
    thead {
      th {
        border: 0px solid #fff;
        @media screen and (max-width: 991px) {
          font-weight: 300;
        }
      }
    }
    tbody {
      th {
        @media screen and (min-width: 992px) {
          width: 122px;
          height: 144px;
          max-width: 122px;
          max-height: 144px;
          font-size: 18px;
          border: 1px solid #e9e9e9;
        }

        @media screen and (max-width: 991px) {
          width: 35px;
          height: 34px;
          max-width: 35px;
          max-height: 34px;
          font-size: 13px;
        }
      }
    }
  }

  .schedules-mobile-view {
    min-height: 200px;
    .custom-light {
      background: #cad1de;
    }
  }

  .floating-button {
    position: absolute;
    bottom: 5%;
    right: 15%;
    i {
      font-weight: bold;
    }
  }
}
</style>
