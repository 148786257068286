<template>
  <div class="view-chart-char-map-data">
    <div class="text-right text-dark">{{ registerDate }}</div>
    <char-map-bar-chart class="pt-4" :customer="customer" />
  </div>
</template>

<script>
import CharMapBarChart from "@/components/charts/CharMapBarChart";
import moment from "moment";

export default {
  components: { CharMapBarChart },
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  computed: {
    registerDate() {
      if (!this.customer.last_map_update) return "";

      return moment(this.customer.last_map_update).format(
        "[Última atualização:] DD/MM/YYYY [ás] hh:mm"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.view-chart-char-map-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;
  font-size: 14px;
}
</style>
