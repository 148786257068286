<template>
  <div class="view-analyses">
    <h5 class="mb-4">Análise combinada</h5>

    <div class="row">
      <div class="col-lg-9">
        <search-filter @search="search" />
      </div>
      <div class="col text-right">
        <router-link
          class="btn btn-success px-5 position-relative btn-new"
          :to="{ name: 'analyses-new' }"
        >
          Nova análise
        </router-link>
      </div>
    </div>

    <div
      v-if="!loading && !analyses.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há análises cadastradas</strong>
      <span class="text-secondary">Cadastre uma análise para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :isSelectable="false"
      :loading="loading"
      :columns="columns"
      :data="analyses"
    >
      <template #action="{ item }">
        <button class="btn btn-sm mr-1" @click="openEdit(item)">
          <i class="icon-pencil text-dark" />
        </button>
        <button class="btn btn-sm" @click="openDelete(item)">
          <i class="icon-trash text-danger" />
        </button>
      </template>
    </data-table>
    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />

    <confirmation
      v-if="analyseToRemove"
      iconType="warning"
      title="Tem certeza que deseja deletar esta análise?"
      cancelButtonText="Cancelar"
      v-model="removeVisible"
      confirmButtonClass="btn-danger"
      confirmButtonText="Excluir"
      :loading="removeLoading"
      :text="
        `Você esta tentando deletar a análise #${analyseToRemove.id}, confirmar?`
      "
      @confirm="submitDelete"
      @cancel="removeVisible = false"
    />
  </div>
</template>

<script>
import TableCellLastReportAt from "../components/analyses/TableCellLastReportAt.vue";
import TableCellReceivers from "../components/analyses/TableCellReceivers.vue";
import TableCellParticipants from "../components/analyses/TableCellParticipants.vue";
import SearchFilter from "@/components/SearchFilter";
import PaginationMixin from "@/mixins/Pagination";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { SearchFilter },
  mixins: [PaginationMixin],
  data() {
    return {
      loading: false,
      removeVisible: false,
      removeLoading: false,
      analyseToRemove: null,
      columns: [
        {
          label: "ID",
          value: "id"
        },
        {
          label: "Título",
          value: "title"
        },
        {
          label: "Data de criação",
          value: "created_at",
          formatter: item => `<span class="text-secondary">
            ${
              item.created_at
                ? moment(item.created_at).format("DD/MM/YYYY")
                : ""
            }
          </span>`
        },
        {
          label: "Data de atualização",
          value: "updated_at",
          formatter: item => `<span class="text-secondary">
            ${
              item.updated_at
                ? moment(item.updated_at).format("DD/MM/YYYY")
                : ""
            }
          </span>`
        },
        {
          label: "Último relatório enviado",
          value: "reports.last_report_sent",
          component: TableCellLastReportAt
        },
        {
          label: "Destinatários",
          value: "receivers",
          component: TableCellReceivers
        },
        {
          label: "Participantes",
          value: "participants",
          component: TableCellParticipants
        }
      ]
    };
  },
  watch: {
    removeVisible(newVal) {
      if (!newVal) {
        this.analyseToRemove = null;
      }
    }
  },
  computed: {
    ...mapState("analyses", ["analyses", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("analyses/get", {
          ...this.params,
          orderBy: "id",
          limit: 20
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openEdit(item) {
      this.$store.commit("analyses/setAnalyse", item);
      this.$router.push({ name: "analyses-edit", params: { id: item.id } });
    },
    openDelete(item) {
      this.analyseToRemove = item;
      this.removeVisible = true;
    },
    submitDelete() {
      this.removeLoading = true;
      this.$store
        .dispatch("analyses/delete", { analyse_id: this.analyseToRemove.id })
        .then(() => {
          this.dispatch();
          this.$message.success("A análise foi excluída");
        })
        .catch(error => {
          this.$message.error("Erro ao tentar excluir a análise");
        })
        .finally(() => {
          this.analyseToRemove = null;
          this.removeLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-analyses {
  .empty {
    font-size: 14px;
    text-align: center;
  }
}
</style>
