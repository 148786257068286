import { saveUserInfo } from "../../setup/auth";

export default {
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/admins", data)
        .then(resolve)
        .catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/admins/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/admins?${queryString}`)
        .then(response => {
          context.commit("setAdmins", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  delete(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .delete(`/admins/${id}`)
        .then(resolve)
        .catch(reject)
    );
  },
  updateProfile(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/admins/${data.id}`, data) // data is { ...allUserInformation }
        .then(function(response) {
          saveUserInfo(response.data.data);
          context.commit("user/setUser", response.data.data, { root: true });
          resolve(response);
        })
        .catch(error => {
          //This code will repeat in all requests that user is logged
          if (error.response.status === 401 || error.response.status === 403) {
            // Logout user because he is unauthorized for any reason
            removeToken();
            context.commit("user/reset", null, { root: true });
          }
          reject(error);
        });
    });
  }
};
