export default {
  setRanking(state, ranking) {
    state.ranking = ranking;
  },
  setRankingCustomers(state, ranking) {
    state.rankingCustomers = ranking;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setCountries(state, countries) {
    state.countries = countries.map(c => ({ id: c, name: c }));
  },
  setStates(state, states) {
    state.states = states.map(s => ({ id: s, name: s }));
  },
  setCities(state, cities) {
    state.cities = cities.map(c => ({ id: c, name: c }));
  },
  setCompanies(state, companies) {
    state.companies = companies.map(c => ({ id: c, name: c }));
  },
  setSectors(state, sectors) {
    state.sectors = sectors.map(c => ({ id: c, name: c }));
  },
  setSelected(state, selected) {
    state.selected = selected;
  }
};
