<template>
  <div class="view-customer-new row">
    <div v-if="loadingPage" class="col text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>

    <div class="col text-center p-5" v-else-if="errorPage">
      Erro!
      <button class="btn-link bg-transparent border-0" @click="loadCustomer">
        Tentar novamente
      </button>
    </div>

    <div v-else class="col-lg-9">
      <h5 class="mb-5">
        <router-link class="back" :to="{ name: 'customer' }">
          <i class="icon-chevron-left"></i>
        </router-link>
        Clientes
      </h5>
      <h5 class="mb-4 pt-4">
        Etapa {{ step }}:
        <span v-if="step === 1">Dados pessoais</span>
        <span v-if="step === 2">Endereço</span>
      </h5>

      <step-1
        v-show="step === 1"
        ref="step-1"
        :form-data="firstStepData"
        :form-errors="formErrors"
        @show-warning="warningVisible = true"
        @next="nextStep"
      />

      <step-2
        v-show="step === 2"
        ref="step-2"
        :form-data="secondStepData"
        :form-errors="formErrors"
        :loading="loading"
        @back="step = 1"
        @show-warning="warningVisible = true"
        @submit="submit"
      />

      <confirmation
        title="Cadastro concluído!"
        text="O cliente foi cadastrado com sucesso! O que deseja fazer agora?"
        cancelButtonText="Voltar para o início"
        confirmButtonText="Mapa de Caracteres"
        confirmButtonClass="btn-success"
        :visible="confirmationVisible"
        no-close-on-backdrop
        @confirm="onSubmitSuccess"
        @cancel="$router.push({ name: 'customer' })"
      />
      <warning
        title="Campos vazios"
        text="Por favor, preencha os dados solicitados para salvar o cadastro do cliente."
        confirmButtonText="Voltar para cadastro"
        :visible="warningVisible"
        @change="closeWarning"
        :confirm="closeWarning"
      />
    </div>
  </div>
</template>

<script>
import Step1 from "../components/customer/Step1";
import Step2 from "../components/customer/Step2";

export default {
  components: { Step1, Step2 },
  data() {
    return {
      step: 1,
      firstStepData: null,
      secondStepData: null,
      formErrors: null,
      confirmationVisible: false,
      warningVisible: false,
      loading: false,
      isEdit: !!this.$route.params.id,
      loadingPage: false,
      errorPage: false,
      newId: null,
      loadedCustomerCpf: null
    };
  },
  mounted() {
    if (this.isEdit) {
      this.loadCustomer();
    }
  },
  methods: {
    loadCustomer() {
      this.loadingPage = true;
      this.$store
        .dispatch("customer/getDetails", this.$route.params.id)
        .then(response => {
          const data = {
            ...response.data.data,
            nacionalityDocument: response.data.data.foreign_document
              ? "Documento Estrangeiro"
              : "CPF",
            nacionalityAddress: response.data.data.foreign_address
              ? "Endereço Estrangeiro"
              : "Brasileiro",
            born_date: (response.data.data.born_date || "")
              .split("-")
              .reverse()
              .join("/"),
            image: response.data.data.image_url
          };
          this.loadedCustomerCpf = data.cpf;
          this.firstStepData = data;
          this.secondStepData = data;
        })
        .catch(() => {
          this.errorPage = true;
        })
        .finally(() => {
          this.loadingPage = false;
        });
    },
    nextStep(data) {
      this.firstStepData = data;
      this.step = 2;
    },
    submit(data) {
      this.secondStepData = data;

      this.loading = true;
      this.formErrors = null;

      const firstStepFields = [
        "analyst_id",
        "image",
        "name",
        "email",
        "born_date",
        "shirt_size",
        "nacionalityDdi",
        "ddi",
        "phone",
        "nacionalityDocument",
        "cpf",
        "foreign_document",
        "company",
        "sector"
      ];

      const action = this.isEdit ? "customer/update" : "customer/create";

      const submitData = {
        ...this.firstStepData,
        ...this.secondStepData,
        born_date: this.firstStepData.born_date
          .split("/")
          .reverse()
          .join("-")
      };

      if (submitData.shirt_size == "") {
        delete submitData.shirt_size;
      }

      if (submitData.nacionalityDocument === "Documento Estrangeiro") {
        delete submitData.cpf;
      }

      if (this.$user.user.user_type === "admin") {
        submitData.analyst_id = this.firstStepData.analyst.id;
      } else {
        submitData.analyst_id = this.$user.user.id;
      }

      if (this.isEdit) {
        submitData.id = this.$route.params.id;

        if (submitData.cpf === this.loadedCustomerCpf) {
          delete submitData.cpf;
        }
      }

      this.$store
        .dispatch(action, submitData)
        .then(response => {
          if (this.isEdit) {
            this.$router.push({ name: "customer-profile" });
          } else {
            this.newId = response.data.data.id;
            this.confirmationVisible = true;
          }
        })
        .catch(error => {
          this.formErrors = {
            ...this.formErrors,
            ...error.response.data.errors
          };
          for (let key in this.formErrors) {
            if (firstStepFields.includes(key)) {
              setTimeout(() => {
                this.step = 1;
              }, 1);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmitSuccess() {
      this.$router.push({ name: "char-map-form", params: { id: this.newId } });
    },
    closeWarning() {
      this.warningVisible = false;
    }
  }
};
</script>
