<template>
  <div class="template-account-menu">
    <b-dropdown
      variant="link"
      toggle-class="text-decoration-none text-dark"
      no-caret
      right
    >
      <template #button-content>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-column align-items-start pr-2">
            <strong>{{ $user.user ? $user.user.name : "" }}</strong>
            <small class="text-secondary">{{
              MAP_PERMISSION[
                `${$user.user ? $user.user.user_type : ""}`.toLowerCase()
              ]
            }}</small>
          </div>
          <i class="icon-chevron-down"></i>
        </div>
      </template>
      <b-dropdown-item :to="{ name: 'edit-profile' }">
        <small class="d-flex align-items-center">
          <i class="icon-pencil"></i>
          <strong>Editar perfil</strong>
        </small>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="logoutDialog = true">
        <small class="d-flex align-items-center text-danger">
          <i class="icon-logout"></i>
          <strong>Sair</strong>
        </small>
      </b-dropdown-item>
    </b-dropdown>

    <confirmation
      v-model="logoutDialog"
      @confirm="$emit('logout')"
      text="Tem certeza que deseja sair?"
      confirm-button-text="Sair"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoutDialog: false,
      MAP_PERMISSION: {
        admin: "Administrador",
        analyst: "Analista"
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.template-account-menu {
  position: absolute;
  top: 8px;
  right: 24px;

  /deep/ .dropdown-toggle {
    display: flex;
    max-height: 32px;
    min-height: 32px;
    align-items: center;
    padding: 0;

    strong {
      font-size: 14px;
      line-height: 17px;
      font-weight: 900;
    }
    small {
      font-size: 10px;
      line-height: 12px;
    }
    .icon-chevron-down {
      font-size: 16px;
    }
  }

  /deep/ .dropdown-item {
    padding: 4px 16px;
  }

  i {
    margin-right: 6px;
    font-size: 24px;
  }
}
</style>
