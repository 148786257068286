<template>
  <div class="home-table-cell-life-quality d-flex flex-column">
    <strong>
      {{ item.forms_answered }} respondido{{
        item.forms_answered !== 1 ? "s" : ""
      }}
    </strong>
    <small class="text-secondary">IQV direto: {{ item.iqv || "0" }}</small>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    lastUpdate() {
      return this.item.last_map_update
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    }
  }
};
</script>
