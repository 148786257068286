<template>
  <div class="admin-home-search-filter d-flex align-items-center">
    <i class="icon-search mr-2"></i>
    <input v-model="text" :placeholder="placeholder" />
  </div>
</template>

<script>
let timeout = null;

export default {
  props: {
    placeholder: {
      type: String,
      default: "Pesquisar"
    }
  },
  data() {
    return {
      text: ""
    };
  },
  watch: {
    text(val) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$emit("search", val);
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.admin-home-search-filter {
  border: 1px solid #aeb6c4;
  border-radius: 4px;
  padding-left: 12px;
  max-height: 40px;
  min-height: 40px;

  i,
  span {
    color: #aeb6c4;
  }

  i {
    font-size: 20px;
  }

  span {
    font-size: 24px;
  }

  input {
    border: none;
    width: 240px;
    background: transparent;
  }

  .dropdown-toggle {
    padding: 0 12px;
    max-height: 40px;
    min-height: 40px;
  }
}
</style>
