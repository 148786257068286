<template>
  <div class="data-table">
    <div class="table-wrapper">
      <table class="table">
        <thead>
          <tr>
            <th v-if="isSelectable" />
            <th v-for="(item, index) in columns" :key="index">
              {{ item.label }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="loading">
            <td :colspan="columns.length + 2" class="text-center">
              <b-spinner variant="primary" />
            </td>
          </tr>
          <tr v-else-if="data.length === 0">
            <td :colspan="columns.length + 2" class="text-center">
              Nenhum registro
            </td>
          </tr>
          <tr v-else v-for="(item, index) in data" :key="index">
            <td v-if="isSelectable">
              <b-form-checkbox v-model="selected[index]" />
            </td>
            <td
              v-for="(field, index) in columns"
              :key="index"
              @click="$emit('click', item)"
            >
              <component
                v-if="field.component"
                :is="field.component"
                :item="item"
              />
              <span
                v-else-if="field.formatter"
                v-html="field.formatter(item)"
              />
              <span v-else>{{ get(item, field.value) }}</span>
            </td>
            <td>
              <slot name="action" v-bind:item="item" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    columns: {
      type: Array,
      default: () => []
    },
    isSelectable: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: { ...Array(20).fill(false) }
    };
  },
  watch: {
    loading() {
      this.selected = { ...Array(20).fill(false) };
    }
  },
  methods: {
    get
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  padding-top: 30px;
  overflow-y: hidden;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .table-wrapper {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.6);
    padding: 16px;
    width: fit-content;
    min-width: 100%;
  }

  table {
    margin-bottom: -60px;
    transform: translateY(-50px);

    th {
      border: none;
      font-size: 12px;
      line-height: 14px;
      color: #aeb6c4;
      white-space: nowrap;
    }

    tr {
      td,
      th {
        border-top: none;
      }

      &:not(:last-child) {
        td,
        th {
          border-bottom: 1px solid #e9edf2;
        }
      }
    }

    // td {
    //   vertical-align: middle;
    // }
  }
}
</style>
