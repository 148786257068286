<template>
  <div class="view-admin-lq-versions">
    <h5 class="mb-4">Versões do formulário IQV</h5>

    <div class="row align-items-center">
      <div class="col-lg-9">
        <b-dropdown
          variant="outline-dark"
          class="dropdown"
          :text="ordernations[params.orderBy]"
        >
          <b-dropdown-item
            v-for="(name, key) in ordernations"
            :key="key"
            @click="params.orderBy = key"
          >
            {{ name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="col text-right">
        <button
          class="btn btn-success btn-new px-5"
          @click="$router.push({ name: 'lq-versions-new' })"
        >
          Criar nova versão
        </button>
      </div>
    </div>

    <div
      v-if="!loading && !versions.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há analistas cadastrados</strong>
      <span class="text-secondary">Cadastre um analista para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :loading="loading"
      :columns="columns"
      :data="versions"
      :is-selectable="false"
    >
      <template #action="{ item }">
        <actions-dropdown :actions="actions" @view="openView(item)" />
      </template>
    </data-table>

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />

    <b-modal v-model="formDialog" hide-header hide-footer centered size="xl">
      <view-version :item="itemToView" @close="formDialog = false" />
    </b-modal>
  </div>
</template>

<script>
import ViewVersion from "@/components/iqv/ViewVersion";
import { mapState } from "vuex";

export default {
  components: { ViewVersion },
  data() {
    return {
      ordernations: {
        created_at: "Mais recentes",
        answers: "Mais respondidos"
      },
      itemToView: null,
      formDialog: false,
      loading: false,
      error: false,
      params: {
        page: 1,
        orderBy: "created_at",
        sortedBy: "desc"
      },
      columns: [
        {
          label: "Versão",
          value: "id"
        },
        {
          label: "Quantidade respondido",
          value: "answers",
          formatter: item =>
            `${item.answers} respondido${item.answers === 1 ? "" : "s"}`
        },
        {
          label: "Criado em",
          value: "created_at",
          formatter: item =>
            item.created_at
              .split("T")[0]
              .split("-")
              .reverse()
              .join("/")
        }
      ],
      actions: [
        {
          icon: "icon-search",
          text: "Visualizar",
          class: "text-dark",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "view"
        }
      ]
    };
  },
  computed: {
    ...mapState("lifeQuality", ["versions", "pagination"])
  },
  watch: {
    "params.orderBy": function() {
      this.dispatch();
    }
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    openView(item) {
      this.formDialog = true;
      this.itemToView = item;
    },
    openNew() {
      // this.$router.push()
    },
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("lifeQuality/getVersions", this.params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-admin-lq-versions {
  .empty {
    font-size: 14px;
    text-align: center;
  }
}
</style>
