<template>
  <div
    class="calendar-table-item-view text-center text-lg-right text-dark font-weight-bold"
    :class="{ 'text-muted': !day.isCurrentMonth }"
  >
    <button
      class="btn d-none d-lg-block"
      :class="{ 'rounded-circle btn-success': isSameDay(day) }"
      @click="dialog = true"
    >
      {{ day.day }}
    </button>
    <button
      class="btn d-lg-none"
      :class="{
        'rounded-circle mobile-same-day-hightlight': isSameDay(day),
        'd-none': !day.isCurrentMonth
      }"
      @click="setDaySchedules"
    >
      {{ day.day }}
    </button>

    <div
      v-if="daySchedules.length"
      class="rounded-circle bg-dark d-lg-none dot"
    ></div>

    <div class="d-none d-lg-block" v-if="daySchedules.length">
      <div
        v-for="(schedule, index) in daySchedules.slice(0, 3)"
        :key="`schedule-${index}`"
        class="p-1 my-1 schedules d-block text-left"
      >
        <div class="d-flex flex-row">
          <span class="text-muted text-truncate flex-fill">
            {{ schedule.starts_in | formatDate }}
            <strong class="text-dark ml-1">
              {{ schedule.client.name }}
            </strong>
          </span>
          <button class="btn btn-sm mx-0 py-0 px-1" @click="openEdit(schedule)">
            <i class="icon-pencil text-primary" />
          </button>
          <button
            class="btn btn-sm mx-0 py-0 px-1"
            @click="openDelete(schedule)"
          >
            <i class="icon-trash text-danger" />
          </button>
        </div>
      </div>

      <b-dropdown
        v-if="daySchedules.length > 3"
        variant="link"
        toggle-class="text-decoration-none p-0 d-none d-lg-block"
        size="lg"
        no-caret
        block
      >
        <template #button-content>
          <button class="btn btn-block btn-sm schedules-see-more">
            Ver mais
          </button>
        </template>
        <b-dropdown-text class="text-dark font-weight-bold text-center">
          {{ day | currentDateWithNoYear }}
        </b-dropdown-text>
        <b-dropdown-text class="text-muted text-uppercase text-center">
          <small>{{ day | currentDayOfWeek }}</small>
        </b-dropdown-text>
        <b-dropdown-text
          v-for="(schedule, index) in daySchedules"
          :key="`schedule-${index}`"
          class="px-1"
        >
          <div class="p-1 my-1 schedules">
            <div class="d-flex flex-row">
              <span class="text-muted text-truncate flex-fill">
                {{ schedule.starts_in | formatDate }}
                <strong class="text-dark ml-1">
                  {{ schedule.client.name }}
                </strong>
              </span>
              <button
                class="btn btn-sm mx-0 py-0 px-1"
                @click="openEdit(schedule)"
              >
                <i class="icon-pencil text-primary" />
              </button>
              <button
                class="btn btn-sm mx-0 py-0 px-1"
                @click="openDelete(schedule)"
              >
                <i class="icon-trash text-danger" />
              </button>
            </div>
          </div>
        </b-dropdown-text>
      </b-dropdown>
    </div>

    <b-modal
      v-model="dialog"
      :visible="dialog"
      hide-header
      hide-footer
      centered
    >
      <div class="p-3 d-flex flex-column">
        <div class="row">
          <div class="col-3 mx-auto">
            <img class="mb-4" src="@/assets/images/icon-rounded-calendar.svg" />
          </div>
        </div>
        <h3 class="text-center mb-3">Agendar compromisso</h3>
        <search-select
          id="customer"
          label="CLIENTE"
          placeholder="Digite o nome do cliente"
          v-model="form.customer"
          url="clients"
          :errors="errors.customer"
        />
        <form-group v-model="currentDate" id="date" label="DATA" disabled />
        <div class="row mb-4">
          <div class="col-6">
            <form-group
              v-model="form.starts_in"
              id="date"
              mask="##:##"
              masked
              maxlength="6"
              placeholder="Ex: 09:00 ou 15:00"
              label="HORÁRIO INICIAL"
              :errors="errors.starts_in"
            />
          </div>
          <div class="col-6">
            <form-group
              v-model="form.ends_in"
              id="date"
              mask="##:##"
              masked
              maxlength="6"
              placeholder="Ex: 10:00 ou 16:00"
              label="HORÁRIO FINAL"
              :errors="errors.ends_in"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <button
              class="btn btn-outline-secondary btn-block"
              @click="closeManipulation"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button
              v-if="scheduleToManipulate"
              class="btn btn-outline-success btn-block"
              @click="submitUpdate(day)"
            >
              <b-spinner v-if="loading" />
              <span v-if="!loading">Atualizar</span>
            </button>
            <button
              v-if="!scheduleToManipulate"
              class="btn btn-outline-success btn-block"
              @click="submitSave(day)"
            >
              <b-spinner v-if="loading" />
              <span v-if="!loading">Agendar</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <confirmation
      iconType="warning"
      title="Tem certeza que deseja realizar esta ação?"
      cancelButtonText="Cancelar"
      v-model="deleteDialog"
      confirmButtonClass="btn-danger"
      confirmButtonText="Excluir"
      :loading="loading"
      :text="removeScheduleInfo"
      @confirm="submitDelete"
      @cancel="closeManipulation"
    />
  </div>
</template>
<script>
import ScheduleMixin from "@/mixins/Schedule";
import moment from "moment";

export default {
  mixins: [ScheduleMixin],
  props: {
    day: {
      type: Object,
      default: null
    }
  },
  computed: {
    daySchedules() {
      const pattern = "YYYY-MM-DD";
      const today = moment(
        `${this.year}-${this.month}-${this.day.day}`,
        pattern
      );

      return this.schedules.filter(ap =>
        today.isSame(moment(ap.date, pattern), "day")
      );
    },
    currentDate() {
      return moment(
        `${this.year}-${this.month}-${this.day.day}`,
        "YYYY-MM-DD"
      ).format("DD MMM [de] YYYY");
    }
  },
  methods: {
    setDaySchedules() {
      this.$store.commit("schedules/setDaySchedules", this.daySchedules);
      this.$store.commit("schedules/setCurrentDay", this.day);
    }
  }
};
</script>
<style lang="scss" scoped>
.calendar-table-item-view {
  position: relative;
  .schedules {
    background: #01416c33;
    border-radius: 2px;
    font-size: 12px;
  }

  .schedules-see-more {
    border: 1px solid #01416c33;
    border-radius: 2px;
    font-size: 12px;
    font-weight: bold;
    color: #01416c;
  }

  .dropdown-see-more {
    width: 100;
  }

  .mobile-same-day-hightlight {
    background: #2178fb25;
    color: #2178fb;
  }

  .dot {
    width: 5px;
    height: 5px;
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translate(-85%, -50%);
  }
}
</style>
