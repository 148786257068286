<template>
  <div class="customer-info d-flex align-items-center">
    <div class="mr-2">
      <img
        v-if="!!customer && !!customer.image_url"
        class="customer-picture big"
        :src="customer.image_url"
      />
      <span v-else class="customer-picture big"><i class="icon-user"></i></span>
    </div>
    <div class="d-flex flex-column">
      <span class="font-weight-bold">{{ customer.name }}</span>
      <small class="text-muted">
        {{ bornDate }} | {{ age }} ano{{ age !== 1 ? "s" : "" }}
      </small>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    customer: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    bornDate() {
      return this.customer.born_date
        .split("-")
        .reverse()
        .join("/");
    },
    age() {
      return moment().diff(
        moment(this.customer.born_date, "YYYY-MM-DD"),
        "year"
      );
    }
  }
};
</script>
