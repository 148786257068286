<template>
  <action-dispatcher
    action="analysts/getDetails"
    :parameters="$route.params.id"
  >
    <div v-if="analyst.id" class="view-analyst-profile row">
      <div class="col-lg-12">
        <h5 class="mb-4">
          <router-link class="back" :to="{ name: 'admin-analysts' }">
            <i class="icon-chevron-left"></i>
          </router-link>
          Perfil do analista
        </h5>

        <personal-data :analyst="analyst" />

        <h5 class="mt-3 mb-3">
          Análise Combinada
        </h5>

        <analyses-data :analyst="analyst" />
      </div>
    </div>

    <h6 class="mb-4">Clientes cadastrados</h6>

    <div
      v-if="!loading && !customers.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há clientes cadastrados</strong>
      <span class="text-secondary">Cadastre um cliente para começar</span>
    </div>

    <data-table
      v-else
      :loading="loading"
      :columns="columns"
      :data="customers"
      :is-selectable="false"
    />

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </action-dispatcher>
</template>

<script>
import PersonalData from "@/components/analysts/PersonalData";
import AnalysesData from "@/components/analysts/AnalysesData";
import TableCellName from "@/components/home/TableCellName";
import TableCellCharMap from "@/components/home/TableCellCharMap";
import TableCellLifeQuality from "@/components/home/TableCellLifeQuality";
import TableCellMasterKey from "@/components/home/TableCellMasterKey";

import { mapState } from "vuex";

export default {
  components: {
    PersonalData,
    AnalysesData
  },
  data() {
    return {
      loading: false,
      error: false,
      params: {
        page: 1,
        search: "",
        // letter: undefined,
        orderBy: "name"
        // sortedBy: undefined
      },
      columns: [
        {
          label: "Nome do cliente",
          value: "name",
          component: TableCellName
        },
        {
          label: "Mapa dos caracteres",
          value: "characters_map",
          component: TableCellCharMap
        },
        {
          label: "Formulário de acompanhamento",
          value: "forms_answered",
          component: TableCellLifeQuality
        },
        {
          label: "Chave mestra",
          value: "forms_answered",
          component: TableCellMasterKey
        }
      ]
    };
  },
  computed: {
    ...mapState("analysts", ["analyst", "customers", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("analysts/getClients", {
          id: this.$route.params.id,
          params: this.params
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    }
  }
};
</script>
