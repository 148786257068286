export default {
  sendForm(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/life_quality/send_form", data)
        .then(resolve)
        .catch(reject);
    });
  },

  getForm(context, code) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/life_quality/form?code=${code}`)
        .then(response => {
          context.commit("setForm", response.data.data);
          resolve();
        })
        .catch(reject);
    });
  },

  answer(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/life_quality/answer", data)
        .then(resolve)
        .catch(reject);
    });
  },

  customerAnswers(context, customer_id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/clients/${customer_id}/answers`)
        .then(resolve)
        .catch(reject);
    });
  },

  formAnswer(context, { customer_id, form_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/clients/${customer_id}/answers/${form_id}`)
        .then(resolve)
        .catch(reject);
    });
  },

  getVersions(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) => {
      window.axios
        .get(`/life_quality/versions?${queryString}`)
        .then(response => {
          context.commit("setVersions", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve();
        })
        .catch(reject);
    });
  },

  getVersion(context, id) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/life_quality/versions/${id}`)
        .then(response => {
          context.commit("setVersion", response.data.data);
          resolve();
        })
        .catch(reject);
    });
  },

  getLastVersion() {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/life_quality`)
        .then(resolve)
        .catch(reject);
    });
  },

  createVersion(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/life_quality`, data)
        .then(resolve)
        .catch(reject);
    });
  }
};
