export default {
  sendEmailToAnalyst(context, { customer_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/reports/characters/${customer_id}/analyst`)
        .then(resolve)
        .catch(reject);
    });
  },
  sendEmailToClient(context, { customer_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/reports/characters/${customer_id}/client`)
        .then(resolve)
        .catch(reject);
    });
  },
  downloadReport(context, { customer_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/reports/characters/${customer_id}/other`, {
          responseType: "blob"
        })
        .then(resolve)
        .catch(reject);
    });
  }
};
