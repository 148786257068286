<template>
  <action-dispatcher
    action="analyses/getDetails"
    :parameters="$route.params.id"
  >
    <div class="view-analyses-new">
      <h5 class="mb-4">Editar análise</h5>

      <step-1 v-if="step === 1" @next="nextStep" />
      <step-2 v-else @previous="previousStep" />
    </div>
  </action-dispatcher>
</template>

<script>
import Step1 from "@/components/analyses-edit/Step1";
import Step2 from "@/components/analyses-edit/Step2";

export default {
  components: { Step1, Step2 },
  data() {
    return {
      step: 1
    };
  },
  methods: {
    previousStep() {
      this.step = 1;
    },
    nextStep() {
      this.step = 2;
    }
  }
};
</script>
