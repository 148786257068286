export default {
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/ranking/forms?${queryString}`)
        .then(response => {
          context.commit("setRanking", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCustomers(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/ranking/clients?${queryString}`)
        .then(response => {
          context.commit("setRankingCustomers", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCountries(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/ranking/countries")
        .then(response => {
          context.commit("setCountries", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getStates(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/ranking/states")
        .then(response => {
          context.commit("setStates", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCities(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/ranking/cities")
        .then(response => {
          context.commit("setCities", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCompanies(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/ranking/companies")
        .then(response => {
          context.commit("setCompanies", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getSectors(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/ranking/sectors")
        .then(response => {
          context.commit("setSectors", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  downloadAllClients(context) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/reports/clients", { responseType: "blob" })
        .then(resolve)
        .catch(reject)
    );
  },
  downloadAllAnswers(context) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/reports/answers", { responseType: "blob" })
        .then(resolve)
        .catch(reject)
    );
  }
};
