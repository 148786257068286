<template>
  <action-dispatcher
    action="analysts/getDetails"
    :parameters="$route.params.id"
  >
    <div class="view-analyses">
      <h5 class="mb-4">
        {{ `Análises combinadas do analista ${analyst.name || ""}` }}
      </h5>

      <div class="row">
        <div class="col-lg-9">
          <search-filter @search="search" />
        </div>
      </div>

      <div
        v-if="!loading && !analyses.length"
        class="empty p-5 d-flex flex-column justify-content-center align-items-center"
      >
        <img src="@/assets/images/no-client.svg" />
        <strong>Ainda não há análises cadastradas</strong>
      </div>

      <data-table
        v-else
        class="mt-5"
        :isSelectable="false"
        :loading="loading"
        :columns="columns"
        :data="analyses"
      />
      <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
    </div>
  </action-dispatcher>
</template>

<script>
import TableCellLastReportAt from "@/components/analyses/TableCellLastReportAt.vue";
import TableCellReceivers from "@/components/analyses/TableCellReceivers.vue";
import TableCellParticipants from "@/components/adminAnalyses/TableCellParticipants.vue";
import SearchFilter from "@/components/SearchFilter";
import PaginationMixin from "@/mixins/Pagination";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { SearchFilter },
  mixins: [PaginationMixin],
  data() {
    return {
      loading: false,
      removeVisible: false,
      removeLoading: false,
      analyseToRemove: null,
      columns: [
        {
          label: "ID",
          value: "id"
        },
        {
          label: "Título",
          value: "title"
        },
        {
          label: "Data de criação",
          value: "created_at",
          formatter: item => `<span class="text-secondary">
            ${
              item.created_at
                ? moment(item.created_at).format("DD/MM/YYYY")
                : ""
            }
          </span>`
        },
        {
          label: "Data de atualização",
          value: "updated_at",
          formatter: item => `<span class="text-secondary">
            ${
              item.updated_at
                ? moment(item.updated_at).format("DD/MM/YYYY")
                : ""
            }
          </span>`
        },
        {
          label: "Último relatório enviado",
          value: "reports.last_report_sent",
          component: TableCellLastReportAt
        },
        {
          label: "Destinatários",
          value: "receivers",
          component: TableCellReceivers
        },
        {
          label: "Participantes",
          value: "participants",
          component: TableCellParticipants
        }
      ]
    };
  },
  computed: {
    ...mapState("analysts", ["analyst", "analyses", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("analysts/getAnalyses", {
          analyst_id: this.$route.params.id,
          ...this.params,
          orderBy: "id",
          limit: 20
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-analyses {
  .empty {
    font-size: 14px;
    text-align: center;
  }
}
</style>
