<template>
  <div>
    <div class="d-flex">
      <search-filter @search="search" />
      <div class="flex-fill"></div>
    </div>

    <div
      v-if="!loading && !customers.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há clientes cadastrados</strong>
      <span class="text-secondary">Cadastre um cliente para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      keyForCheckedTest="client_id"
      :loading="loading"
      :columns="columns"
      :data="customers"
      :is-selectable="false"
    />

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </div>
</template>

<script>
import TableCellSelectParticipant from "@/components/analyses/TableCellSelectParticipant";
import SearchFilter from "@/components/home/SearchFilter";
import CustomerMixin from "@/mixins/Customer";
import moment from "moment";

export default {
  mixins: [CustomerMixin],
  props: {
    participants: {
      type: Array,
      default: () => []
    }
  },
  components: { SearchFilter },
  data() {
    return {
      columns: [
        {
          label: "",
          value: "select",
          component: TableCellSelectParticipant
        },
        {
          label: "Nome do cliente",
          value: "name",
          formatter: item =>
            !!item.characters_map
              ? `<strong>${item.name}</strong>`
              : `<span class="text-muted"><s>${item.name}</s></span>`
        },
        {
          label: "E-mail",
          value: "email",
          formatter: item =>
            !!item.characters_map
              ? `<strong>${item.name}</strong>`
              : `<span class="text-muted"><s>${item.name}</s></span>`
        },
        {
          label: "Detalhes",
          value: "forms_answered",
          formatter: item =>
            `<div class="text-left d-flex flex-column">
                ${
                  !!item.characters_map
                    ? "<strong>Realizado</strong>"
                    : `<span class="text-muted">Mapa não realizado</span>`
                }
                <span class="text-muted">${moment(item.created_at).format(
                  "[Data de criação:] DD/MM/YYYY"
                )}
              </span>
            </div>`
        }
      ]
    };
  },
  methods: {
    onSelect(item) {
      this.$emit("onSelect", item);
    }
  }
};
</script>
