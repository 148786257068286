import { formFields } from "@/functions/helpers";
import { mapState } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      loading: false,
      scheduleToManipulate: null,
      ...formFields(["customer", "date", "starts_in", "ends_in"], ""),
      dialog: false,
      deleteDialog: false,
      visibleMobileSchedules: false
    };
  },
  computed: {
    ...mapState("schedules", [
      "year",
      "month",
      "schedules",
      "daySchedules",
      "currentDay"
    ]),
    ...mapState("customer", ["customers"]),
    removeScheduleInfo() {
      if (!this.scheduleToManipulate) return "";

      const date = moment(
        this.scheduleToManipulate.starts_in,
        "YYYY-MM-DD hh:mm:ss"
      ).format("DD/MM/YYYY [ás] HH:mm");

      return `O compromisso com o cliente ${this.scheduleToManipulate.client.name} em ${date} será excluido, confirmar exclusão?`;
    },
    mobileCurrentDate() {
      if (!this.currentDay) return "";

      return moment(
        `${this.year}-${this.month}-${this.currentDay.day}`,
        "YYYY-MM-DD"
      ).format("DD MMM [de] YYYY");
    }
  },
  filters: {
    formatDate(value) {
      return moment(value, "YYYY-MM-DD hh:mm:ss").format("HH:mm");
    },
    currentDayOfWeek(item) {
      return moment(
        `${this.year}-${this.month}-${item.day}`,
        "YYYY-MM-DD"
      ).format("dddd");
    },
    currentDateWithNoYear(item) {
      return moment(
        `${this.year}-${this.month}-${item.day}`,
        "YYYY-MM-DD"
      ).format("DD [de] MMMM");
    }
  },
  methods: {
    isSameDay(item) {
      if (!item || !item.isCurrentMonth) return false;

      return moment(
        `${this.year}-${this.month}-${item.day}`,
        "YYYY-MM-DD"
      ).isSame(moment(), "day");
    },
    openEdit(schedule) {
      this.scheduleToManipulate = schedule;
      this.form = {
        customer: schedule.client,
        starts_in: moment(schedule.starts_in, "YYYY-MM-DD hh:mm:ss").format(
          "HH:mm"
        ),
        ends_in: moment(schedule.ends_in, "YYYY-MM-DD hh:mm:ss").format("HH:mm")
      };
      this.dialog = true;
    },
    openDelete(schedule) {
      this.scheduleToManipulate = schedule;
      this.deleteDialog = true;
    },
    closeManipulation() {
      this.deleteDialog = false;
      this.dialog = false;
      this.scheduleToManipulate = null;
    },
    submitDelete(callback = null) {
      this.loading = true;
      this.$store
        .dispatch("schedules/delete", this.scheduleToManipulate.id)
        .then(() => {
          this.$message.success("Compromisso removido!");
          this.scheduleToManipulate = null;
          this.$emit("update");
          if (callback) callback();
        })
        .catch(error => {
          this.$message.error("Erro ao remover compromisso");
        })
        .finally(() => {
          this.loading = false;
          this.closeManipulation();
        });
    },
    submitUpdate(item, callback = null) {
      if (!this.scheduleToManipulate) return;

      this.loading = true;
      const month = this.month < 10 ? `0${this.month}` : this.month;
      const day = item.day < 10 ? `0${item.day}` : item.day;
      const date = `${this.year}-${month}-${day}`;
      this.$store
        .dispatch("schedules/update", {
          schedule_id: this.scheduleToManipulate.id,
          data: {
            date,
            client_id: this.form.customer.id,
            starts_in: `${date} ${this.form.starts_in}:00`,
            ends_in: `${date} ${this.form.ends_in}:00`
          }
        })
        .then(() => {
          this.$message.success("Compromisso atualizado!");
          this.closeManipulation();
          this.$emit("update");
          if (callback) callback();
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$message.error(error.response.data.message);
          } else {
            this.$message.error("Erro ao atualizar compromisso");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitSave(item, callback = null) {
      if (this.form.starts_in.length < 5 || this.form.ends_in.length < 5) {
        this.$message.error(
          "Digite corretamente a hora de início e hora do término (Ex: 15:00)"
        );
        return;
      }
      this.loading = true;
      const month = this.month < 10 ? `0${this.month}` : this.month;
      const day = item.day < 10 ? `0${item.day}` : item.day;
      const date = `${this.year}-${month}-${day}`;
      this.$store
        .dispatch("schedules/create", {
          date,
          client_id: this.form.customer.id,
          starts_in: `${date} ${this.form.starts_in}:00`,
          ends_in: `${date} ${this.form.ends_in}:00`
        })
        .then(() => {
          this.$message.success("Compromisso salvo!");
          this.form = {
            customer: "",
            starts_in: "",
            ends_in: ""
          };
          this.dialog = false;
          this.$emit("update");
          if (callback) callback();
        })
        .catch(error => {
          if (error.response.data.message) {
            this.$message.error(error.response.data.message);
          } else {
            this.$message.error("Erro ao salvar compromisso");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
