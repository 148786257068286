<template>
  <div class="view-admin-analysts-new row">
    <div v-if="loadingPage" class="col text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>

    <div class="col text-center p-5" v-else-if="errorPage">
      Erro!
      <button class="btn-link bg-transparent border-0" @click="loadAnalyst">
        Tentar novamente
      </button>
    </div>

    <form v-else class="col-lg-9" @submit.prevent="submit">
      <h5 class="mb-4">
        <router-link class="back" :to="{ name: 'admin-analysts' }">
          <i class="icon-chevron-left"></i>
        </router-link>
        Cadastrar analista
      </h5>

      <form-group
        id="name"
        label="NOME COMPLETO*"
        placeholder="Digite o nome do analista. Ex: João Silva"
        v-model="form.name"
        :errors="errors.name"
      />

      <form-group
        id="email"
        type="email"
        label="E-MAIL*"
        placeholder="Digite o e-mail do analista. Ex: joaosilva@gmail.com"
        v-model="form.email"
        :errors="errors.email"
      />

      <div class="row">
        <div class="col-lg-6">
          <form-group
            id="phone"
            type="tel"
            label="TELEFONE*"
            placeholder="Ex: (11) 9 9999-9999"
            :mask="isBr ? '(##) # ####-####' : '################'"
            v-model="form.phone"
            :errors="errors.phone"
          />
        </div>
        <div class="col-lg-6">
          <form-group
            id="cpf"
            :label="isBr ? 'CPF' : 'DOCUMENTO ESTRANGEIRO'"
            :type="isBr ? 'tel' : 'text'"
            placeholder="Ex: 123.456.789-01"
            :mask="isBr ? '###.###.###-##' : '################'"
            :masked="isBr"
            v-model="form.cpf"
            :errors="errors.cpf"
          />
        </div>
      </div>

      <div class="row justify-content-between">
        <div class="col-lg-6">
          <form-group
            id="country"
            label="PAÍS*"
            placeholder="Clique para selecionar o país"
            v-model="form.country"
            :select="true"
            :options="countryOptions"
            :errors="errors.country"
          />
        </div>
        <div class="col-lg-6">
          <form-group
            id="uf"
            label="UF*"
            placeholder="Clique para selecionar o estado"
            v-model="form.uf"
            :select="isBrasil"
            :options="stateOptions"
            :errors="errors.uf"
          />
        </div>
      </div>

      <form-group
        v-model="form.city"
        id="city"
        label="CIDADE*"
        placeholder="Clique para selecionar a cidade"
        :select="isBrasil"
        :options="cityOptions"
        :errors="errors.city"
      />

      <b-form-checkbox class="py-4" v-model="form.internal_analyst">
        Salvar como analista interno
      </b-form-checkbox>

      <div class="row justify-content-between pt-lg-5">
        <div class="col-5 col-lg-3">
          <hollow-button
            styleType="cancel"
            title="Cancelar"
            type="button"
            @click="$router.push({ name: 'admin-analysts' })"
          />
        </div>
        <div class="col-5 col-lg-3">
          <hollow-button title="Salvar" type="submit" :loading="loading" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { formFields, removeMask } from "@/functions/helpers";
import AddressForm from "@/mixins/AddressForm";

export default {
  mixins: [AddressForm],
  data() {
    return {
      isEdit: !!this.$route.params.id,
      loadingPage: false,
      errorPage: false,
      loading: false,
      loadedCpf: null,
      ...formFields(
        [
          "city",
          "country",
          "cpf",
          "email",
          "internal_analyst",
          "name",
          "phone",
          "uf"
        ],
        {
          country: "Brasil",
          internal_analyst: false
        }
      )
    };
  },
  computed: {
    isBr() {
      return this.form.country === "Brasil";
    }
  },
  mounted() {
    if (this.isEdit) {
      this.loadAnalyst();
    }

    /* mixin functions */
    this.loadCountries();
    this.loadStatesAndCities(brazilianStates => {
      const { country, uf } = this.form;
      if (country === "Brasil" && uf && uf.length) {
        const state = brazilianStates.states.find(st => st.uf === uf);
        this.cityOptions = state.cities.map(ct => ({ name: ct, id: ct }));
      }
    });
    /* mixin functions */
  },
  methods: {
    resetErrors() {
      for (let i in this.errors) this.errors[i] = [];
    },
    submit() {
      this.loading = true;
      this.resetErrors();

      const action = this.isEdit ? "analysts/update" : "analysts/create";

      const submitData = {
        ...this.form,
        cpf: this.isBr ? removeMask(this.form.cpf) : this.form.cpf
      };
      if (this.isEdit) {
        submitData.id = this.$route.params.id;
      }

      if (this.loadedCpf && removeMask(this.loadedCpf) === submitData.cpf) {
        delete submitData.cpf;
      }

      submitData.internal_analyst = !!submitData.internal_analyst;

      this.$store
        .dispatch(action, submitData)
        .then(() => {
          this.$router.push({ name: "admin-analysts" });
        })
        .catch(error => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadAnalyst() {
      this.loadingPage = true;
      this.$store
        .dispatch("analysts/getDetails", this.$route.params.id)
        .then(response => {
          this.loadedCpf = response.data.data.cpf;
          this.form = {
            ...response.data.data
          };
        })
        .catch(() => {
          this.errorPage = true;
        })
        .finally(() => {
          this.loadingPage = false;
        });
    }
  }
};
</script>
