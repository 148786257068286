<template>
  <div class="view-admin-admins">
    <h5 class="mb-4">Administradores</h5>

    <div class="row align-items-center">
      <div class="col-lg-9">
        <search-filter @search="search" />
      </div>
      <div class="col text-right">
        <button class="btn btn-success btn-new px-5" @click="openNew">
          Novo administrador
        </button>
      </div>
    </div>

    <div
      v-if="!loading && !admins.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há analistas cadastrados</strong>
      <span class="text-secondary">Cadastre um analista para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :loading="loading"
      :columns="columns"
      :data="admins"
      :is-selectable="false"
    >
      <template #action="{ item }">
        <actions-dropdown
          :actions="actions"
          @edit="edit(item)"
          @remove="remove(item)"
        />
      </template>
    </data-table>

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />

    <b-modal v-model="formDialog" hide-header hide-footer centered>
      <admin-form
        :user="itemToEdit"
        @close="formDialog = false"
        @saved="saved"
      />
    </b-modal>
  </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import AdminForm from "@/components/admins/Form";
import { mapState } from "vuex";

export default {
  components: { SearchFilter, AdminForm },
  data() {
    return {
      itemToEdit: null,
      formDialog: false,
      loading: false,
      error: false,
      params: {
        page: 1,
        search: "",
        // letter: undefined,
        orderBy: "name"
        // sortedBy: undefined
      },
      columns: [
        {
          label: "Nome",
          value: "name"
        },
        {
          label: "E-mail",
          value: "email"
        }
      ],
      actions: [
        {
          icon: "icon-pencil",
          text: "Editar",
          class: "text-dark",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "edit"
        },
        {
          icon: "icon-trash-3",
          text: "Excluir cadastro",
          confirmation: {
            enable: true,
            text:
              "Todos os dados referentes a este administrador serão deletados, porém analistas e seus respectivos clientes continuarão no sistema, confirmar ação?",
            submitText: "Confirmar",
            submitButtonClass: "btn-danger"
          },
          class: "text-danger",
          event: "remove"
        }
      ]
    };
  },
  computed: {
    ...mapState("admins", ["admins", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    openEdit(item) {
      this.formDialog = true;
      this.itemToEdit = item;
    },
    openNew() {
      this.formDialog = true;
      this.itemToEdit = null;
    },
    saved() {
      this.formDialog = false;
      this.dispatch();
    },
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("admins/get", this.params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    },
    edit(item) {
      this.openEdit(item);
    },
    remove(item) {
      if (this.$user.user.id === item.id) {
        this.$message.error(
          "Para deletar o próprio cadastro, solicite esta ação a outro administrador"
        );
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("admins/delete", item.id)
        .then(() => {
          this.$message.success("O administrador foi deletado");
          this.dispatch();
        })
        .catch(error => {
          this.$message.error("Erro ao deletar administrador");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-admin-admins {
  .empty {
    font-size: 14px;
    text-align: center;
  }
}
</style>
