<template>
  <router-link
    :to="{ name: 'analyses-analyst-details', params: { id: item.id } }"
    type="button"
    class="btn btn-sm text-success d-flex align-items-center"
  >
    <img class="img-fluid" src="@/assets/images/idea-green-icon.svg" />
    <span>Ver todos</span>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
