<template>
  <button class="btn btn-sm d-flex">
    <div class="d-flex flex-column justify-content-start">
      <span v-html="formInfo" class="text-left" />
    </div>
  </button>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formInfo() {
      const {
        iqv,
        forms_answered,
        forms_overview,
        last_life_quality_form_send
      } = this.item;

      if (!last_life_quality_form_send)
        return `<span class="text-muted">Nenhum formulário enviado</span>`;

      if (iqv === null && last_life_quality_form_send !== null) {
        return `
          <div class="d-flex flex-column">
            <strong>${moment(last_life_quality_form_send).format(
              "[Último envio:] DD/MM/YYYY [às] H:mm"
            )}</strong>
            <span class="text-muted">Nenhum formulário respondido</span>
          </div>`;
      }

      if (forms_answered === 1)
        return `<div class="d-flex flex-column text-left">
                  <span>1 formulário respondido</span>
                  <span class="text-muted">
                      ${moment(last_life_quality_form_send).format(
                        "[Último envio:] DD/MM/YYYY [às] H:mm"
                      )}
                  </span>
                  <span class="text-muted">IQV inicial: ${iqv}</span>
                  <span class="text-muted">
                      ${moment(forms_overview.iqv_answered_at).format(
                        "[Respondido em:] DD/MM/YYYY [às] H:mm"
                      )}
                  </span>
              </div>`;

      if (forms_answered > 1) {
        const text =
          forms_answered > 1
            ? "formulários respondidos"
            : "formulário respondido";
        const improveScore = Math.round(forms_overview.improvement_score * 100);
        return `
              <div class="d-flex flex-column text-left">
                  <span>${forms_answered} ${text}</span>
                  <span class="text-muted">
                      ${moment(last_life_quality_form_send).format(
                        "[Último envio:] DD/MM/YYYY [às] H:mm"
                      )}
                  </span>
                  <span class="text-muted">
                      ${moment(forms_overview.previous_iqv_answered_at).format(
                        "[Anterior respondido em:] DD/MM/YYYY [às] H:mm"
                      )}
                  </span>
                  <span class="text-muted">
                      ${moment(forms_overview.iqv_answered_at).format(
                        "[Atual respondido em:] DD/MM/YYYY [às] H:mm"
                      )}
                  </span>
                  <span class="text-muted">
                      IQV inicial: ${forms_overview.previous_iqv}
                  </span>
                  <span class="text-muted">IQV atual: ${iqv}</span>
                  <span class="text-muted">Índice de Resultado: ${improveScore}%</span>
            </div>`;
      }
      return null;
    }
  }
};
</script>
