export default {
  data() {
    return {
      actions: [
        {
          icon: "icon-pencil",
          text: "Editar",
          class: "text-dark",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "edit"
        },
        {
          icon: "icon-trash-3",
          text: "Excluir cadastro",
          confirmation: {
            enable: true,
            text:
              "Todos os dados referentes a este cliente serão deletados, confirmar?",
            submitText: "Confirmar"
          },
          class: "text-danger",
          event: "remove"
        }
      ]
    };
  },
  methods: {
    edit(item) {
      this.$router.push({ name: "edit-customer", params: { id: item.id } });
    },
    remove(item) {
      this.loading = true;
      this.$store
        .dispatch("customer/delete", item.id)
        .then(() => {
          this.$message.success("O cliente foi deletado");
          this.dispatch();
        })
        .catch(error => {
          this.$message.error("Erro ao deletar cliente");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
