export default {
  pagination: {},
  customer: {},
  customers: [],

  countries: [],
  states: [],
  cities: [],
  companies: [],
  sectors: []
};
