<template>
  <div class="d-flex flex-column">
    <strong
      >{{ item.master_key_overview.fact_count }} chave{{
        item.master_key_overview.fact_count !== 1 ? "s" : ""
      }}
      mestra{{ item.master_key_overview.fact_count !== 1 ? "s" : "" }}</strong
    >
    <small class="text-secondary"
      >Média de Etapas realizadas: {{ stepAverage }}</small
    >
    <small class="text-secondary"
      >Última etapa realizada: {{ lastCompletedStep }}</small
    >
    <small class="text-secondary"
      >Relatórios enviados: {{ item.master_key_overview.sent_count }}</small
    >
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    stepAverage() {
      if (!this.item.master_key_overview.step_count_average) return 0;

      return Math.round(this.item.master_key_overview.step_count_average);
    },
    lastCompletedStep() {
      if (!this.item.master_key_overview.last_fact_update)
        return "Nenhuma concluída";

      return moment(
        this.item.master_key_overview.last_fact_update,
        "YYYY-MM-DD hh:mm:ss"
      ).format("DD/MM/YYYY");
    }
  }
};
</script>
