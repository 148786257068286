<template>
  <div>
    <button
      class="btn btn-outline-dark d-flex align-items-center"
      @click="openReport"
    >
      <i class="icon-pdf-file mr-1"></i>
      Gerar relatório da chave mestra
      <i class="icon-arrow-right ml-1" />
    </button>

    <b-modal v-model="dialog" hide-footer hide-header centered>
      <div v-if="step === 1" class="confirmation p-3">
        <h3 class="text-center px-4 pt-2 mb-4">Enviar relatório</h3>

        <span class="mb-3 text-muted pt-4"
          >Selecione quais etapas serão incluídas no relatório:</span
        >

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            class="mb-3 pt-3 text-muted"
            stacked
            :aria-describedby="ariaDescribedby"
            v-model="selecteds"
            :options="
              currentFact.steps.map(value => ({
                text: `Etapa ${value.step}`,
                value
              }))
            "
          />
        </b-form-group>

        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="onClose"
            >
              Cancelar
            </button>
          </div>
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="next"
            >
              Avançar
            </button>
          </div>
        </div>
      </div>
      <div v-if="step === 2" class="confirmation p-4">
        <h3 class="text-center mb-4">Enviar relatório</h3>

        <div class="text-muted text-center mb-4">
          Selecione a opção de compartilhamento desejada:
        </div>
        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="download"
            >
              <b-spinner variant="light" v-if="loading.download" />
              <span v-if="!loading.download">Fazer download</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="submitToAnalyst"
            >
              <b-spinner variant="light" v-if="loading.analyst" />
              <span v-if="!loading.analyst">Enviar para o analista</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="submitToCustomer"
            >
              <b-spinner variant="light" v-if="loading.customer" />
              <span v-if="!loading.customer">Enviar para o cliente</span>
            </button>
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="previous"
            >
              Voltar
            </button>
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="onClose"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
export default {
  data() {
    return {
      step: 1,
      dialog: false,
      selecteds: [],
      loading: {
        download: false,
        analyst: false,
        customer: false
      }
    };
  },
  computed: {
    ...mapState("masterKey", ["currentFact"]),
    ...mapState("customer", ["customer"])
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selecteds = [];
      }
    }
  },
  methods: {
    openReport() {
      const published = this.currentFact.steps.filter(
        s => !!s.action_character
      );
      if (published.length === 0) {
        this.$message.error("Este fato ainda não tem nenhum passo completo");
        return;
      }
      this.dialog = true;
    },
    onClose() {
      this.step = 1;
      this.selecteds = [];
      this.dialog = false;
    },
    previous() {
      this.step = 1;
    },
    next() {
      if (!this.selecteds.length) {
        this.$message.error(
          "Primeiro, selecione pelo menos 1 etapa para incluir no relatório"
        );
        return;
      }
      this.step = 2;
    },
    download() {
      this.loading.download = true;
      this.$store
        .dispatch("masterKey/downloadPdf", {
          customer_id: this.$route.params.id,
          fact_Id: this.currentFact.id,
          steps: this.selecteds.map(s => s.step)
        })
        .then(response => {
          const a = document.createElement("a");
          const blobURL = URL.createObjectURL(response.data);
          a.href = blobURL;

          const customerSplit = this.customer.name.split(" ");
          const customerFirstLetter = customerSplit[0];
          const customerSecondLetter =
            customerSplit.length > 1
              ? `-${customerSplit[customerSplit.length - 1]}`
              : "";

          const analystSplit = this.$user.user.name.split(" ");
          const analystFirstLetter = analystSplit[0];
          const analystSecondLetter =
            analystSplit.length > 1
              ? `-${analystSplit[analystSplit.length - 1]}`
              : "";

          const pdfName = `${moment(this.currentFact.last_updated_at).format(
            "YYYYMMDD"
          )}-${customerFirstLetter}${customerSecondLetter}-${analystFirstLetter}${analystSecondLetter}.pdf`;

          a.setAttribute("download", pdfName);
          a.click();
          URL.revokeObjectURL(blobURL);
          this.$message.success("O PDF foi baixado!");
        })
        .catch(error => {
          this.$message.error("Erro ao fazer download do PDF");
        })
        .finally(() => {
          this.loading.download = false;
        });
    },
    submitToAnalyst() {
      this.loading.analyst = true;
      this.$store
        .dispatch("masterKey/sendReportToAnalyst", {
          customer_id: this.$route.params.id,
          fact_Id: this.currentFact.id,
          steps: this.selecteds.map(s => s.step)
        })
        .then(() => {
          this.$message.success("Relatório enviado para o analista");
        })
        .catch(error => {
          this.$message.error("Erro ao enviar relatório para o analista");
        })
        .finally(() => {
          this.loading.analyst = false;
        });
    },
    submitToCustomer() {
      this.loading.customer = true;
      this.$store
        .dispatch("masterKey/sendReportToCustomer", {
          customer_id: this.$route.params.id,
          fact_Id: this.currentFact.id,
          steps: this.selecteds.map(s => s.step)
        })
        .then(() => {
          this.$message.success("Relatório enviado para o cliente");
        })
        .catch(error => {
          this.$message.error("Erro ao enviar relatório para o cliente");
        })
        .finally(() => {
          this.loading.customer = false;
        });
    }
  }
};
</script>
