<template>
  <div class="text-secondary">
    <span v-if="item.reports.last_report_sent">{{ dateLabel }}</span>
    <small v-else>Nenhum relatório enviado</small>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    dateLabel() {
      return moment(
        this.item.reports.last_report_sent,
        "YYYY-MM-DD hh:mm:ss"
      ).format("DD/MM/YYYY");
    }
  }
};
</script>
