export default {
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/schedules", data)
        .then(resolve)
        .catch(reject);
    });
  },
  update(context, { schedule_id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/schedules/${schedule_id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/schedules?${queryString}`)
        .then(response => {
          context.commit("setSchedules", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  delete(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .delete(`/schedules/${id}`)
        .then(resolve)
        .catch(reject)
    );
  }
};
