<template>
  <div class="view-customer">
    <h5 class="mb-4">Clientes</h5>

    <div class="row">
      <div class="col-lg-8">
        <search-filter @search="search" :searchValue="params.search" />
      </div>
      <div
        v-if="!!listOfActiveFilters.length"
        class="col-1 d-flex align-items-center"
      >
        <button class="btn btn-sm btn-outline-warning" @click="removeFilters">
          Limpar
        </button>
      </div>
      <div class="col text-right">
        <router-link
          class="btn btn-success px-5 position-relative btn-new"
          :to="{ name: 'register-customer' }"
        >
          Novo cliente
        </router-link>
      </div>
    </div>

    <div v-if="!!listOfActiveFilters.length" class="row pt-2">
      <div class="col-2 m-0 text-muted">Filtros ativos:</div>
      <div
        v-for="(filter, index) in listOfActiveFilters"
        :key="index"
        class="col p-0 m-0"
      >
        <button class="btn btn-sm letter-indicator">
          <span class="text-dark">{{ filter }}</span>
        </button>
      </div>
    </div>

    <div
      v-if="!loading && !customers.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há clientes cadastrados</strong>
      <span class="text-secondary">Cadastre um cliente para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :isSelectable="false"
      :loading="loading"
      :columns="columns"
      :data="customers"
    >
      <template #action="{ item }">
        <actions-dropdown
          :actions="actions"
          @edit="edit(item)"
          @remove="remove(item)"
        />
      </template>
    </data-table>

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </div>
</template>

<script>
import SearchFilter from "@/components/home/SearchFilter";
import TableCellName from "../components/home/TableCellName";
import TableCellCharMap from "../components/home/TableCellCharMap";
import TableCellLifeQuality from "../components/home/TableCellLifeQuality";
import TableCellMasterKey from "../components/home/TableCellMasterKey";
import CustomerMixin from "@/mixins/Customer";
import DeleteCustomerMixin from "@/mixins/CustomerDropdown";

export default {
  mixins: [CustomerMixin, DeleteCustomerMixin],
  components: { SearchFilter },
  data() {
    return {
      columns: [
        {
          label: "Nome do cliente",
          value: "name",
          component: TableCellName
        },
        {
          label: "Mapa dos caracteres",
          value: "characters_map",
          component: TableCellCharMap
        },
        {
          label: "Formulário de acompanhamento",
          value: "forms_answered",
          component: TableCellLifeQuality
        },
        {
          label: "Chave mestra",
          value: "forms_answered",
          component: TableCellMasterKey
        }
      ]
    };
  },
  methods: {
    removeFilters() {
      this.params.search = "";
      this.dispatch();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-customer {
  .empty {
    font-size: 14px;
    text-align: center;
  }
  .letter-indicator {
    background: rgba(1, 65, 108, 0.1);
  }
}
</style>
