<template>
  <div class="view-master-key">
    <h5 class="mb-4">
      <router-link class="back" :to="{ name: 'customer-profile' }">
        <i class="icon-chevron-left"></i>
      </router-link>
      Chave Mestra
    </h5>
    <div class="d-flex justify-content-end">
      <generate-report-button v-if="!!currentFact" />
    </div>

    <h6>Fato em questão</h6>

    <fact-selector />

    <template v-if="!!currentFact">
      <h6>Etapa</h6>

      <stage-selector />
    </template>

    <div v-if="currentFact && currentStep" class="row">
      <div v-if="!!currentStep.action_character" class="col-lg-6">
        <h6 class="d-flex justify-content-between align-items-center">
          <span>Reforço de recurso</span>
          <small
            class="font-weight-bold"
            :class="{
              'text-success': traitIndicator.type === 'resource',
              'text-danger': traitIndicator.type === 'pain',
              'text-secondary': !traitIndicator.type
            }"
          >
            {{ traitIndicator.text }}
          </small>
        </h6>

        <zone-chart />

        <h6>Traços</h6>

        <traits-details />

        <h6>Ação de proteção</h6>

        <div class="card">
          <div class="card-body">
            {{ currentStep.action || "" }}
          </div>
        </div>

        <h6>Dicas, anotações e observações</h6>

        <div class="card">
          <div class="card-body">
            {{ currentStep.notes || "" }}
          </div>
        </div>
      </div>
      <div v-else class="col-lg-6"></div>
      <div class="col-lg-6">
        <h6>Etapa {{ currentStep.step }}</h6>

        <stage-details />
      </div>
    </div>
  </div>
</template>

<script>
import FactSelector from "@/components/master-key/FactSelector";
import GenerateReportButton from "@/components/master-key/GenerateReportButton";
import StageSelector from "@/components/master-key/StageSelector";
import StageDetails from "@/components/master-key/StageDetails";
import TraitsDetails from "@/components/master-key/TraitsDetails";
import ZoneChart from "@/components/master-key/ZoneChart";
import charMapUtils from "@/functions/charactermap";
import { mapState } from "vuex";

export default {
  components: {
    FactSelector,
    StageSelector,
    StageDetails,
    TraitsDetails,
    ZoneChart,
    GenerateReportButton
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("masterKey", ["currentFact", "currentStep", "traitIndicator"])
  },
  mounted() {
    this.dispatchCustomer();
  },
  methods: {
    dispatchCustomer() {
      this.$store.commit("customer/setCustomer", {}); // clear customer state for ensure the correct customer
      this.$store
        .dispatch("customer/getDetails", this.$route.params.id) // get the correct customer info
        .then(() => {
          this.fillTraitsPercent();
        })
        .catch(error => {
          this.$message.error("Erro ao pedir dados do cliente");
        });
    },
    fillTraitsPercent() {
      const { sumTraitsByKey } = charMapUtils;
      const { characters_map } = this.customer;
      this.$store.commit("masterKey/setTraitsPercent", {
        E: {
          percent: (sumTraitsByKey(characters_map, "E") * 100) / 60,
          char: "E"
        },
        M: {
          percent: (sumTraitsByKey(characters_map, "M") * 100) / 60,
          char: "M"
        },
        O: {
          percent: (sumTraitsByKey(characters_map, "O") * 100) / 60,
          char: "O"
        },
        P: {
          percent: (sumTraitsByKey(characters_map, "P") * 100) / 60,
          char: "P"
        },
        R: {
          percent: (sumTraitsByKey(characters_map, "R") * 100) / 60,
          char: "R"
        }
      });
    }
  }
};
</script>

<style lang="scss">
.view-master-key {
  h6 {
    margin-bottom: 16px;
  }

  .card {
    margin-bottom: 40px;
  }

  .icon-pdf-file {
    font-size: 24px;
  }
}
</style>
