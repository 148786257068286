<template>
  <action-dispatcher
    action="customer/getDetails"
    :parameters="$route.params.id"
    @success="loadedCustomer"
  >
    <div class="view-char-map-form">
      <h5 class="mb-4">
        <router-link class="back" :to="{ name: 'customer-profile' }">
          <i class="icon-chevron-left"></i>
        </router-link>
        Mapa dos caracteres
      </h5>

      <customer-info class="mb-5" :customer="customer" />

      <body-part-selector v-model="bodyType" class="mb-5" :points="points" />

      <div class="row">
        <div class="col-lg-6">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h6 class="mb-0">Distribuição dos pontos</h6>
            <small>
              <strong class="text-success"
                >Restam {{ points[bodyType] }} pontos</strong
              >
            </small>
          </div>

          <points-selector
            v-for="(letter, index) in traits"
            :key="letter + bodyType"
            v-model="form[bodyType].traits[index].value"
            :points="points[bodyType]"
            :letter="letter"
            @click.native="trait = index"
            :selected="trait === index"
          />
        </div>

        <div class="col-lg-6" style="padding-top: 35px;">
          <div class="card mb-4">
            <div class="card-body">
              <div class="row">
                <div class="col-6 d-flex flex-column">
                  <strong>{{ traitName }}</strong>
                  <small class="text-secondary">Traço de caráter</small>
                </div>
                <div class="col-6 d-flex flex-column">
                  <strong>{{ bodyTypeName }}</strong>
                  <small class="text-secondary">Parte do corpo</small>
                </div>
              </div>
            </div>
          </div>

          <label>comentário da parte do corpo</label>
          <textarea
            v-model="form[bodyType].notes"
            class="form-control mb-4"
            placeholder="Digite aqui o seu comentário"
            rows="3"
          ></textarea>

          <label>comentário do traço de caracter (lateralidade)</label>
          <textarea
            v-model="form[bodyType].traits[trait].comment"
            class="form-control mb-4"
            placeholder="Digite aqui o seu comentário"
            rows="3"
          ></textarea>

          <div class="d-flex">
            <b-form-checkbox v-model="form[bodyType].traits[trait].left"
              >Esquerdo</b-form-checkbox
            >
            <div class="mx-3"></div>
            <b-form-checkbox v-model="form[bodyType].traits[trait].right"
              >Direito</b-form-checkbox
            >
          </div>
        </div>
      </div>

      <div class="d-flex pt-5">
        <button
          v-if="bodyType > 0"
          class="btn btn-outline-success px-5"
          @click="bodyType--"
        >
          <i class="icon-arrow-left"></i> Anterior
        </button>
        <div class="flex-fill"></div>
        <button
          v-if="bodyType < 5"
          class="btn px-5"
          :class="{
            'btn-outline-success': points[bodyType] === 0,
            'btn-outline-secondary': points[bodyType] !== 0
          }"
          :disabled="points[bodyType] !== 0"
          @click="bodyType++"
        >
          Próximo <i class="icon-arrow-right"></i>
        </button>
        <button
          v-if="bodyType === 5"
          class="btn btn-outline-success px-5"
          @click="save"
        >
          <loading :show="loading">Salvar</loading>
        </button>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import BodyPartSelector from "../components/char-map/BodyPartSelector";
import PointsSelector from "../components/char-map/PointsSelector";
import CustomerInfo from "../components/CustomerInfo";
import { mapState } from "vuex";

const traits = ["E", "O", "P", "M", "R"];
const bodyTypes = ["head", "eyes", "mouth", "back", "hip", "legs"];

export default {
  components: { BodyPartSelector, CustomerInfo, PointsSelector },
  data() {
    return {
      editing: false,
      forceRecompute: 0,
      loading: false,
      trait: 0,
      bodyType: 0,
      traits,
      bodyTypes,
      form: bodyTypes.map(type => ({
        type,
        notes: "",
        traits: traits.map(name => ({
          name,
          value: 0,
          comment: "",
          left: false,
          right: false
        }))
      }))
    };
  },
  computed: {
    ...mapState("customer", ["customer"]),
    bodyTypeName() {
      return ["Cabeça", "Olhos", "Boca", "Tronco", "Quadril", "Pernas"][
        this.bodyType
      ];
    },
    traitName() {
      return ["Esquizoide", "Oral", "Psicopata", "Masoquista", "Rígido"][
        this.trait
      ];
    },
    points() {
      this.forceRecompute;
      return bodyTypes.map((val, index) => {
        return (
          10 -
          this.form[index].traits.reduce(
            (prev, cur) => prev + (parseInt(cur.value) || 0),
            0
          )
        );
      });
    }
  },
  methods: {
    loadedCustomer() {
      if (!this.customer.characters_map) return;

      this.editing = true;
      for (let i in this.form) {
        const bodyType = this.customer.characters_map.find(
          c => c.type === this.form[i].type
        );
        this.form[i].notes = bodyType.notes;
        for (let j in this.form[i].traits) {
          const trait = bodyType.traits.find(
            c => c.name === this.form[i].traits[j].name
          );
          this.form[i].traits[j] = trait;
        }
      }

      this.forceRecompute++;
    },
    save() {
      const action = this.editing
        ? "customer/updateCharacter"
        : "customer/createCharacter";

      this.loading = true;
      this.$store
        .dispatch(action, {
          id: this.$route.params.id,
          data: { characters: this.form }
        })
        .then(() => {
          this.$message.success("Mapa dos caracteres realizado com sucesso");
          this.$router.push({ name: "customer-profile" });
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-char-map-form {
  label {
    text-transform: uppercase;
  }

  textarea {
    border: 1px solid #e9edf2;
    border-radius: 4px;
  }
}
</style>
