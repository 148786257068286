export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const emojis = [
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😉",
  "😊",
  "😋",
  "😌",
  "😍",
  "😏",
  "😒",
  "😓",
  "😔",
  "😖",
  "😘",
  "😚",
  "😜",
  "😝",
  "😞",
  "😠",
  "😢",
  "😤",
  "😥",
  "😨",
  "😩",
  "😪",
  "😫",
  "😭",
  "😰",
  "😱",
  "😲",
  "😳",
  "😵",
  "😷",
  "😸",
  "😹",
  "😺",
  "😻",
  "😼",
  "😽",
  "😾",
  "😿",
  "🙀",
  "🙅",
  "🙆",
  "🙇",
  "🙈",
  "🙉",
  "🙊",
  "🙋",
  "🙌",
  "🙍",
  "🙎",
  "🙏"
];

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] = initial && initial[field] ? initial[field] : "";
    errors[field] = [];
  }
  return { form, errors };
};

export const removeMask = maskedValue => {
  return maskedValue
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/\D/g, "")
    .trim();
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const MAP_MONTH_NAME = {
  1: { name: "Janeiro", short: "JAN" },
  2: { name: "Fevereiro", short: "FEV" },
  3: { name: "Março", short: "MAR" },
  4: { name: "Abril", short: "ABR" },
  5: { name: "Maio", short: "MAI" },
  6: { name: "Junho", short: "JUN" },
  7: { name: "Julho", short: "JUL" },
  8: { name: "Agosto", short: "AGO" },
  9: { name: "Setembro", short: "SET" },
  10: { name: "Outubro", short: "OUT" },
  11: { name: "Novembro", short: "NOV" },
  12: { name: "Dezembro", short: "DEZ" }
};

export const getArrayOfDaysInMonth = (year, month) => {
  var numDaysInMonth, daysInWeek, daysIndex, index, day, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  let yearPrepared = month === 1 ? year - 1 : year;

  index =
    daysIndex[new Date(yearPrepared, month - 1, 1).toString().split(" ")[0]];

  daysArray = [];

  for (day = 1; day <= numDaysInMonth[month - 1]; day++) {
    if (day === 1) {
      const end = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = end - 1; weekIndex >= 0; weekIndex--) {
        const beforeMonthDaysCount =
          month === 1 ? numDaysInMonth[11] : numDaysInMonth[month - 2];
        daysArray.push({
          day: beforeMonthDaysCount - weekIndex,
          isCurrentMonth: false
        });
      }

      daysArray.push({ day, isCurrentMonth: true });
    } else if (day === numDaysInMonth[month - 1]) {
      daysArray.push({ day, isCurrentMonth: true });

      const start = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = 1; weekIndex <= 7 - start; weekIndex++) {
        daysArray.push({ day: weekIndex, isCurrentMonth: false });
      }
    } else {
      daysArray.push({ day, isCurrentMonth: true });
    }

    index++;
    if (index == 7) index = 0;
  }

  return daysArray;
};

export const MaskToHour = dateStr => {
  if (dateStr.length > 2) {
    let v = dateStr.replace(/\D/g, "").slice(0, 5);
    return `${v.slice(0, 2)}:${v.slice(2)}`;
  }

  return date;
};
