<template>
  <div class="view-iqv-form bg-light py-5">
    <div class="container">
      <action-dispatcher
        action="lifeQuality/getForm"
        :parameters="$route.params.code"
        @error="error"
      >
        <div class="mb-4">
          <img src="../assets/logo.png" />
        </div>

        <h5 class="mb-5">Formulário IQV</h5>

        <step-0 v-if="step === 0" @next="step = 1" :form="form" />
        <div v-else v-for="(section, index) in form.sections" :key="index">
          <step-1
            v-show="step === index + 1"
            :step="index + 1"
            :form="section"
            :first="step === 1"
            :last="last"
            :loading="loading"
            :answers="answers"
            @back="back"
            @next="next"
            @save="save"
          />
        </div>
        <step-2 v-if="step === nSections + 1" />
      </action-dispatcher>
    </div>
  </div>
</template>

<script>
import Step0 from "../components/iqv/Step0";
import Step1 from "../components/iqv/Step1";
import Step2 from "../components/iqv/Step2";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: { Step0, Step1, Step2 },
  data() {
    return {
      step: 0,
      answers: [],
      loading: false
    };
  },
  computed: {
    ...mapState("lifeQuality", ["form"]),
    nSections() {
      return get(this.form, "sections.length", 0);
    },
    last() {
      return this.nSections === this.step;
    },
    QUESTIONS_MAP() {
      const map = {};
      if (this.nSections) {
        this.form.sections.forEach(section => {
          for (let id in section.questions) {
            map[id] = section.questions[id].title;
          }
        });
      }
      return map;
    }
  },
  methods: {
    error(error) {
      this.$message.error("Erro! " + get(error, "response.data.message", ""));
    },
    back() {
      this.step--;
      window.scrollTo(0, 0);
    },
    next(answers) {
      this.answers = [...this.answers, ...answers];
      this.step++;
      window.scrollTo(0, 0);
    },
    save(answers) {
      const newAnswers = [...this.answers];
      for (const i in answers) {
        if (
          newAnswers.map(a => a.question_id).indexOf(answers[i].question_id) ==
          -1
        ) {
          newAnswers.push(answers[i]);
        }
      }
      this.answers = newAnswers;

      this.loading = true;

      this.$store
        .dispatch("lifeQuality/answer", {
          code: this.$route.params.code,
          answers: this.answers.filter(a => a)
        })
        .then(() => {
          this.step++;
        })
        .catch(error => {
          this.$message.error("Erro! " + error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-iqv-form {
  min-height: 100vh;

  .container {
    max-width: 800px;
  }
}
</style>
