export default {
  setAnalyse(state, analyse) {
    state.analyse = analyse;
  },
  mergeAnalyse(state, analyse) {
    state.analyse = { ...state.analyse, ...analyse };
  },
  setAnalyses(state, analyses) {
    state.analyses = analyses;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  }
};
