<template>
  <div class="d-flex align-items-center">
    <b-checkbox v-model="selectedValue" />
    <button class="btn btn-sm d-flex" @click="openProfile">
      <div class="d-flex flex-column justify-content-start">
        <strong class="text-left">{{ item.name }}</strong>
        <small class="text-secondary text-left">{{ item.email }}</small>
      </div>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("analysts", ["selected"]),
    selectedValue: {
      get() {
        return !!this.selected.find(p => p.id === this.item.id);
      },
      set(val) {
        let selected;
        if (val) {
          selected = [...this.selected, this.item];
        } else {
          selected = [];
          selected = this.selected.filter(p => p.id !== this.item.id);
        }
        this.$store.commit("analysts/setSelected", selected);
      }
    }
  },
  methods: {
    openProfile() {
      this.$router.push({
        name: "admin-analysts-profile",
        params: { id: this.item.id }
      });
    }
  }
};
</script>
