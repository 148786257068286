<template>
  <router-link
    :to="{
      name: 'analyses-admin-details',
      params: { analyst_id: analyst.id, analyse_id: item.id }
    }"
    type="button"
    class="btn btn-sm text-success d-flex align-items-center"
  >
    <img class="img-fluid" src="@/assets/images/idea-green-icon.svg" />
    <span>Ver todos</span>
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("analysts", ["analyst"])
  }
};
</script>
