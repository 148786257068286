<template>
  <div class="analyses-table-cell-select-participant">
    <b-form-checkbox v-if="item.characters_map" v-model="selected" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("analyses", ["analyse"]),
    participants() {
      return get(this.analyse, "participants");
    },
    selected: {
      get() {
        return (
          !!this.participants &&
          !!this.participants.find(p => p.client_id === this.item.id)
        );
      },
      set(val) {
        if (!this.participants) return;

        let participants;
        if (val) {
          participants = [
            ...this.participants,
            {
              ...this.item,
              client_id: this.item.id,
              characters: this.item.characters_map
            }
          ];
        } else {
          participants = [];
          participants = this.participants.filter(
            p => p.client_id !== this.item.id
          );
        }
        this.$store.commit("analyses/setAnalyse", {
          ...this.analyse,
          participants
        });
      }
    }
  }
};
</script>
