import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import BootstrapVue from "bootstrap-vue";
import VueTheMask from "vue-the-mask";
import VueTelInput from "vue-tel-input";
import VCalendar from "v-calendar";
import VTooltip from "v-tooltip";

import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "./components";
import "./plugins";
import { init } from "./setup/api";

import Chart from "chart.js";
import "moment/locale/pt-br";

Chart.defaults.global.defaultFontFamily = "'Lato', sans-serif";
Chart.defaults.global.defaultFontStyle = "bold";

Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(VueTelInput);
Vue.use(VCalendar);
Vue.use(VTooltip);

init();

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
