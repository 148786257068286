var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"iqv-form-question card",attrs:{"id":_vm.id}},[_c('div',{staticClass:"card-body"},[_c('strong',{staticClass:"d-block mb-4"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.mandatory)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.type === 'short_answer')?_c('div',{staticClass:"short-answer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.other),expression:"other"}],staticClass:"form-control",domProps:{"value":(_vm.other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.other=$event.target.value}}})]):_vm._e(),(_vm.type === 'linear_scale')?_c('div',{staticClass:"linear-scale"},[_c('div',{staticClass:"d-flex mb-2 flex-wrap"},_vm._l((_vm.max),function(i){return _c('button',{key:i,staticClass:"btn",class:{
            'btn-outline-dark': !_vm.value || _vm.value.selected_scalar !== i,
            'btn-success': _vm.value && _vm.value.selected_scalar === i
          },on:{"click":function($event){return _vm.selectScalar(i)}}},[_vm._v(" "+_vm._s(i)+" ")])}),0),_c('div',{staticClass:"d-flex min-max-text"},[_c('small',[_vm._v(_vm._s(_vm.minText))]),_c('div',{staticClass:"flex-fill"}),_c('small',[_vm._v(_vm._s(_vm.maxText))])])]):_vm._e(),(_vm.type === 'single')?_c('div',{staticClass:"single"},[_vm._l((_vm.alternatives),function(alternative,index){return _c('div',{key:index,staticClass:"d-flex align-items-center py-2"},[_c('button',{staticClass:"btn",class:{
            'btn-outline-dark':
              !_vm.value || _vm.value.selected_alternative !== alternative.id,
            'btn-outline-success':
              _vm.value && _vm.value.selected_alternative === alternative.id
          },on:{"click":function($event){return _vm.clickSingle(alternative.id)}}},[(_vm.value && _vm.value.selected_alternative === alternative.id)?_c('span',{staticClass:"ball"}):_vm._e()]),_vm._v(" "+_vm._s(alternative.option)+" ")])}),(_vm.hasOther)?_c('div',{staticClass:"d-flex align-items-center py-2"},[_c('button',{staticClass:"btn",class:{
            'btn-outline-dark': !_vm.otherSelected,
            'btn-outline-success': _vm.otherSelected
          },on:{"click":_vm.clickOtherSingle}},[(_vm.otherSelected)?_c('span',{staticClass:"ball"}):_vm._e()]),_vm._v(" Outro: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.other),expression:"other"}],staticClass:"form-control",domProps:{"value":(_vm.other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.other=$event.target.value}}})]):_vm._e()],2):_vm._e(),(_vm.type === 'multiple')?_c('div',{staticClass:"multiple"},[_vm._l((_vm.alternatives),function(alternative,index){return _c('div',{key:index,staticClass:"d-flex align-items-center py-2"},[_c('button',{staticClass:"btn",class:{
            'btn-outline-dark':
              !_vm.value || !_vm.value.selected_alternatives.includes(alternative.id),
            'btn-success':
              _vm.value && _vm.value.selected_alternatives.includes(alternative.id)
          },on:{"click":function($event){return _vm.clickMultiple(alternative.id)}}},[(
              _vm.value && _vm.value.selected_alternatives.includes(alternative.id)
            )?_c('i',{staticClass:"icon-check"}):_vm._e()]),_vm._v(" "+_vm._s(alternative.option)+" ")])}),(_vm.hasOther)?_c('div',{staticClass:"d-flex align-items-center py-2"},[_c('button',{staticClass:"btn",class:{
            'btn-outline-dark': !_vm.otherSelected,
            'btn-success': _vm.otherSelected
          },on:{"click":_vm.clickOtherMultiple}},[(_vm.otherSelected)?_c('i',{staticClass:"icon-check"}):_vm._e()]),_vm._v(" Outro: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.other),expression:"other"}],staticClass:"form-control",domProps:{"value":(_vm.other)},on:{"input":function($event){if($event.target.composing){ return; }_vm.other=$event.target.value}}})]):_vm._e()],2):_vm._e(),(_vm.isShowingError)?_c('div',{staticClass:"d-flex pt-3"},[_vm._m(0)]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"error-message bg-danger text-light d-flex align-items-center"},[_c('i',{staticClass:"icon-alert-circle mr-2"}),_c('small',[_vm._v(" Esta pergunta é obrigatória ")])])}]

export { render, staticRenderFns }