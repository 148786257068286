<template>
  <button class="btn btn-sm d-flex">
    <div class="d-flex flex-column justify-content-start">
      <strong class="text-left">
        {{ percents }}
      </strong>
    </div>
  </button>
</template>

<script>
import charMapUtils from "@/functions/charactermap";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    percents() {
      if (!this.item.characters_map) return "Não realizado";
      const { sumTraitsByKey } = charMapUtils;
      const { characters_map } = this.item;
      const MAP_PERCENTS = {
        E: Math.round((sumTraitsByKey(characters_map, "E") * 100) / 60),
        M: Math.round((sumTraitsByKey(characters_map, "M") * 100) / 60),
        O: Math.round((sumTraitsByKey(characters_map, "O") * 100) / 60),
        P: Math.round((sumTraitsByKey(characters_map, "P") * 100) / 60),
        R: Math.round((sumTraitsByKey(characters_map, "R") * 100) / 60)
      };
      return `E ${MAP_PERCENTS["E"]} / O ${MAP_PERCENTS["O"]} / P ${MAP_PERCENTS["P"]} / M ${MAP_PERCENTS["M"]} / R ${MAP_PERCENTS["R"]}`;
    }
  }
};
</script>
