<template>
  <button class="btn btn-sm d-flex">
    <div class="d-flex flex-column justify-content-start">
      <span v-html="lastUpdate" class="text-left" />
      <span v-html="lastReportUpdate" class="text-left" />
    </div>
  </button>
</template>

<script>
import moment from "moment";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    lastUpdate() {
      const { last_map_update } = this.item;

      if (!last_map_update)
        return `<span class="text-muted">Não realizado</span>`;

      const info = moment(last_map_update).format(
        "[Última atualização em:] DD/MM/YYYY [ás] hh:mm"
      );
      return `<strong>${info}</strong>`;
    },
    lastReportUpdate() {
      const { last_character_map_report_send } = this.item;

      if (!last_character_map_report_send)
        return `<span class="text-muted">Relatório não enviado</span>`;

      const info = moment(last_character_map_report_send).format(
        "[Último relatório enviadoa em:] DD/MM/YYYY [ás] hh:mm"
      );
      return `<strong>${info}</strong>`;
    }
  }
};
</script>
