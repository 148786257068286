<template>
  <div class="home-search-filter d-flex align-items-center">
    <i class="icon-search mr-2"></i>
    <input v-model="text" :placeholder="placeholder" />
    <span>|</span>
    <b-dropdown
      text="Filtrar"
      ref="dropdown"
      variant="link"
      toggle-class="text-decoration-none"
    >
      <div v-if="loading" class="text-center p-4">
        <b-spinner variant="primary" />
      </div>

      <form v-else class="px-3 py-2" @submit.prevent="submit">
        <h6>Filtrar clientes</h6>

        <form-group
          v-model="form.country"
          id="country"
          label="PAÍS"
          placeholder="Selecione..."
          select
          :options="countries"
          nullable
        />
        <form-group
          v-model="form.uf"
          id="uf"
          label="UF"
          placeholder="Selecione..."
          select
          :options="states"
          nullable
        />
        <form-group
          v-model="form.city"
          id="city"
          label="CIDADE"
          placeholder="Selecione..."
          select
          :options="cities"
          nullable
        />
        <form-group
          v-model="form.company"
          id="company"
          label="EMPRESA"
          placeholder="Selecione..."
          select
          :options="companies"
          nullable
        />
        <form-group
          v-model="form.sector"
          id="sector"
          label="SETOR"
          placeholder="Selecione..."
          select
          :options="sectors"
          nullable
        />
        <!-- <form-group v-model="" label="EMPRESA" placeholder="Selecione..." select />
        <form-group v-model="" label="SETOR" placeholder="Selecione..." select /> -->

        <div class="text-center pt-3">
          <button class="btn btn-outline-success btn-block px-4">
            Aplicar filtros
          </button>
        </div>
      </form>
    </b-dropdown>
  </div>
</template>

<script>
import { mapState } from "vuex";

let timeout = null;

export default {
  props: {
    placeholder: {
      type: String,
      default: "Pesquisar por cliente"
    },
    searchValue: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      text: "",
      loading: true,
      form: {
        country: "",
        uf: "",
        city: "",
        company: "",
        sector: ""
      }
    };
  },
  computed: {
    ...mapState("customer", [
      "countries",
      "states",
      "cities",
      "companies",
      "sectors"
    ])
  },
  mounted() {
    Promise.all([
      this.$store.dispatch("customer/getCountries"),
      this.$store.dispatch("customer/getStates"),
      this.$store.dispatch("customer/getCities"),
      this.$store.dispatch("customer/getCompanies"),
      this.$store.dispatch("customer/getSectors")
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        this.text = "";
        this.form = {
          country: "",
          uf: "",
          city: "",
          company: "",
          sector: ""
        };
      }
    },
    text() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.search();
      }, 300);
    }
  },
  methods: {
    submit() {
      this.search();
      this.$refs.dropdown.hide(true);
    },
    search() {
      let searchTerm = this.text ? `name:${this.text}` : "";
      for (let key in this.form) {
        if (this.form[key]) {
          if (searchTerm) searchTerm += ";";
          searchTerm += `${key}:${this.form[key]}`;
        }
      }
      this.$emit("search", searchTerm);
    }
  }
};
</script>

<style lang="scss">
.home-search-filter {
  border: 1px solid #aeb6c4;
  border-radius: 4px;
  padding-left: 12px;
  max-height: 40px;
  min-height: 40px;
  width: 100%;

  i,
  span {
    color: #aeb6c4;
  }

  i {
    font-size: 20px;
  }

  span {
    font-size: 24px;
  }

  input {
    display: flex;
    flex: 1;
    border: none;
    width: 100%;
    background: transparent;
  }

  .dropdown-toggle {
    padding: 0 12px;
    max-height: 40px;
    min-height: 40px;
  }

  .form-control {
    border: 1px solid #e1e1e1;
  }

  select {
    width: 200px;
  }
}
</style>
