<template>
  <div class="trait-details card">
    <div class="card-body">
      <div class="row">
        <div class="col-5">
          <h4 class="text-center">Dor</h4>
          <div class="mb-2">
            <master-key-doughnut-chart
              v-if="!!painData.length"
              :traits="painData"
            />
          </div>
          <span
            v-for="(tt, index) in painData"
            :key="`pain-${index}`"
            class="d-flex align-items-center"
          >
            <span
              class="trait-square mr-2"
              :class="`trait-${tt.char.toLowerCase()}`"
            />
            {{ `${MAP_TO_NAME[tt.char]} ${tt.percent}%` }}
          </span>
        </div>
        <div class="col-1"><div class="vertical-divider" /></div>
        <div class="col-5">
          <h4 class="text-center">Recurso</h4>
          <div class="mb-2">
            <master-key-doughnut-chart
              v-if="!!resourceData.length"
              :traits="resourceData"
            />
          </div>
          <span
            v-for="(tt, index) in resourceData"
            :key="`pain-${index}`"
            class="d-flex align-items-center"
          >
            <span
              class="trait-square mr-2"
              :class="`trait-${tt.char.toLowerCase()}`"
            />
            {{ `${MAP_TO_NAME[tt.char]} (${tt.percent}%)` }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MasterKeyDoughnutChart from "@/components/charts/MasterKeyDoughnutChart";
import charMapUtils from "@/functions/charactermap";
import MasterKeyTraitsMixin from "@/mixins/MasterKeyTraits";
export default {
  mixins: [MasterKeyTraitsMixin],
  components: { MasterKeyDoughnutChart },
  data() {
    return {
      MAP_TO_NAME: charMapUtils.MAP_TRAIT_TO_NAME
    };
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables";

.trait-details {
  .trait-square {
    width: 12px;
    height: 12px;
    border-radius: 2px;
  }
  .trait-e {
    background-color: $trait-e;
  }
  .trait-o {
    background-color: $trait-o;
  }
  .trait-p {
    background-color: $trait-p;
  }
  .trait-m {
    background-color: $trait-m;
  }
  .trait-r {
    background-color: $trait-r;
  }

  .vertical-divider {
    height: 100%;
    width: 1px;
    background-color: #e9edf2;
  }
}
</style>
