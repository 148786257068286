<template>
  <action-dispatcher action="lifeQuality/getVersion" :parameters="item.id">
    <div class="iqv-view-version bg-light p-5">
      <h4 class="mb-4">{{ title }}</h4>
      <p class="mb-5">{{ description }}</p>

      <div v-for="(section, index) in version.sections" :key="index">
        <h6 class="mb-4">Etapa {{ index + 1 }}: {{ section.name }}</h6>

        <form-question
          v-for="(question, index) in section.questions"
          :key="index"
          :id="question.id"
          :title="question.title"
          :type="question.type"
          :min="question.min"
          :max="question.max"
          :min-text="question.min_text"
          :max-text="question.max_text"
          :alternatives="question.alternatives"
          :has-other="question.has_other"
          :mandatory="question.mandatory"
        />
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import FormQuestion from "./FormQuestion";
import get from "lodash/get";
import { mapState } from "vuex";

export default {
  components: { FormQuestion },
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState("lifeQuality", ["version"]),
    title() {
      return get(this.version, "begin_title");
    },
    description() {
      return get(this.version, "begin_description");
    }
  }
};
</script>
