export default {
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/clients", data)
        .then(resolve)
        .catch(reject);
    });
  },
  update(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${data.id}`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  get(context, params) {
    const queryString = new URLSearchParams(params).toString();

    return new Promise((resolve, reject) =>
      window.axios
        .get(`/clients?${queryString}`)
        .then(response => {
          context.commit("setCustomers", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  delete(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .delete(`/clients/${id}`)
        .then(resolve)
        .catch(reject)
    );
  },
  getDetails(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/clients/${id}`)
        .then(response => {
          context.commit("setCustomer", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },

  createCharacter(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${id}/characters`, data)
        .then(resolve)
        .catch(reject);
    });
  },
  updateCharacter(context, { id, data }) {
    return new Promise((resolve, reject) => {
      window.axios
        .put(`/clients/${id}/characters`, data)
        .then(resolve)
        .catch(reject);
    });
  },

  getCountries(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/clients/countries")
        .then(response => {
          context.commit("setCountries", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getStates(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/clients/states")
        .then(response => {
          context.commit("setStates", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCities(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/clients/cities")
        .then(response => {
          context.commit("setCities", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getCompanies(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/clients/companies")
        .then(response => {
          context.commit("setCompanies", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getSectors(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/clients/sectors")
        .then(response => {
          context.commit("setSectors", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  }
};
