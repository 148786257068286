import constants from "@/constants";
import get from "lodash/get";

export default {
  data() {
    return {
      form: {
        uf: "",
        country: ""
      },
      countryOptions: [],
      stateOptions: [],
      cityOptions: []
    };
  },
  watch: {
    "form.uf"(newVal, oldVal) {
      let ufAux = oldVal;
      if (newVal) ufAux = newVal;

      const selectedState = this.stateOptions.find(st => st.name === ufAux);

      this.cityOptions = get(selectedState, "cities", []).map(ct => ({
        name: ct,
        id: ct
      }));
    }
  },
  computed: {
    isBrasil() {
      return this.form.country === "Brasil";
    }
  },
  methods: {
    loadCountries() {
      this.countryOptions = constants.JSON.COUNTRIES.map(ctry => ({
        name: ctry.name,
        id: ctry.name
      }));
    },
    loadStatesAndCities(callback = null) {
      fetch(constants.JSON.BRAZILIAN_STATES)
        .then(response => response.json())
        .then(brazilianStates => {
          //map to { names, id, ... } is needed for form-group select pattern
          this.stateOptions = brazilianStates.states.map(st => ({
            ...st,
            name: st.uf,
            id: st.uf
          }));
          if (callback) {
            callback(brazilianStates);
          }
        });
    }
  }
};
