<template>
  <div class="char-map-points-selector card my-2" :class="{ selected }">
    <div class="card-body d-flex align-items-center p-2">
      <span class="letter">{{ letter }}</span>
      <div class="flex-fill"></div>
      <button
        class="btn btn-outline-primary"
        :class="{ disabled: value <= 0 }"
        @click="minus"
      >
        <i class="icon-minus"></i>
      </button>
      <the-mask mask="##" v-model="innerValue" />
      <button
        class="btn btn-primary"
        :class="{ disabled: points <= 0 }"
        @click="plus"
      >
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    letter: {
      type: String,
      default: "A"
    },
    selected: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    },
    points: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    value(val) {
      if (this.innerValue !== val) {
        this.innerValue = val;
      }
    },
    innerValue(newVal, oldVal) {
      if (newVal === oldVal) return;
      if (this.points - parseInt(newVal) + parseInt(oldVal) < 0) {
        this.$emit("input", parseInt(oldVal) + parseInt(this.points));
      } else {
        this.$emit("input", parseInt(newVal));
      }
    }
  },
  methods: {
    minus() {
      if (this.value > 0) {
        this.innerValue = this.value - 1;
      }
    },
    plus() {
      if (this.points > 0) {
        this.innerValue = this.value + 1;
      }
    }
  }
};
</script>

<style lang="scss">
.char-map-points-selector {
  &.selected {
    box-shadow: 0 0 4px rgb(100, 150, 250);
  }

  .letter {
    width: 56px;
    height: 56px;
    background: rgba(233, 237, 242, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  input {
    width: 60px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid #e9edf2;
    margin: 0 16px;
    text-align: center;
    background: white;
  }

  button {
    border-radius: 26px;
    min-width: 26px !important;
    max-width: 26px !important;
    min-height: 26px !important;
    max-height: 26px !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &.disabled {
      opacity: 0.3;
    }
  }
}
</style>
