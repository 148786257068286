<template>
  <div class="master-key-stage-details">
    <stage-details-item
      class="mb-3"
      v-for="(letter, index) in letters"
      :key="index"
      :letter="letter"
      :showComment="currentLetter === letter"
      :class="{ 'mb-3': index !== 4 }"
      @open="() => (currentLetter = letter)"
      @close="() => (currentLetter = '')"
    />
    <div class="row">
      <div class="col-6">
        <button
          v-if="currentLetter !== 'E' && currentLetter.length"
          class="btn btn-outline-secondary btn-block d-flex align-items-center justify-content-center"
          @click="previous"
        >
          <i class="icon-arrow-left mr-2" />
          Etapa anterior
        </button>
      </div>
      <div class="col-6">
        <button
          v-if="currentLetter !== 'R'"
          class="btn btn-outline-success btn-block d-flex align-items-center justify-content-center"
          @click="next"
        >
          Próxima etapa
          <i class="icon-arrow-right ml-2" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import StageDetailsItem from "./StageDetailsItem";

export default {
  components: { StageDetailsItem },
  data() {
    return {
      letters: ["E", "O", "P", "M", "R"],
      currentLetter: ""
    };
  },
  methods: {
    next() {
      if (!this.currentLetter.length) {
        this.currentLetter = "E";
        return;
      }

      const index = this.letters.findIndex(l => this.currentLetter === l);
      this.currentLetter = this.letters[index + 1];
    },
    previous() {
      if (this.currentLetter === "R") {
        this.currentLetter = "M";
        return;
      }

      const index = this.letters.findIndex(l => this.currentLetter === l);
      this.currentLetter = this.letters[index - 1];
    }
  }
};
</script>
