export default {
  data() {
    return {
      actions: [
        {
          icon: "icon-pencil",
          text: "Editar cadastro",
          class: "text-dark",
          confirmation: {
            enable: false,
            text: ""
          },
          event: "edit"
        },
        {
          icon: "icon-send-email",
          text: "Reenviar e-mail de acesso",
          class: "text-dark",
          confirmation: {
            enable: true,
            text:
              "A nova senha será enviada para o e-mail cadastrado no registro do analista, confirmar?",
            submitText: "Confirmar",
            submitButtonClass: "btn-success"
          },
          event: "send"
        },
        {
          icon: "icon-trash-3",
          text: "Excluir cadastro",
          confirmation: {
            enable: true,
            title: "Excluir cadastro de analista",
            text:
              "Ao excluir esse cadastro, o analista não terá mais acesso à plataforma. Você tem certeza que deseja excluir esse analista? ",
            submitText: "Confirmar",
            submitButtonClass: "btn-danger"
          },
          class: "text-danger",
          event: "remove"
        }
      ]
    };
  },
  methods: {
    edit(item) {
      this.$router.push({
        name: "admin-edit-analysts",
        params: { id: item.id }
      });
    },
    send(item) {
      this.loading = true;
      this.$store
        .dispatch("analysts/resendPassword", item.id)
        .then(() => {
          this.$message.success(
            "O e-mail de acesso foi reenviado para o e-mail cadastrado no analista"
          );
        })
        .catch(error => {
          this.$message.error("Erro ao reenviar e-mail de acesso ao analista");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove(item) {
      this.loading = true;
      this.$store
        .dispatch("analysts/delete", item.id)
        .then(() => {
          this.$message.success("O analista foi deletado");
          this.dispatch();
        })
        .catch(error => {
          this.$message.error("Erro ao deletar analista");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
