export default {
  create(context, data) {
    return new Promise((resolve, reject) => {
      window.axios
        .post("/analyses", data)
        .then(response => {
          context.commit("mergeAnalyse", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  get(context, data) {
    return new Promise((resolve, reject) =>
      window.axios
        .get("/analyses", { params: data })
        .then(response => {
          context.commit("setAnalyses", response.data.data);
          context.commit("setPagination", response.data.meta.pagination);
          resolve(response);
        })
        .catch(reject)
    );
  },
  getDetails(context, id) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analyses/${id}`)
        .then(response => {
          context.commit("setAnalyse", response.data.data);
          resolve(response);
        })
        .catch(reject)
    );
  },
  update(context, { analyse_id, data }) {
    return new Promise((resolve, reject) =>
      window.axios
        .post(`/analyses/${analyse_id}`, data)
        .then(resolve)
        .catch(reject)
    );
  },
  delete(context, { analyse_id }) {
    return new Promise((resolve, reject) =>
      window.axios
        .delete(`/analyses/${analyse_id}`)
        .then(resolve)
        .catch(reject)
    );
  },
  download(context, { analyse_id }) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analyses/${analyse_id}/report`, { responseType: "blob" })
        .then(resolve)
        .catch(reject)
    );
  },
  sendReportToAnalyst(context, { analyse_id }) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analyses/${analyse_id}/report/analyst`)
        .then(resolve)
        .catch(reject)
    );
  },
  sendReportToReceivers(context, { analyse_id }) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analyses/${analyse_id}/report/client`)
        .then(resolve)
        .catch(reject)
    );
  },
  adminDownload(context, { analyse_id, analyst_id }) {
    return new Promise((resolve, reject) =>
      window.axios
        .get(`/analysts/${analyst_id}/analyses/${analyse_id}/report`, {
          responseType: "blob"
        })
        .then(resolve)
        .catch(reject)
    );
  }
};
