export default {
  pagination: {},
  ranking: [],
  rankingCustomers: [],
  countries: [],
  states: [],
  cities: [],
  companies: [],
  sectors: [],
  selected: []
};
