import charMapUtils from "@/functions/charactermap";
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    customers: {
      type: Array,
      default: []
    },
    averageLineVisible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    const colors = ["#0099FF", "#FFCC00", "#FF8A00", "#00FF00", "#FF00FF"];
    return {
      barDataSets: [],
      lineDataset: {},
      TRAITS: ["E", "O", "P", "M", "R"],
      BAR_COLORS: {
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
        barPercentage: 0.5
      },
      options: {
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }

              label += Math.round((tooltipItem.yLabel / 60) * 100);
              return `${label}%`;
            }
          }
        },
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 60,
                beginAtZero: true,
                fontColor: "#060620",
                fontSize: 16,
                padding: 10
              },
              gridLines: {
                display: false,
                color: "#99999955",
                drawBorder: false,
                drawTicks: false,
                offsetGridLines: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                maxTicksLimit: 60,
                beginAtZero: true,
                callback: function(value) {
                  if (value % 3 === 0) {
                    return `${Math.round((value / 60) * 100)}%`;
                  }

                  return "";
                },
                fontColor: "#AEB6C4",
                drawOnChartArea: true
              },
              gridLines: {
                display: false,
                zeroLineColor: "transparent"
              }
            }
          ]
        }
      }
    };
  },
  mounted() {
    this.render();
  },
  watch: {
    averageLineVisible(val) {
      this.render();
    },
    customers(val) {
      this.render();
    }
  },
  methods: {
    populateDatasets() {
      if (!this.customers || !this.customers.length) return;
      this.barDataSets = this.customers.map(customer => {
        return {
          label: customer.name,
          legend: false,
          ...this.BAR_COLORS,
          lineTension: 0,
          data: [
            ...this.TRAITS.map((key, index) => {
              const sum = !!customer.characters
                ? charMapUtils.sumTraitsByKey(customer.characters, key)
                : [];
              return {
                x: index,
                y: sum
              };
            })
          ]
        };
      });

      this.lineDataset = {
        label: "Line Dataset",
        borderColor: "#8BA8BC",
        backgroundColor: "transparent",
        pointBorderColor: "#01416c",
        pointBackgroundColor: "#01416c",
        lineTension: 0,
        data: [
          ...this.TRAITS.map(t =>
            charMapUtils.averageByTrait(t, this.customers)
          )
        ],
        type: "line"
      };
    },
    render() {
      this.populateDatasets();
      if (this.averageLineVisible) {
        this.renderChart(
          {
            labels: this.TRAITS,
            datasets: [this.lineDataset, ...this.barDataSets]
          },
          this.options
        );
      } else {
        this.renderChart(
          { labels: this.TRAITS, datasets: this.barDataSets },
          this.options
        );
      }
    }
  }
};
