<template>
  <b-modal
    @change="change"
    v-model="visible"
    :visible="visible"
    hide-footer
    hide-header
    centered
    :no-close-on-backdrop="noCloseOnBackdrop"
  >
    <div class="confirmation p-2">
      <div class="d-flex justify-content-center mb-4">
        <img :src="iconMap[iconType] || iconMap.success" />
      </div>

      <h3 class="text-center px-4 pt-2 mb-3" v-if="title.length">
        {{ title }}
      </h3>

      <div class="text-center px-4 pt-2 pb-3 mb-4 text-secondary text">
        {{ text }}
      </div>

      <div class="row">
        <div v-if="cancelButtonText" class="col pr-4">
          <button
            class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </button>
        </div>
        <div class="col pl-4">
          <button
            class="btn btn-block btn-lg font-weight-bold"
            :class="confirmButtonClass"
            @click="confirm"
          >
            <b-spinner v-if="loading" variant="light" />
            <span v-if="!loading">{{ confirmButtonText }}</span>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String, //"success" | "warning" | "mail"
      default: "success"
    },
    visible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Tem certeza?"
    },
    title: {
      type: String,
      default: ""
    },
    cancelButtonText: {
      type: String,
      default: "Cancelar"
    },
    confirmButtonText: {
      type: String,
      default: "Confirmar"
    },
    confirmButtonClass: {
      type: String,
      default: "btn-danger"
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: "visible",
    event: "change"
  },
  data() {
    return {
      iconMap: {
        warning: require("../assets/images/warning-icon.svg"),
        success: require("../assets/images/success-icon.svg"),
        mail: require("../assets/images/mail-icon.svg")
      }
    };
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    },
    cancel() {
      this.$emit("cancel");
      this.change(false);
    },
    confirm() {
      this.$emit("confirm");
      this.change(false);
    }
  }
};
</script>

<style lang="scss">
.confirmation {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
  }
}
</style>
