<template>
  <div
    class="form-group mb-0 py-2"
    :class="{ 'has-icon': !!icon, 'has-value': !!value, 'is-date': isDate }"
  >
    <label
      :for="id"
      v-if="label && type !== 'file'"
      :class="{ 'text-danger': showError }"
      >{{ label }}</label
    >
    <i v-if="icon" :class="'icon ' + icon"></i>

    <div v-if="isDate" class="date-picker-wrapper d-flex">
      <v-date-picker
        :first-day-of-week="1"
        v-model="date"
        :popover="{ placement: 'bottom', visibility: 'click' }"
      >
        <template v-slot="{ togglePopover }">
          <button
            type="button"
            class="btn text-secondary"
            @click="togglePopover({ placement: 'auto-start' })"
          >
            <i class="icon-calendar"></i>
          </button>
        </template>
      </v-date-picker>
    </div>

    <select
      v-if="select"
      class="form-control"
      :class="classObject"
      :id="id"
      v-model="innerValue"
    >
      <option value="" :disabled="!nullable">{{ placeholder }}</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.id"
      >
        {{ optionLabelFunction(option) }}
      </option>
    </select>
    <the-mask
      v-else-if="mask.length"
      :mask="mask"
      :masked="masked"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <vue-tel-input
      v-else-if="isPhone"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      type="tel"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      @country-changed="countryChanged"
      @validate="obj => $emit('validate', obj)"
    />
    <textarea
      v-else-if="textarea"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div class="form-password-wrapper" v-else-if="type === 'password'">
      <input
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="!showPassword && type"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
      />
      <div v-if="showError && showErrorMessage" class="invalid-feedback">
        {{ errors[0] }}
      </div>
      <button
        type="button"
        class="show-password-icon clear-button"
        :class="{
          'password-icon-position-y-40': errors && errors.length && !label,
          'password-icon-position-y-50': label && (!errors || !errors.length)
        }"
        @click="showPassword = !showPassword"
      >
        <i :class="!showPassword ? 'icon-eye' : 'icon-eye-off'" />
      </button>
    </div>

    <div class="radio-group text-dark row pt-2" v-else-if="type === 'radio'">
      <div v-for="option in options" :key="option.name" class="col-6">
        <input
          :id="option.name"
          type="radio"
          :value="option.name"
          v-model="innerValue"
        />
        <span :for="option.name">{{ option.name }}</span>
      </div>
    </div>

    <input
      v-else
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div v-if="showError && showErrorMessage" class="invalid-feedback">
      {{ errors[0] }}
    </div>

    <button v-if="withClearButton && value.length" class="clear" @click="clear">
      <i class="icon-x"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    nullable: {
      type: Boolean,
      default: false
    },
    mask: {
      type: [String, Array],
      default: ""
    },
    masked: {
      type: Boolean,
      default: false
    },
    isDate: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    },
    select: {
      type: Boolean,
      default: false
    },
    textarea: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabelFunction: {
      type: Function,
      default: item => item.name
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    },
    showErrorMessage: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withClearButton: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPassword: false,
      showError: this.errors && this.errors.length,
      innerValue: this.value,
      date: null
    };
  },
  computed: {
    classObject() {
      const result = { "is-invalid": this.showError };
      result[this.customClass] = true;
      return result;
    }
  },
  watch: {
    date(newVal) {
      this.innerValue = newVal
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    },
    errors(newVal) {
      if (newVal && !!newVal[0]) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    innerValue(newVal) {
      if (newVal != this.value) {
        this.showError = false;
        this.$emit("input", newVal);

        if (this.isDate && newVal.length === 10) {
          this.date = new Date(
            newVal
              .split("/")
              .reverse()
              .join("-") + "T12:00:00"
          );
        }
      }
      if (this.isPhone && newVal.match(/[a-zA-Z]/)) {
        setTimeout(() => {
          this.innerValue = newVal.replace(/[a-zA-Z]/, "");
        }, 2);
      }
    },
    value(newVal) {
      this.$emit("change");
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
        this.showError = false;
      }
    }
  },
  methods: {
    countryChanged(obj) {
      this.$emit("country-changed", obj);
      setTimeout(() => {
        this.innerValue = document.querySelector(`#${this.id} input`).value;
      }, 1);
    },
    clear() {
      this.$emit("input", "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.form-group {
  position: relative;

  .form-control.is-invalid {
    background-image: none;
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 54px;
      left: 4px;
    }

    .form-control {
      padding-right: 32px;
    }
  }

  .clear {
    position: absolute;
    height: 64px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }

  &:not(.has-value) {
    select {
      color: #aeb6c4 !important;
    }
  }

  /deep/ .vti__dropdown-list {
    z-index: 3;
  }

  .date-picker-wrapper {
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 1;

    button {
      height: 54px;
    }
  }

  &.is-date {
    .form-control {
      padding-left: 40px;
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background: white;
  }

  .form-password-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .form-control {
      padding-right: 7px;
    }
    .show-password-icon {
      position: absolute;
      right: 25px;
      top: 50%;
      font-size: 17px;
      cursor: pointer;
      user-select: none;
    }
    .password-icon-position-y-40 {
      top: 40%;
    }
    .password-icon-position-y-50 {
      top: 50%;
    }
    .clear-button {
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      border: 0px;
    }
  }

  .radio-group {
    font-size: 16px;

    input[type="radio"] {
      margin-right: 5px;
    }
  }
}
</style>
