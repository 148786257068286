<template>
  <div class="master-key-fact-selector">
    <b-spinner v-if="loadingComponent" variant="primary"></b-spinner>

    <button
      v-else-if="facts.length === 0"
      class="btn btn-outline-success px-5"
      @click="dialog = true"
    >
      Adicionar um fato
    </button>

    <div v-else class="card" :class="{ empty: !currentFact }">
      <div class="card-body d-flex">
        <b-dropdown
          :text="dropDownText"
          variant="link"
          class="dropdown dropdown-toggle"
          toggle-class="text-decoration-none"
        >
          <b-dropdown-item
            v-for="(factItem, index) in facts"
            :key="`fact-${index}`"
            @click="onSelect(factItem)"
          >
            {{ factItem.name }}
          </b-dropdown-item>
        </b-dropdown>

        <!-- <select
          v-model="selectedFact"
          class="form-control flex-fill"
          @change="onSelect"
        >
          <option v-for="(factItem, index) in facts" :key="index">
            {{ factItem.name }}
          </option>
        </select> -->

        <button class="btn btn-outline-primary mx-3" @click="onEdit">
          Editar este fato
        </button>
        <button class="btn btn-outline-success" @click="openNew">
          Novo fato
        </button>
      </div>
    </div>

    <b-modal v-model="dialog" hide-header hide-footer centered>
      <div class="p-3">
        <h5 class="text-center mb-4">O fato em questão</h5>
        <textarea
          v-model="text"
          class="form-control"
          placeholder="Descreva aqui um fato específico que está gerando desconforto, sofrimento e/ou dor para o cliente"
          rows="5"
        ></textarea>
        <div class="row pt-4">
          <div class="col-6">
            <button
              class="btn btn-block btn-outline-secondary"
              @click="dialog = false"
            >
              Cancelar
            </button>
          </div>
          <div class="col-6">
            <button class="btn btn-block btn-success" @click="save">
              <b-spinner v-if="loading" variant="light" />
              <span v-if="!loading">Salvar</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      text: "",
      errors: null,
      dialog: false,
      editedIndex: null,
      loading: false,
      loadingComponent: false
    };
  },
  computed: {
    ...mapState("masterKey", ["facts", "currentFact"]),
    ...mapState("customer", ["customer"]),
    dropDownText() {
      if (!this.currentFact) return "Selecione um fato";
      return this.currentFact.name;
    }
  },
  mounted() {
    this.$store.commit("masterKey/setCurrentFact", null);
    this.dispatch();
  },
  methods: {
    openNew() {
      this.text = "";
      this.dialog = true;
    },
    dispatch() {
      this.loadingComponent = true;
      this.$store
        .dispatch("masterKey/getFacts", {
          customer_id: this.$route.params.id
        })
        .catch(error => {
          this.errors = error.data.errors;
        })
        .finally(() => {
          this.loadingComponent = false;
        });
    },
    save() {
      const name = this.text;
      if (this.editedIndex) {
        this.loading = true;
        this.$store
          .dispatch("masterKey/updateFactName", {
            customer_id: this.$route.params.id,
            fact_id: this.currentFact.id,
            name
          })
          .then(response => {
            this.onSelect(response.data.data);
            this.text = "";
            this.editedIndex = null;
            this.dialog = false;
          })
          .catch(error => {
            this.errors = error.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
        return;
      }

      this.$store
        .dispatch("masterKey/createFact", {
          customer_id: this.$route.params.id,
          name
        })
        .then(response => {
          this.facts.push(response.data.data);
          this.dialog = false;
          this.text = "";
          this.onSelect(response.data.data);
        })
        .catch(error => {
          this.errors = error.data.errors;
        });
    },
    onSelect(fact) {
      this.$store.commit("masterKey/setCurrentFact", fact);
    },
    onEdit() {
      if (!this.currentFact) {
        this.$message.error("Primeiro, selecione um fato");
        return;
      }
      this.text = this.currentFact.name;
      this.editedIndex = this.facts.findIndex(f => f.name === this.fact);
      this.dialog = true;
    }
  }
};
</script>

<style lang="scss">
.master-key-fact-selector {
  .form-control {
    width: auto;
  }

  button {
    white-space: nowrap;
  }

  .dropdown {
    flex: 1 1 auto;
    min-width: 0;
  }

  .dropdown-item {
    white-space: normal;
  }

  .dropdown-toggle {
    background: #ffffff;
    color: #212631;
    border: 1px solid #e9edf2;
    border-radius: 4px;
    text-align: left;
    position: relative;

    &::after {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }

    button {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 24px;
    }
  }

  .empty {
    .dropdown-toggle {
      color: #aeb6c4;
    }
  }
}
</style>
