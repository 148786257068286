<template>
  <form class="customer-step-1" @submit.prevent="submit">
    <image-input
      id="image"
      label="FOTO DE PERFIL"
      type="file"
      v-model="form.image"
      :errors="theErrors.image"
    />
    <search-select
      v-if="$user.user.user_type === 'admin'"
      id="analyst"
      label="ANALISTA*"
      placeholder="Escolha o analista"
      v-model="form.analyst"
      :errors="theErrors.analyst_id"
    />
    <form-group
      id="name"
      label="NOME COMPLETO*"
      placeholder="Digite o nome do cliente. Ex: João Silva"
      v-model="form.name"
      :errors="theErrors.name"
    />
    <form-group
      id="email"
      type="email"
      label="E-MAIL*"
      placeholder="Digite o e-mail do cliente. Ex: joaosilva@gmail.com"
      v-model="form.email"
      :errors="theErrors.email"
    />
    <div class="row justify-content-between">
      <div class="col-lg-6">
        <form-group
          id="bornDate"
          label="DATA DE NASCIMENTO*"
          placeholder="DD/MM/AAAA"
          v-model="form.born_date"
          :isDate="true"
          :mask="'##/##/####'"
          masked
          :errors="theErrors.born_date"
        />
      </div>
      <div v-if="showShirt" class="col-lg-6">
        <form-group
          id="shirt_size"
          type="text"
          placeholder="Ex: M"
          label="TAMANHO DA CAMISETA"
          v-model="form.shirt_size"
          :select="true"
          :options="shirtOptions"
          :errors="theErrors.shirt_size"
        />
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-lg-6">
        <form-group
          id="nacionalityDdi"
          label="DDI DO TELEFONE"
          type="radio"
          v-model="form.nacionalityDdi"
          :isRadioGroup="true"
          :options="[{ name: 'Brasileiro' }, { name: 'Telefone Estrangeiro' }]"
          :errors="theErrors.nacionalityDdi"
        />
      </div>
      <div class="col-lg-6 row pl-lg-0 pr-0 pr-sm-0 pr-lg-3">
        <div v-if="form.nacionalityDdi !== 'Brasileiro'" class="col-3 pl-lg-0">
          <form-group
            id="ddi"
            type="ddi"
            label="DDI*"
            placeholder="Ex: 55"
            v-model="form.ddi"
            :mask="`###`"
            :errors="theErrors.ddi"
          />
        </div>
        <div class="col pl-lg-0 pr-lg-3 pr-0 pr-sm-0">
          <form-group
            id="phone"
            type="phone"
            label="TELEFONE*"
            placeholder="Ex: (11) 9 9999-9999"
            v-model="form.phone"
            :mask="
              form.nacionalityDdi === 'Brasileiro'
                ? '(##) # ####-####'
                : '################'
            "
            :errors="theErrors.phone"
          />
        </div>
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-lg-6">
        <form-group
          id="nacionalityDocument"
          label="TIPO DE DOCUMENTO"
          type="radio"
          v-model="form.nacionalityDocument"
          :isRadioGroup="true"
          :options="[{ name: 'CPF' }, { name: 'Documento Estrangeiro' }]"
          :errors="theErrors.nacionalityDocument"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="cpf"
          type="cpf"
          label="NÚMERO DO DOCUMENTO"
          placeholder="Ex: 123.456.789-01"
          mask="###.###.###-##"
          v-model="form.cpf"
          v-if="form.nacionalityDocument === 'CPF'"
          :errors="theErrors.cpf"
        />
        <form-group
          v-if="form.nacionalityDocument === 'Documento Estrangeiro'"
          id="foreign_document"
          type="foreign_document"
          label="NÚMERO DO DOCUMENTO"
          placeholder="Ex: 123.456.789-01"
          v-model="form.foreign_document"
          :errors="theErrors.foreign_document"
        />
      </div>
    </div>

    <form-group
      id="company"
      type="company"
      label="EMPRESA"
      placeholder="Digite o nome da empresa. Ex: Concerg"
      v-model="form.company"
      :errors="theErrors.company"
    />
    <form-group
      id="sector"
      type="sector"
      label="SETOR"
      placeholder="Digite o setor. Ex: Analista"
      v-model="form.sector"
      :errors="theErrors.sector"
    />

    <div class="row justify-content-between pt-5">
      <div class="col-5 col-lg-3">
        <hollow-button
          styleType="cancel"
          title="Cancelar"
          type="button"
          @click="$router.go(-1)"
        />
      </div>
      <div class="col-5 col-lg-3">
        <hollow-button class="" title="Continuar" type="submit" />
      </div>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions/helpers";

export default {
  props: {
    formData: {
      type: Object,
      default: null
    },
    formErrors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      shirtOptions: [
        { name: "PP", id: "PP" },
        { name: "P", id: "P" },
        { name: "M", id: "M" },
        { name: "G", id: "G" },
        { name: "GG", id: "GG" }
      ],
      ...formFields(
        [
          "image",
          "name",
          "email",
          "born_date",
          "shirt_size",
          "nacionalityDdi",
          "ddi",
          "phone",
          "nacionalityDocument",
          "cpf",
          "foreign_document",
          "company",
          "sector",
          "analyst",
          "analyst_id"
        ],
        {
          nacionalityDdi: "Brasileiro",
          nacionalityDocument: "CPF",
          ddi: "55",
          analyst: null,
          ...this.formData
        }
      )
    };
  },
  computed: {
    showShirt() {
      return (
        this.$user.user.internal_analyst ||
        this.$user.user.user_type === "admin"
      );
    },
    theErrors() {
      const result = { ...this.errors };
      const formErrors = this.formErrors ? this.formErrors : {};
      for (let i in formErrors) {
        if (result[i]) {
          result[i] = [...result[i], ...formErrors[i]];
        }
      }
      return result;
    }
  },
  watch: {
    "form.nacionalityDdi"(newVal) {
      if (newVal === "Brasileiro") {
        this.form.ddi = "55";
      } else {
        this.form.ddi = "";
      }
    }
  },
  methods: {
    reset() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.nacionalityDdi = "Brasileiro";
      this.nacionalityDocument = "CPF";
      this.ddi = "55";
    },
    submit() {
      if (this.validateFields()) {
        this.$emit("next", this.form);
      }
    },
    validateFields() {
      const required = ["name", "email", "phone"];

      let valid = true;

      for (let i in required) {
        let key = required[i];
        if (!this.form[key]) {
          this.errors[key] = ["Este campo não pode ficar em branco"];
          valid = false;
        }
      }

      if (
        this.form.nacionalityDdi === "Telefone Estrangeiro" &&
        !this.form.ddi
      ) {
        this.errors.ddi = ["Este campo não pode ficar em branco"];
        valid = false;
      }

      if (this.$user.user.user_type === "admin" && !this.form.analyst) {
        this.errors.analyst_id = ["Escolha um analista"];
        valid = false;
      }

      // if (
      //   this.form.nacionalityDocument === "CPF" &&
      //   this.form.cpf.length !== 11
      // ) {
      //   this.errors.cpf = ["Digite um CPF válido"];
      //   valid = false;
      // }

      // if (
      //   this.form.nacionalityDocument === "Documento Estrangeiro" &&
      //   !this.form.foreign_document
      // ) {
      //   this.errors.foreign_document = ["Digite um documento estrangeiro"];
      //   valid = false;
      // }

      if (!valid) {
        this.$emit("show-warning");
      }

      return valid;
    }
  }
};
</script>
