import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

import DefaultTemplate from "./templates/Default.vue";
import BlankTemplate from "./templates/Blank.vue";

import Home from "./views/Home.vue";
import EditProfile from "./views/EditProfile.vue";
import ChangePassword from "./views/ChangePassword.vue";
import Customer from "./views/Customer.vue";
import CustomerNew from "./views/CustomerNew.vue";
import CustomerProfile from "./views/CustomerProfile.vue";
import IQVForm from "./views/IQVForm.vue";
import MasterKey from "./views/MasterKey.vue";
import CharMapForm from "./views/CharMapForm.vue";
import Analyses from "./views/Analyses.vue";
import AnalysesNew from "./views/AnalysesNew.vue";
import AnalysesEdit from "./views/AnalysesEdit.vue";
import AdminAnalysts from "./views/admin/Analysts.vue";
import AdminAnalystsNew from "./views/admin/AnalystsNew.vue";
import AdminAnalystsProfile from "./views/admin/AnalystsProfile.vue";
import AnalysesAnalystDetails from "./views/AnalysesAnalystDetails.vue";
import AnalysesAdminDetails from "./views/admin/AnalysesDetails.vue";
import AdminRanking from "./views/admin/Ranking.vue";
import AdminRankingCustomers from "./views/admin/RankingCustomers.vue";
import AdminAdmins from "./views/admin/Admins.vue";
import AdminLQVersions from "./views/admin/LQVersions.vue";
import AdminLQVersionsNew from "./views/admin/LQVersionsNew.vue";
import AdminAnalysesAnalyst from "./views/admin/AnalysesAnalyst.vue";
import Schedules from "./views/Schedules.vue";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/ranking",
      name: "ranking",
      component: AdminRanking,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/agenda",
      name: "schedules",
      component: Schedules,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/customers/ranking/",
      name: "ranking-customers",
      component: AdminRankingCustomers,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/administradores",
      name: "admins",
      component: AdminAdmins,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/iqv",
      name: "lq-versions",
      component: AdminLQVersions,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/iqv/novo",
      name: "lq-versions-new",
      component: AdminLQVersionsNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/analistas",
      name: "admin-analysts",
      component: AdminAnalysts,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analistas/cadastrar",
      name: "admin-register-analysts",
      component: AdminAnalystsNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analistas/:id",
      name: "admin-analysts-profile",
      component: AdminAnalystsProfile,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analistas/:id/editar",
      name: "admin-edit-analysts",
      component: AdminAnalystsNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/editar-perfil",
      name: "edit-profile",
      component: EditProfile,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/clientes",
      name: "customer",
      component: Customer,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/clientes/cadastrar",
      name: "register-customer",
      component: CustomerNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/clientes/:id/editar",
      name: "edit-customer",
      component: CustomerNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/clientes/:id",
      name: "customer-profile",
      component: CustomerProfile,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/nova-analise",
      name: "analyses-new",
      component: AnalysesNew,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/editar-analise/:id",
      name: "analyses-edit",
      component: AnalysesEdit,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analise-combinada",
      name: "analyses",
      component: Analyses,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analises/analista/:id",
      name: "admin-analyses-analyst-list",
      component: AdminAnalysesAnalyst,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/analista/analise/:id/detalhes",
      name: "analyses-analyst-details",
      component: AnalysesAnalystDetails,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/admin/analista/:analyst_id/analise/:analyse_id/detalhes",
      name: "analyses-admin-details",
      component: AnalysesAdminDetails,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/life-quality/:code",
      name: "iqv",
      component: IQVForm,
      meta: {
        template: BlankTemplate,
        protected: false
      }
    },

    {
      path: "/clientes/:id/chave-mestra",
      name: "master-key",
      component: MasterKey,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/clientes/:id/mapa-de-caracteres",
      name: "char-map-form",
      component: CharMapForm,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },

    {
      path: "/alterar-senha",
      name: "change-password",
      component: ChangePassword,
      meta: {
        template: DefaultTemplate,
        protected: true
      }
    },
    {
      path: "/cadastro",
      name: "register",
      component: () => import("./views/Register.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/password-recovery",
      name: "password-recovery",
      component: () => import("./views/PasswordRecovery.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/password/reset",
      name: "reset-password",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/termos-de-uso",
      name: "terms",
      component: () => import("./views/Terms.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    }
  ]
});

// Vue.use(VueGtm, {
//   id: "GTM-TB4VDDF",
//   vueRouter
// });

export default vueRouter;
