<template>
  <div class="view-customer">
    <h5 class="mb-4">Ranking de Analistas</h5>

    <div class="d-flex">
      <search-filter placeholder="Pesquisar por analista" @search="search" />
    </div>

    <div
      v-if="!loading && !ranking.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há analistas cadastrados</strong>
      <span class="text-secondary">Cadastre um analista para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :loading="loading"
      :columns="columns"
      :data="ranking"
      :is-selectable="false"
    />

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter";
import TableCellName from "@/components/adminHome/TableCellName";
import { mapState } from "vuex";

export default {
  components: { SearchFilter },
  data() {
    return {
      loading: false,
      error: false,
      params: {
        page: 1,
        search: "",
        // letter: undefined,
        sortedBy: "form_last_answers_avg"
      },
      columns: [
        {
          label: "Nome do analista",
          value: "name",
          component: TableCellName
        },
        {
          label: "Clientes cadastrados",
          formatter: item => `<div class="d-flex flex-column">
            <strong>${item.client_count}</strong>
            <small class="text-secondary">clientes</small>
          </div>`
        },
        {
          label: "Mapa dos caracteres",
          formatter: item => `<div class="d-flex flex-column">
            <strong>${item.character_map_count}</strong>
            <small class="text-secondary">realizados</small>
          </div>`
        },
        {
          label: "Formulário de acompanhamento",
          formatter: item => `<div class="d-flex flex-column">
            <strong>IQV Médio: ${Math.round(item.form_last_answers_avg) ||
              "-"}</strong>
            <small class="text-secondary">${
              item.form_answer_count
            } formulários respondidos</small>
          </div>`
        },
        {
          label: "Chave mestra",
          formatter: item => `<div class="d-flex flex-column">
            <strong>${item.master_key_completed_count} concluídas</strong>
            <small class="text-secondary">${item.master_key_started_count} conduzidas</small>
          </div>`
        }
      ]
    };
  },
  computed: {
    ...mapState("ranking", ["ranking", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    dispatch() {
      this.loading = true;
      this.$store
        .dispatch("ranking/get", this.params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    }
  }
};
</script>

<style lang="scss" scoped>
.view-customer {
  .empty {
    font-size: 14px;
    text-align: center;
  }
}
</style>
