<template>
  <action-dispatcher
    action="customer/getDetails"
    :parameters="$route.params.id"
  >
    <div v-if="customer.id" class="view-customer-profile row">
      <div class="col-lg-12">
        <h5 class="mb-4">
          <router-link class="back" :to="{ name: 'customer' }">
            <i class="icon-chevron-left"></i>
          </router-link>
          Perfil do cliente
        </h5>

        <profile-personal-data :customer="customer" />

        <div class="row mb-4">
          <div class="col-lg-6 text-left">
            <span class="font-weight-bold text-dark">
              Formulários de acompanhamento individual
            </span>
            <profile-iqv-form-data class="mt-3" :customer="customer" />
          </div>
          <div class="col-lg-6 text-left">
            <div v-if="$user.user.user_type === 'admin'">
              <span class="font-weight-bold text-dark">
                Detalhes dos formulários
              </span>
              <profile-iqv-forms-answers class="mt-3" :customer="customer" />
            </div>
          </div>
        </div>

        <span class="font-weight-bold text-dark">
          Chave mestra
        </span>
        <profile-master-key-data class="mt-3" :customer="customer" />

        <div v-if="!!customer.characters_map" class="row align-items-center">
          <div class="col-lg-3">
            <span class="font-weight-bold text-dark">
              Mapa dos caracteres
            </span>
          </div>
          <div
            class="col-lg d-flex align-items-center justify-content-end flex-wrap flex-lg-nowrap"
          >
            <router-link
              class="btn btn-outline-success my-3 my-lg-0"
              :to="{
                name: 'char-map-form',
                params: { id: $route.params.id }
              }"
            >
              Atualizar mapa dos caracteres
            </router-link>
            <button-char-map-send-report class="ml-3" :customer="customer" />
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold text-dark">
            Mapa dos caracteres
          </span>
          <div class="col">
            <div class="row mt-4 empty-characters-map align-items-center">
              <div class="col-8">
                Mapa dos caracteres ainda não criado
              </div>
              <div class="col-4 d-flex justify-content-end">
                <router-link
                  class="btn btn-outline-success my-3 my-lg-0"
                  :to="{
                    name: 'char-map-form',
                    params: { id: $route.params.id }
                  }"
                >
                  Criar mapa dos caracteres
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <profile-chart-char-map-data
          v-if="!!customer.characters_map"
          class="mt-3"
          :customer="customer"
        />

        <div v-if="!!customer.characters_map" class="row pt-4">
          <div class="col-lg-6 pb-3 pb-lg-0">
            <char-map-traits-table :customer="customer" />
          </div>
          <div class="col-lg-6 d-flex">
            <profile-comments-and-laterality-data
              class="flex-fill"
              :customer="customer"
            />
          </div>
        </div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import ProfilePersonalData from "@/components/customer/ProfilePersonalData";
import ProfileIqvFormData from "@/components/customer/ProfileIqvFormData";
import ProfileIqvFormsAnswers from "@/components/customer/ProfileIqvFormsAnswers";
import ProfileMasterKeyData from "@/components/customer/ProfileMasterKeyData";
import ProfileChartCharMapData from "@/components/customer/ProfileChartCharMapData";
import ProfileCommentsAndLateralityData from "@/components/customer/ProfileCommentsAndLateralityData";
import ButtonCharMapSendReport from "../components/customer/ButtonSendReport.vue";
import CharMapTraitsTable from "@/components/charts/CharMapTraitsTable";

import { mapState } from "vuex";

export default {
  name: "CustomerProfile",
  components: {
    ProfilePersonalData,
    ProfileIqvFormData,
    ProfileMasterKeyData,
    ProfileChartCharMapData,
    CharMapTraitsTable,
    ProfileCommentsAndLateralityData,
    ButtonCharMapSendReport,
    ProfileIqvFormsAnswers
  },
  data() {
    return {
      iqvForms: [],
      iqvFormsColumns: [
        {
          label: "ID",
          value: "id",
          component: <span class="text-dark font-weight-bold">0</span>
        },
        {
          label: "IQV",
          value: "iqv",
          component: <span class="text-dark font-weight-bold">60</span>
        },
        {
          label: "Data",
          value: "date",
          component: <span class="text-dark font-weight-bold">30/03/2020</span>
        },
        {
          label: "Versão do formulário",
          value: "version",
          component: <span class="text-dark font-weight-bold">#3</span>
        }
      ]
    };
  },
  computed: {
    ...mapState("customer", ["customer"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.view-customer-profile {
  .btn-map-report {
    .icon-pdf-file {
      font-size: 24px;
    }
  }

  .empty-characters-map {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
    border-radius: 8px;
    padding: 24px;

    .small-mobile {
      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
}
</style>
