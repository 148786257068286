<template>
  <div class="view-admin-lq-versions">
    <h5 class="mb-4">
      <router-link class="back" :to="{ name: 'lq-versions' }">
        <i class="icon-chevron-left"></i>
      </router-link>
      Nova versão do formulário IQV
    </h5>

    <div v-if="loading" class="text-center p-5">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
    </div>

    <div v-else>
      <form-group
        v-model="form.begin_title"
        label="TÍTULO"
        placeholder="Título"
        id="begin_title"
      />

      <form-group
        v-model="form.begin_description"
        label="DESCRIÇÃO"
        placeholder="Descrição"
        id="begin_description"
        textarea
      />

      <div class="pt-5" v-for="(section, index) in sections" :key="index">
        <h6>Etapa {{ sectionNumber(index) }}:</h6>

        <form-group
          v-model="section.name"
          label="NOME"
          placeholder="Nome"
          :id="'name' + index"
          class="mb-4"
        />

        <edit-form-questions
          :section="section"
          :id="index"
          @change="value => (sections[index].questions = value)"
        />
      </div>

      <div class="text-center pt-4">
        <button class="btn btn-success px-5" @click="save">
          <loading :show="loadingSave">
            Salvar
          </loading>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EditFormQuestions from "@/components/iqv/EditFormQuestions";

export default {
  components: { EditFormQuestions },
  data() {
    return {
      loadingSave: false,
      version: null,
      loading: true,
      form: {
        begin_title: "",
        begin_description: ""
      },
      sections: {}
    };
  },
  mounted() {
    this.$store
      .dispatch("lifeQuality/getLastVersion")
      .then(response => {
        this.version = response.data.data;
        this.form.begin_title = response.data.data.begin_title;
        this.form.begin_description = response.data.data.begin_description;

        const sections = response.data.data.sections.slice(1);
        for (let i in sections) {
          this.$set(this.sections, i, sections[i]);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    sectionNumber(index) {
      return parseInt(index) + 2;
    },
    save() {
      const form = {
        ...this.form,
        sections: [this.version.sections[0], ...Object.values(this.sections)]
      };

      this.loadingSave = true;
      this.$store
        .dispatch("lifeQuality/createVersion", { form })
        .then(() => {
          this.$router.push({ name: "lq-versions" });
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.loadingSave = false;
        });
    }
  }
};
</script>
