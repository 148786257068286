<template>
  <div class="analyses-step-2">
    <div class="col-lg-12">
      <h5 class="mb-4">
        <i class="btn icon-chevron-left" @click="$emit('previous')" />
        Resultado da análise
      </h5>

      <div
        class="d-flex flex-column flex-lg-row justify-content-end align-items-center mb-4"
      >
        <div class="d-flex">
          <b-form-checkbox v-model="averageLineVisible" class="mr-2" />
          <span>Exibir linha média do resultado</span>
        </div>
        <button type="button" class="btn btn-primary px-5 m-3" @click="save">
          <b-spinner v-if="loading" variant="light" class="mx-4" />
          <i v-if="!loading" class="icon-save mr-1" />
          <span v-if="!loading">Salvar análise</span>
        </button>
        <button-send-report :analyse="analyse" />
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-lg-1">
          <span class="text-muted">Clientes:</span>
        </div>
        <div class="col">
          <participants-list :participants="analyse.participants" />
        </div>
      </div>
      <div class="card-aspect mb-4">
        <combined-analyses-bar-chart
          v-if="!!analyse.participants && !!analyse.participants.length"
          class="pt-4"
          :averageLineVisible="averageLineVisible"
          :customers="analyse.participants"
        />
        <small v-else class="text-secondary">Nenhum cliente selecionado</small>
      </div>

      <table-selectable-customer :participants="analyse.participants" />
    </div>
  </div>
</template>

<script>
import TableSelectableCustomer from "@/components/analyses/TableSelectableCustomer";
import ButtonSendReport from "@/components/analyses/ButtonSendReport";
import CombinedAnalysesBarChart from "@/components/charts/CombinedAnalysesBarChart";
import ParticipantsList from "@/components/analyses/ParticipantsList.vue";
import { mapState } from "vuex";

export default {
  name: "AnalysesAnalystDetails",
  components: {
    CombinedAnalysesBarChart,
    TableSelectableCustomer,
    ButtonSendReport,
    ParticipantsList
  },
  data() {
    return {
      averageLineVisible: true,
      loading: false
    };
  },
  computed: {
    ...mapState("analyses", ["analyse"])
  },
  methods: {
    save() {
      if (this.analyse.participants.length < 2) {
        this.$message.error(
          "Escolha ao menos 2 clientes para participar da análise"
        );
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("analyses/update", {
          analyse_id: this.analyse.id,
          data: {
            ...this.analyse,
            participants: this.analyse.participants.map(p => p.client_id),
            receivers: this.analyse.receivers.map(r => r.client_id),
            receiversInfos: this.analyse.receivers.map(r => {
              const { client_id, name, email } = r;
              return { client_id, name, email };
            })
          }
        })
        .then(() => {
          this.$message.success("A análise foi atualizada");
        })
        .catch(errors => {
          this.$message.error("Error ao atualizar análise");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
