<template>
  <div class="home-table-cell-life-quality d-flex flex-column">
    <strong
      >{{ item.forms_overview.sent_count }} OCE enviado{{
        item.forms_overview.sent_count !== 1 ? "s" : ""
      }}</strong
    >
    <small class="text-secondary"
      >{{ item.forms_overview.answered_count || "0" }} respondido{{
        item.forms_overview.answered_count !== 1 ? "s" : ""
      }}</small
    >
    <small class="text-secondary">IQV inicial: {{ iqvInicial }}</small>
    <small class="text-secondary"
      >Índice de R. máximo:
      {{ Math.round(item.forms_overview.result_score_max * 100) || "0" }}</small
    >
    <small class="text-secondary"
      >Índice de R. mínimo:
      {{ Math.round(item.forms_overview.result_score_min * 100) || "0" }}</small
    >
    <small class="text-secondary"
      >Índice de R. médio:
      {{
        Math.round(item.forms_overview.result_score_mean * 100) || "0"
      }}</small
    >
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    iqvInicial() {
      return this.item.forms_overview.first_answer_mean
        ? Math.round(this.item.forms_overview.first_answer_mean)
        : "0";
    }
  }
};
</script>
