import axios from "axios";

// API
// devUrl -> 'https://o-corpo-explica-dev.herokuapp.com/api'
// stagingUrl -> 'https://o-corpo-explica-web-staging.herokuapp.com/api'
// productionUrl -> 'https://o-corpo-explica-web.herokuapp.com/api'

window.axios = axios;

if (
  document.location.hostname.includes("localhost") ||
  document.location.hostname.includes("staging")
) {
  window.axios.defaults.baseURL =
    "https://o-corpo-explica-dev.herokuapp.com/api";
} else {
  window.axios.defaults.baseURL =
    "https://o-corpo-explica-web.herokuapp.com/api";
}

window.axios.defaults.baseURL = "https://o-corpo-explica-web.herokuapp.com/api";

window.axios.defaults.timeout = 1000 * 60 * 5;

const TOKEN = "_apiToken";

const setAxiosHeader = token => {
  if (token) {
    window.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete window.axios.defaults.headers.common.Authorization;
  }
};

const setTokenInfo = tokenInfo =>
  localStorage.setItem("_apiTokenInfo", tokenInfo);

const setToken = token => {
  localStorage.setItem(TOKEN, token);
  setAxiosHeader(token);
};

const removeToken = () => {
  localStorage.removeItem(TOKEN);
  setAxiosHeader(null);
};

const getToken = () => {
  return localStorage.getItem(TOKEN);
};

const init = () => {
  const token = getToken();
  if (token) {
    setAxiosHeader(token);
  }
};

export { setToken, setTokenInfo, getToken, removeToken, init };
