import CharMapUtils from "@/functions/charactermap";
import moment from "moment";

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const buildURI = (data, uFEFF) => {
  const csv = data
    .filter(e => e)
    .map(row => row.join(","))
    .join(`\n`);
  const type = isSafari() ? "application/csv" : "text/csv";
  const blob = new Blob(["\uFEFF", csv], { type });
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? "\uFEFF" : ""}${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === "undefined"
    ? dataURI
    : URL.createObjectURL(blob);
};

export const customersToCSVArray = selected => {
  const csvHeader = [
    [
      "Analista",
      "Nome",
      "Email",
      "Tamanho da camisa",
      "Mapa dos Caracteres (%)",
      "Pontuação do Caracter E (%)",
      "Pontuação do Caracter O (%)",
      "Pontuação do Caracter P (%)",
      "Pontuação do Caracter M (%)",
      "Pontuação do Caracter R (%)",

      "Formulários respondidos",
      "Último relatório enviado em",
      "IQV Inicial",
      "Respondido em",
      "IQV Atual",
      "Respondido em",
      "Índice do Resultado",

      "Último mapa enviado em",
      "Último mapa atualizado em",

      "Chave mestra conduzidas",
      "Chave mestra concluídas",
      "Último passo concluído em",
      "Último relatório enviado em"
    ]
  ];

  const data = selected.map(
    ({
      name,
      email,
      shirt_size,
      characters_map,
      last_life_quality_form_send,
      forms_answered,
      forms_overview: {
        previous_iqv_answered_at,
        previous_iqv,
        iqv_answered_at,
        improvement_score
      },
      iqv,
      last_character_map_report_send,
      master_key_driven_count,
      master_key_completed_count,
      last_map_update,
      last_fact_step_update,
      last_master_key_report_send,
      analyst
    }) => {
      let values = [];
      let p = [];

      const mapPercent = value => {
        const res = ((value * 100) / 60).toFixed(0);
        return Number(res);
      };

      if (characters_map) {
        values = CharMapUtils.createLateralityArray(characters_map);
        p = values[values.length - 1].map(it => mapPercent(it.value));
      }

      const characterPercent = () => {
        if (!characters_map) return "-";
        return `E ${p[0]} / O ${p[1]} / P ${p[2]} / M ${p[3]} / R ${p[4]}`;
      };

      const strippedCharacterPercents = () => {
        if (!characters_map) return ["-", "-", "-", "-", "-"];
        return [p[0], p[1], p[2], p[3], p[4]];
      };

      const rIqv = () => {
        let answeredCount = forms_answered ?? "0";
        let lastSent = last_life_quality_form_send
          ? moment(last_life_quality_form_send).format("DD/MM/YYYY [às] H:mm")
          : "-";
        let initialIqv = previous_iqv ?? "-";
        let initialAnsweredAt = previous_iqv_answered_at
          ? moment(previous_iqv_answered_at).format("DD/MM/YYYY [às] H:mm")
          : "-";
        let currentIqv = iqv ?? "-";
        let currentAnsweredAt = iqv_answered_at
          ? moment(iqv_answered_at).format("DD/MM/YYYY [às] H:mm")
          : "-";
        let result = improvement_score
          ? `${Math.round(improvement_score * 100)}%`
          : "-";

        return [
          answeredCount, // Formulários respondidos
          lastSent, // Último relatório enviado em
          initialIqv, // IQV Inicial
          initialAnsweredAt, // Respondido em
          currentIqv, //IQV Atual
          currentAnsweredAt, // Respondido em,
          result // Indice do Resultado,
        ];
      };

      return [
        analyst ? analyst.name : "-",
        name,
        email ?? "-",
        shirt_size ?? "-",
        characterPercent(),
        ...strippedCharacterPercents(),
        ...rIqv(),
        last_character_map_report_send
          ? moment(last_character_map_report_send).format(
              "DD/MM/YYYY [às] H:mm"
            )
          : "-",
        last_map_update
          ? moment(last_map_update).format("DD/MM/YYYY [às] H:mm")
          : "-",
        master_key_driven_count ?? "-",
        master_key_completed_count ?? "-",
        last_fact_step_update
          ? moment(last_fact_step_update).format("DD/MM/YYYY [às] H:mm")
          : "-",
        last_master_key_report_send
          ? moment(last_master_key_report_send).format("DD/MM/YYYY [às] H:mm")
          : "-"
      ];
    }
  );
  return [...csvHeader, ...data];
};

export const createCustomersCSVHref = selected => {
  const data = customersToCSVArray(selected);
  const href = buildURI(data);
  return href;
};
