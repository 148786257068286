import { Doughnut } from "vue-chartjs";

export default {
  extends: Doughnut,
  props: {
    traits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartData: {},
      colors: {
        E: "#0099FF",
        O: "#FFCC00",
        P: "#FF8A00",
        M: "#00FF00",
        R: "#FF00FF"
      },
      options: {
        legend: {
          display: false
        },
        cutoutPercentage: 90,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: {
                display: false,
                autoSkip: true,
                maxTicksLimit: 60,
                beginAtZero: true,
                fontColor: "#060620",
                fontSize: 16,
                padding: 10
              },
              gridLines: {
                display: false,
                color: "#99999955",
                drawBorder: false,
                drawTicks: false,
                offsetGridLines: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                maxTicksLimit: 10,
                beginAtZero: true,
                fontColor: "#AEB6C4",
                drawOnChartArea: true
              },
              gridLines: {
                display: false
              }
            }
          ]
        }
      }
    };
  },
  watch: {
    traits(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.fillDatasets();
        this.renderChart(this.chartData, this.options);
      }
    }
  },
  mounted() {
    this.fillDatasets();
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    fillDatasets() {
      this.chartData = {
        labels: [...this.traits.map(t => t.char), "#"],
        datasets: [
          {
            data: [
              ...this.traits.map(t => parseInt(t.percent)),
              100 - this.traits.reduce((acc, t) => parseInt(t.percent) + acc, 0)
            ],
            label: "",
            legend: true,
            backgroundColor: [
              ...this.traits.map(t => this.colors[t.char]),
              "#E9EDF2"
            ],
            borderColor: [
              ...this.traits.map(t => this.colors[t.char]),
              "#E9EDF2"
            ],
            hoverBackgroundColor: [
              ...this.traits.map(t => this.colors[t.char]),
              "#E9EDF2"
            ],
            hoverBorderColor: [
              ...this.traits.map(t => this.colors[t.char]),
              "#E9EDF2"
            ],
            barPercentage: 0.2
          }
        ]
      };
    }
  }
};
