<template>
  <div class="home-table-cell-char-map">
    <div class="d-flex flex-column">
      <strong>{{ item.client_count }} clientes</strong>
      <small class="text-secondary">{{
        item.internal_analyst ? "Analista interno" : ""
      }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    lastUpdate() {
      return this.item.last_map_update
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.home-table-cell-char-map-modal {
  .icon-wrapper {
    margin: auto;
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #212631;
  }
}
</style>
