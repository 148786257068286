<template>
  <div class="home-table-cell-life-quality d-flex flex-column">
    <strong
      >{{ item.analyses_overview.analyses_count }} realizada{{
        item.analyses_overview.analyses_count !== 1 ? "s" : ""
      }}</strong
    >
    <small class="text-secondary"
      >Maior análise:
      {{ item.analyses_overview.participants_max || "0" }} participante{{
        item.analyses_overview.participants_max !== 1 ? "s" : ""
      }}</small
    >
    <small class="text-secondary"
      >Menor análise:
      {{ item.analyses_overview.participants_min || "0" }} participante{{
        item.analyses_overview.participants_min !== 1 ? "s" : ""
      }}</small
    >
    <small class="text-secondary">Média de pessoas: {{ peopleAverage }}</small>
    <small class="text-secondary">Última realização: {{ lastDid }}</small>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    peopleAverage() {
      if (!this.item.analyses_overview.participants_average) return "0";

      return Math.round(this.item.analyses_overview.participants_average);
    },
    lastDid() {
      if (!this.item.analyses_overview.last_analysis_update)
        return "não realizado";

      return moment(
        this.item.analyses_overview.last_analysis_update,
        "YYYY-MM-DD hh:mm:ss"
      ).format("DD/MM/YYYY");
    }
  }
};
</script>
