<template>
  <div class="analysts-analyses-data">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-lg-3 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">
                {{ analyst.analyses_overview.analyses_count }}
              </span>
              <small class="text-muted">Analises criadas</small>
            </div>
          </div>
          <div class="col-lg-3 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">
                {{ analyst.analyses_overview.sent_count }}
              </span>
              <small class="text-muted">Analises enviadas</small>
            </div>
          </div>
          <div class="col-lg-3 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">{{
                analyst.analyses_overview.participants_max
              }}</span>
              <small class="text-muted">
                Máximo de participantes por análise
              </small>
            </div>
          </div>
          <div class="col-lg-3 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">{{
                analyst.analyses_overview.participants_min
              }}</span>
              <small class="text-muted">
                Mínimo de participantes por análise
              </small>
            </div>
          </div>
          <div class="col-lg-3 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">{{
                analyst.analyses_overview.participants_average
              }}</span>
              <small class="text-muted">
                Média de participantes por análise
              </small>
            </div>
          </div>
          <div class="col-lg-4 mb-3">
            <div class="d-flex flex-column justify-content-center flex-fill">
              <span class="font-weight-bold">{{ lastUpdate }}</span>
              <small class="text-muted">
                Data da última atualização em uma análise
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 mb-3 pt-1">
        <router-link
          :to="{
            name: 'admin-analyses-analyst-list',
            params: { id: analyst.id }
          }"
          type="button"
          class="btn btn-outline-success"
        >
          Ver análises Combinadas do analista
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    analyst: {
      type: Object,
      default: null
    }
  },
  computed: {
    lastUpdate() {
      return moment(this.analyst.analyses_overview.last_analysis_update).format(
        "DD/MM/YYYY"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.analysts-analyses-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    span {
      font-size: 12px;
    }
  }
}
</style>
