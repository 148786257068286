<template>
  <div class="d-flex flex-column">
    <strong
      >{{ item.character_map_overview.completed_count }} realizados</strong
    >
    <small class="text-secondary">{{ lastDid }}</small>
    <small class="text-secondary">
      Relatórios enviados: {{ item.character_map_overview.sent_count }}</small
    >
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    lastDid() {
      if (!this.item.character_map_overview.last_completed) return "";

      return moment(
        this.item.character_map_overview.last_completed,
        "YYYY-MM-DD hh:mm:ss"
      ).format("[Último mapa em:] DD/MM/YYYY");
    }
  }
};
</script>
