<template>
  <div v-if="!!customer.last_map_update">
    <button
      class="btn btn-map-report btn-outline-dark d-flex align-items-center"
      @click="visible = true"
    >
      <i class="icon-pdf-file mr-1"></i>
      Gerar relatório de mapa dos caracteres
      <i class="icon-arrow-right ml-1"></i>
    </button>
    <b-modal v-model="visible" hide-footer hide-header centered>
      <div class="confirmation p-2">
        <div class="row justify-content-center mb-4">
          <img src="@/assets/images/rounded-mail-icon.svg" />
        </div>

        <h3 class="text-center px-4 pt-2 mb-3">
          Gerar Relatório do Mapa dos Caracteres
        </h3>

        <div class="text-center px-4 pt-2 pb-3 mb-4 text">
          Você pode fazer o download do PDF do relatório de mapa dos caracteres
          ou enviar por e-mail. O que deseja fazer?
        </div>

        <div class="row">
          <div class="col">
            <button
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="download"
            >
              <b-spinner variant="light" v-if="loading.download" />
              <span v-if="!loading.download">Fazer Download</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="sendToCustomer"
            >
              <b-spinner variant="light" v-if="loading.customer" />
              <span v-if="!loading.customer">Enviar para cliente</span>
            </button>
            <button
              v-if="$user.user.user_type !== 'admin'"
              class="btn btn-block btn-lg btn-success font-weight-bold"
              @click="sendToAnalyst"
            >
              <b-spinner variant="light" v-if="loading.analyst" />
              <span v-if="!loading.analyst">Enviar para analista</span>
            </button>
            <button
              class="btn btn-block btn-outline-secondary btn-lg font-weight-bold"
              @click="cancel"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visible: false,
      loading: {
        download: false,
        customer: false,
        analyst: false
      }
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    cancel() {
      this.visible = false;
    },
    download() {
      if (this.loading.download) return;
      this.loading.download = true;
      this.$store
        .dispatch("charactersMap/downloadReport", {
          customer_id: this.$route.params.id
        })
        .then(response => {
          const a = document.createElement("a");
          const blobURL = URL.createObjectURL(response.data);
          a.href = blobURL;

          const customerNameSplit = this.customer.name.split(" ");

          const customerLastLetter =
            customerNameSplit.length > 1
              ? `-${customerNameSplit[customerNameSplit.length - 1]}`
              : "";

          const analystNameSplit = this.$user.user.name.split(" ");

          const analystLastLetter =
            analystNameSplit.length > 1
              ? `-${analystNameSplit[analystNameSplit.length - 1]}`
              : "";

          const pdfName = `${moment(this.customer.last_map_update).format(
            "YYYYMMDD"
          )}-${customerNameSplit[0]}${customerLastLetter}-${
            analystNameSplit[0]
          }${analystLastLetter}.pdf`;

          a.setAttribute("download", pdfName);

          a.click();
          URL.revokeObjectURL(blobURL);
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.download = false;
        });
    },
    sendToAnalyst() {
      if (this.loading.analyst) return;
      this.loading.analyst = true;
      this.$store
        .dispatch("charactersMap/sendEmailToAnalyst", {
          customer_id: this.$route.params.id
        })
        .then(response => {
          this.$message.success("Relatório enviado para o e-mail do analista");
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.analyst = false;
        });
    },
    sendToCustomer() {
      if (this.loading.customer) return;
      this.loading.customer = true;
      this.$store
        .dispatch("charactersMap/sendEmailToClient", {
          customer_id: this.$route.params.id
        })
        .then(response => {
          this.$message.success("Relatório enviado para o e-mail do cliente");
        })
        .catch(error => this.$message.error(error))
        .finally(() => {
          this.visible = false;
          this.loading.customer = false;
        });
    }
  }
};
</script>
