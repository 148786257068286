<template>
  <div class="customer-profile-personal-data">
    <!-- personal info end -->
    <div class="row">
      <div class="col-lg-3 mb-3">
        <div class="d-flex">
          <div class="mr-3">
            <img
              v-if="!!customer && !!customer.image_url"
              class="customer-picture big"
              :src="customer.image_url"
            />
            <span v-else class="customer-picture big"
              ><i class="icon-user"></i
            ></span>
          </div>
          <div class="d-flex flex-column justify-content-center flex-fill">
            <span class="font-weight-bold">{{ customer.name }}</span>
          </div>
          <actions-dropdown
            class="d-lg-none"
            :actions="actions"
            @edit="edit(customer)"
            @remove="remove(customer)"
          />
        </div>
      </div>
      <div class="col-6 col-lg d-flex flex-column justify-content-center mb-3">
        <span class="font-weight-bold">{{ customer.phone }}</span>
        <small class="text-muted">Telefone</small>
      </div>
      <div class="col-6 col-lg d-flex flex-column justify-content-center mb-3">
        <span class="font-weight-bold">{{ customer.email }}</span>
        <small class="text-muted">E-mail</small>
      </div>
      <div class="col d-flex flex-column justify-content-center">
        <span class="font-weight-bold">{{
          customer.cpf || customer.foreign_document
        }}</span>
        <small class="text-muted">
          <span v-if="customer.cpf">CPF</span>
          <span v-else-if="customer.foreign_document"
            >Documento Estrangeiro</span
          >
          <span v-else>Documento Não Informado</span>
        </small>
      </div>
      <div
        v-if="customer.analyst"
        class="col d-flex flex-column justify-content-center"
      >
        <span class="font-weight-bold">{{ customer.analyst.name }}</span>
        <small class="text-muted">Analista</small>
      </div>
      <div class="col-1 d-none d-lg-flex flex-column justify-content-center">
        <actions-dropdown
          :actions="actions"
          @edit="edit(customer)"
          @remove="remove(customer)"
        />
      </div>
    </div>
    <!-- personal info end -->
    <hr />
    <div class="row">
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">EMPRESA</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.company"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">SETOR</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.sector"
            disabled
          />
        </div>
      </div>
      <div v-if="showShirt" class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">TAMANHO DA CAMISETA</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.shirt_size"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">DATA DE CADASTRO</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="registerDate"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">PAÍS</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.country"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">UF</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.uf"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">CIDADE</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.city"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">CEP</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.zip_code | formatCep(!!customer.address)"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="form-group">
          <label class="text-dark">ENDEREÇO</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.address || customer.foreign_address"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div v-if="!customer.foreign_address_2" class="row">
          <div class="col">
            <div class="form-group">
              <label class="text-dark">COMPLEMENTO</label>
              <input
                type="text"
                class="form-control bg-white"
                :value="customer.address_complement"
                disabled
              />
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="form-group">
              <label class="text-dark">NÚMERO</label>
              <input
                type="text"
                class="form-control bg-white"
                :value="customer.address_number"
                disabled
              />
            </div>
          </div>
        </div>
        <div v-if="!!customer.foreign_address_2" class="form-group">
          <label class="text-dark">ENDEREÇO 2</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="customer.foreign_address_2"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DeleteCustomerMixin from "@/mixins/CustomerDropdown";

export default {
  mixins: [DeleteCustomerMixin],
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  filters: {
    formatCep: function(cep, address) {
      if (address) {
        return cep.replace(/^(\d{5})(\d)/, "$1-$2");
      }
      return cep;
    }
  },
  computed: {
    registerDate() {
      return moment(this.customer.created_at).format("DD/MM/YYYY");
    },
    showShirt() {
      return (
        this.$user.user.internal_analyst ||
        this.$user.user.user_type === "admin"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.customer-profile-personal-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    span {
      font-size: 12px;
    }
  }
}
</style>
