class CharMapUtils {
  static createLateralityArray = characters => {
    const result = [];

    const head = CharMapUtils.filterTraitsByType(characters, "head");
    const eyes = CharMapUtils.filterTraitsByType(characters, "eyes");
    const mout = CharMapUtils.filterTraitsByType(characters, "mouth");
    const back = CharMapUtils.filterTraitsByType(characters, "back");
    const hips = CharMapUtils.filterTraitsByType(characters, "hip");
    const legs = CharMapUtils.filterTraitsByType(characters, "legs");

    result.push(head);
    result.push(eyes);
    result.push(mout);
    result.push(back);
    result.push(hips);
    result.push(legs);

    const E = {
      value: CharMapUtils.sumTraitsByKey(characters, "E"),
      left: undefined,
      right: undefined
    };
    const M = {
      value: CharMapUtils.sumTraitsByKey(characters, "M"),
      left: undefined,
      right: undefined
    };
    const O = {
      value: CharMapUtils.sumTraitsByKey(characters, "O"),
      left: undefined,
      right: undefined
    };
    const P = {
      value: CharMapUtils.sumTraitsByKey(characters, "P"),
      left: undefined,
      right: undefined
    };
    const R = {
      value: CharMapUtils.sumTraitsByKey(characters, "R"),
      left: undefined,
      right: undefined
    };

    const last = [E, O, P, M, R];
    result.push(last);
    return result;
  };

  static filterTraitsByType = (characters, type) =>
    characters
      .find(it => it.type === type)
      ?.traits.sort(CharMapUtils.traitSorting)
      .map(it => ({
        value: it.value,
        left: it.left,
        right: it.right
      }));

  static keyToInt = type => {
    switch (type) {
      case "E":
        return 1;
      case "O":
        return 2;
      case "P":
        return 3;
      case "M":
        return 4;
      case "R":
        return 5;
      default:
        return -1;
    }
  };

  static traitSorting = (a, b) =>
    CharMapUtils.keyToInt(a.name) - CharMapUtils.keyToInt(b.name);

  static sumTraitsByKey = (characters, key) =>
    characters
      .map(it => it.traits)
      .flat()
      .filter(it => it.name === key)
      .map(it => it.value)
      .reduce((acc, cur) => acc + cur, 0);

  static averageByTrait = (key, clients) =>
    clients
      .map(it => CharMapUtils.sumTraitsByKey(it.characters, key))
      .reduce((acc, curr) => acc + curr, 0) / clients.length;

  static MAP_TRAIT_TO_NAME = {
    E: "Esquizoide",
    O: "Oral",
    P: "Psicopata",
    M: "Masoquista",
    R: "Rígido"
  };
}

export default CharMapUtils;
