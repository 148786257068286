<template>
  <div class="iqv-step-0">
    <h4 class="mb-4">{{ title }}</h4>
    <p class="mb-5">{{ description }}</p>
    <div class="text-right">
      <button class="btn btn-outline-success px-4" @click="next">
        Próxima etapa <i class="icon-arrow-right"></i>
      </button>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    title() {
      return get(this.form, "begin_title");
    },
    description() {
      return get(this.form, "begin_description");
    }
  },
  methods: {
    next() {
      this.$emit("next", this.selected);
    }
  }
};
</script>
