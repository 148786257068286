export default {
  data() {
    return {
      params: {
        page: 1,
        search: "",
        letter: undefined,
        orderBy: "name",
        sortedBy: undefined
      }
    };
  },
  watch: {
    "params.search"() {
      this.dispatch();
    }
  },
  methods: {
    dispatch() {},
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    }
  }
};
