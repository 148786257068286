<template>
  <div class="profile-comments-laterality-data">
    <h5 class="mb-5">Comentários ou lateralidades</h5>
    <div class="accordion" id="#accordionId">
      <div v-for="(trait, index) in traits" :key="index" class="bottom-divider">
        <button
          class="btn btn-sm btn-block text-left"
          :class="{ 'custom-rounded-bg': visibleIndex === index }"
          type="button"
          @click="setIndex(index)"
        >
          <div class="d-flex align-items-center">
            <img :src="trait.icon" />
            <div class="flex-fill pl-4">
              <span>{{ trait.text }}</span>
            </div>
            <i
              :class="{
                'icon-chevron-right': visibleIndex !== index,
                'icon-chevron-down': visibleIndex === index
              }"
            ></i>
          </div>
        </button>

        <div
          id="collapseOne"
          class="collapse"
          :class="{ show: visibleIndex === index }"
          aria-labelledby="headingOne"
          data-parent="#accordionId"
        >
          <div class="card-body">
            <div
              class="text-muted mb-3"
              v-html="getNotes(infoByBodyPart[trait.name])"
            />
            <div
              class="text-muted mb-3"
              v-html="getComment(infoByBodyPart[trait.name])"
            />
          </div>
        </div>
        <hr v-if="index !== traits.length - 1" class="my-2" />
      </div>
    </div>
  </div>
</template>

<script>
import charMapUtils from "@/functions/charactermap";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      visibleIndex: null,
      infoByBodyPart: {},
      traits: [
        {
          name: "head",
          text: "Cabeça",
          icon: require("@/assets/chart/head.svg")
        },
        {
          name: "eyes",
          text: "Olhos",
          icon: require("@/assets/chart/eye.svg")
        },
        {
          name: "mouth",
          text: "Boca",
          icon: require("@/assets/chart/mouth.svg")
        },
        {
          name: "back",
          text: "Tronco",
          icon: require("@/assets/chart/back.svg")
        },
        {
          name: "hip",
          text: "Quadril",
          icon: require("@/assets/chart/breech.svg")
        },
        {
          name: "legs",
          text: "Pernas",
          icon: require("@/assets/chart/legs.svg")
        }
      ]
    };
  },
  mounted() {
    if (this.customer.characters_map) {
      this.infoByBodyPart = {
        back: this.getCommentsAndLaterality("back"),
        head: this.getCommentsAndLaterality("head"),
        eyes: this.getCommentsAndLaterality("eyes"),
        mouth: this.getCommentsAndLaterality("mouth"),
        hip: this.getCommentsAndLaterality("hip"),
        legs: this.getCommentsAndLaterality("legs")
      };
    }
  },
  methods: {
    setIndex(index) {
      if (this.visibleIndex === index) this.visibleIndex = null;
      else this.visibleIndex = index;
    },
    getCommentsAndLaterality(bodyPart) {
      if (!this.customer.characters_map) return [];

      const charMap = this.customer.characters_map.find(
        bp => bp.type === bodyPart
      );

      return charMap;
    },
    getNotes(bodyPartInfo) {
      if (!bodyPartInfo || !bodyPartInfo.notes)
        return "Sem notas para esta parte do corpo";

      return `
        <strong class="text-dark d-block mb-2">Nota:</strong>
        ${bodyPartInfo.notes.replace(/\n/g, "<br />")}
      `;
    },
    getComment(bodyPartInfo) {
      if (!bodyPartInfo || !bodyPartInfo.traits)
        return "Sem comentários para esta parte do corpo";

      const traits = bodyPartInfo.traits;

      if (!traits.map(({ comment }) => comment).join(""))
        return "Sem comentários para esta parte do corpo";

      const comments = traits.map(({ name, comment, left, right }) => {
        return comment
          ? `<div class="mb-3">
          <strong class="d-block">${
            charMapUtils.MAP_TRAIT_TO_NAME[name]
          } ${this.getLaterality(left, right)}</strong>
          ${comment}
        </div>`
          : "";
      });

      return `<strong class="text-dark d-block mb-2">Comentários:</strong>${comments.join(
        ""
      )}`;
    },
    getLaterality(left, right) {
      if (left && right) return "(Esquerda e Direita)";
      if (left) return "(Esquerda)";
      if (right) return "(Direita)";
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-comments-laterality-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;

  img {
    width: 35px;
    height: 35px;
  }
}
</style>
