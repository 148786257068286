<template>
  <div class="analysts-personal-data">
    <!-- personal info end -->
    <div class="row">
      <div class="col-lg-3 mb-3">
        <div class="d-flex">
          <!-- <div class="mr-3">
            <img
              v-if="!!analyst && !!analyst.image_url"
              class="customer-picture big"
              :src="analyst.image_url"
            />
            <span v-else class="customer-picture big"
              ><i class="icon-user"></i
            ></span>
          </div> -->
          <div class="d-flex flex-column justify-content-center flex-fill">
            <span class="font-weight-bold">{{ analyst.name }}</span>
            <small class="text-muted">Analista</small>
          </div>
          <actions-dropdown
            class="d-lg-none"
            :actions="actions"
            @edit="edit(analyst)"
            @send="send(analyst)"
            @remove="remove(analyst)"
          />
        </div>
      </div>
      <div class="col-6 col-lg d-flex flex-column justify-content-center mb-3">
        <span class="font-weight-bold">{{ analyst.phone }}</span>
        <small class="text-muted">Telefone</small>
      </div>
      <div class="col-6 col-lg d-flex flex-column justify-content-center mb-3">
        <span class="font-weight-bold">{{ analyst.email }}</span>
        <small class="text-muted">E-mail</small>
      </div>
      <div class="col d-flex flex-column justify-content-center">
        <span class="font-weight-bold">{{
          analyst.cpf || analyst.foreign_document
        }}</span>
        <small class="text-muted">{{
          !!analyst.cpf ? "CPF" : "Documento Estrangeiro"
        }}</small>
      </div>
      <div
        v-if="analyst.analyst"
        class="col d-flex flex-column justify-content-center"
      >
        <span class="font-weight-bold">{{ analyst.analyst.name }}</span>
        <small class="text-muted">Analista</small>
      </div>
      <div class="col-1 d-none d-lg-flex flex-column justify-content-center">
        <actions-dropdown
          :actions="actions"
          @edit="edit(analyst)"
          @send="send(analyst)"
          @remove="remove(analyst)"
        />
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">DATA DE CADASTRO</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="registerDate"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">PAÍS</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="analyst.country"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">UF</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="analyst.uf"
            disabled
          />
        </div>
      </div>
      <div class="col-12 col-lg">
        <div class="form-group">
          <label class="text-dark">CIDADE</label>
          <input
            type="text"
            class="form-control bg-white"
            :value="analyst.city"
            disabled
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnalystDropdown from "@/mixins/AnalystDropdown";
import moment from "moment";

export default {
  mixins: [AnalystDropdown],
  props: {
    analyst: {
      type: Object,
      default: null
    }
  },
  computed: {
    registerDate() {
      return moment(this.analyst.created_at).format("DD/MM/YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.analysts-personal-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 40px;

  @media screen and (max-width: 991px) {
    span {
      font-size: 12px;
    }
  }
}
</style>
