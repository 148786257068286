<template>
  <div class="analyses-step-1">
    <div class="row">
      <div class="col-lg-9">
        <form-group
          id="title"
          placeholder="Digite o título da análise"
          v-model="form.title"
          :errors="errors.title"
        />
      </div>
      <div class="col pt-2 text-right">
        <button class="btn btn-success btn-block py-2" @click="nextStep">
          Gerar análise
        </button>
      </div>
    </div>
    <div class="pt-4 row align-items-center">
      <div class="col-lg-6">
        <h5 class="mr-5 d-flex mb-0">
          Selecione ao menos 1 cliente para receber o relatório da análise
          combinada
        </h5>
      </div>
      <div class="col-lg-6">
        <search-filter placeholder="Pesquisar por cliente" @search="search" />
      </div>
    </div>

    <div
      v-if="!loading && !customers.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há clientes cadastrados</strong>
      <span class="text-secondary">Cadastre um cliente para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      keyForCheckedTest="client_id"
      :loading="loading"
      :columns="columns"
      :data="customers"
      :is-selectable="false"
    />

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />
  </div>
</template>

<script>
import TableCellSelectReceivers from "@/components/analyses/TableCellSelectReceivers";
import SearchFilter from "@/components/home/SearchFilter";
import { formFields } from "@/functions/helpers";
import CustomerMixin from "@/mixins/Customer";
import { mapState } from "vuex";
import moment from "moment";

export default {
  components: { SearchFilter },
  mixins: [CustomerMixin],
  data() {
    return {
      ...formFields(["title"]),
      columns: [
        {
          label: "",
          value: "select",
          component: TableCellSelectReceivers
        },
        {
          label: "Nome do cliente",
          value: "name",
          formatter: item => `<strong>${item.name}</strong>`
        },
        {
          label: "E-mail",
          value: "email",
          formatter: item => `<strong>${item.name}</strong>`
        },
        {
          label: "Detalhes",
          value: "forms_answered",
          formatter: item =>
            `<div class="text-left d-flex flex-column">
                <strong>${
                  !!item.characters_map ? "Realizado" : "Mapa não realizado"
                }</strong>
                <span class="text-muted">${moment(item.created_at).format(
                  "[Data de criação:] DD/MM/YYYY"
                )}
              </span>
            </div>`
        }
      ]
    };
  },
  computed: {
    ...mapState("analyses", ["analyse"])
  },
  methods: {
    nextStep() {
      if (!this.form.title) {
        this.errors = {
          title: ["Digite o título da nova análise"]
        };
        return;
      }

      if (!this.analyse.receivers.length) {
        this.$message.error(
          "Selecione ao menos 1 cliente para receber o relatório da análise combinada"
        );
        return;
      }

      this.$store.commit("analyses/setAnalyse", {
        ...this.analyse,
        title: this.form.title
      });

      this.$emit("next");
    }
  }
};
</script>
