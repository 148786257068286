<template>
  <div>
    <button
      class="btn btn-sm text-success d-flex align-items-center"
      @click="openCloseDialog"
    >
      <img class="img-fluid mr-2" src="@/assets/images/mail-green-icon.svg" />
      <span>Ver todos</span>
    </button>
    <b-modal v-model="dialog" hide-header hide-footer centered>
      <div class="p-3">
        <h3 class="text-center mb-3">Destinatários</h3>
        <div class="text-center text-muted mb-5">
          Pessoas que receberam por e-mail a análise combinada:
        </div>
        <table class="table mb-5">
          <thead>
            <tr>
              <td class="text-muted">Nome do analista</td>
              <td class="text-muted">E-mail</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(receiver, index) in item.receivers" :key="index">
              <td>{{ receiver.name }}</td>
              <td>{{ receiver.email }}</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center px-5">
          <button
            class="btn btn-success btn-block mx-5"
            @click="openCloseDialog"
          >
            Fechar
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    openCloseDialog() {
      this.dialog = !this.dialog;
    }
  }
};
</script>
