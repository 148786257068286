<template>
  <div class="view-customer">
    <h5 class="mb-4">Analistas</h5>

    <div class="row">
      <div class="col-lg-7">
        <search-filter
          placeholder="Pesquisar analista"
          :searchValue="params.letter"
          @search="search"
          @searchLetter="searchLetter"
        />
      </div>

      <div
        class="col d-flex justify-content-sm-end justify-content-center pt-3 pt-lg-0"
      >
        <button
          class="btn btn-outline-success px-4 mr-3 position-relative"
          @click="openImportDialog"
        >
          Importar analistas
        </button>
        <router-link
          class="btn btn-success px-4 position-relative"
          :to="{ name: 'admin-register-analysts' }"
        >
          Novo analista
        </router-link>
      </div>
    </div>

    <div v-if="!!params.letter" class="row pt-2">
      <div class="col-2 m-0 text-muted">Filtrando pela letra:</div>
      <div class="col pl-0 ml-0">
        <button class="btn btn-sm letter-indicator" @click="removeLetter">
          <span class="mr-2 text-dark">{{ params.letter }}</span>
          <i class="icon-x text-dark" />
        </button>
      </div>
    </div>

    <div v-if="selected.length" class="d-flex pt-2 mb-3">
      <div class="mr-2 text-muted py-1">Selecionados:</div>
      <div class="flex-fill d-flex flex-wrap">
        <div v-for="(it, index) in selected" :key="index" class="p-0 m-1">
          <button class="btn btn-sm customer-indicator">
            <span class="text-dark">{{ it.name }}</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!!selected.length" class="d-flex flex-column flex-md-row">
      <button class="btn btn-outline-dark my-1" @click="resendVisible = true">
        <b-spinner v-if="loadingAccess" />
        <i v-if="!loadingAccess" class="icon-send-email mr-1" />
        <span v-if="!loadingAccess">Reenviar e-mail(s) de acesso</span>
      </button>

      <button
        class="btn btn-outline-danger my-1 mx-2"
        @click="deleteVisible = true"
      >
        <b-spinner v-if="loadingDelete" />
        <i v-if="!loadingDelete" class="icon-trash mr-1" />
        <span v-if="!loadingDelete">Excluir analistas</span>
      </button>

      <button
        class="btn btn-sm btn-warning my-1"
        @click="$store.commit('analysts/setSelected', [])"
      >
        Limpar seleção
      </button>
    </div>

    <div
      v-if="!loading && !analysts.length"
      class="empty p-5 d-flex flex-column justify-content-center align-items-center"
    >
      <img src="@/assets/images/no-client.svg" />
      <strong>Ainda não há analistas cadastrados</strong>
      <span class="text-secondary">Cadastre um analista para começar</span>
    </div>

    <data-table
      v-else
      class="mt-5"
      :loading="loading"
      :columns="columns"
      :data="analysts"
      :is-selectable="false"
    >
      <template #action="{ item }">
        <actions-dropdown
          :actions="actions"
          @edit="edit(item)"
          @send="send(item)"
          @remove="remove(item)"
        />
      </template>
    </data-table>

    <pagination :total-pages="pagination.total_pages" @set-page="setPage" />

    <b-modal
      v-model="importDialog"
      hide-header
      hide-footer
      centered
      :size="importModalSize"
    >
      <import-modal
        @size="size => (importModalSize = size)"
        @close="importDialog = false"
        @saved="dispatch"
      />
    </b-modal>

    <confirmation
      v-if="selected.length"
      iconType="warning"
      title="Tem certeza que deseja realizar esta ação?"
      cancelButtonText="Cancelar"
      v-model="resendVisible"
      confirmButtonClass="btn-success"
      confirmButtonText="Reenviar"
      text="Uma nova senha de acesso será reenviada para os analistas selecionados, reenviar?"
      :loading="loadingAccess"
      @confirm="submitResendAccess"
      @cancel="resendVisible = false"
    />

    <confirmation
      v-if="selected.length"
      iconType="warning"
      title="Tem certeza que deseja realizar esta ação?"
      cancelButtonText="Cancelar"
      v-model="deleteVisible"
      confirmButtonClass="btn-danger"
      confirmButtonText="Confirmar Exclusão"
      text="O cadastro dos analistas selecionados será excluído do sistema, confirmar exclusão?"
      :loading="loadingDelete"
      @confirm="submitDelete"
      @cancel="deleteVisible = false"
    />
  </div>
</template>

<script>
import AnalystDropdownMixin from "@/mixins/AnalystDropdown";
import SearchFilter from "@/components/analysts/SearchFilter";
import TableCellName from "@/components/adminHome/TableCellName";
import TableCellDetails from "@/components/adminHome/TableCellDetails";
import TableCellLifeQuality from "@/components/adminHome/TableCellLifeQuality";
import TableCellMasterKey from "@/components/adminHome/TableCellMasterKey";
import TableCellCharactersMap from "@/components/adminHome/TableCellCharactersMap.vue";
import TableCellCombinedAnalysis from "@/components/adminHome/TableCellCombinedAnalysis.vue";
import ImportModal from "@/components/adminHome/ImportModal";

import { mapState } from "vuex";

export default {
  mixins: [AnalystDropdownMixin],
  components: { SearchFilter, ImportModal },
  data() {
    return {
      importModalSize: "md",
      importDialog: false,
      resendVisible: false,
      deleteVisible: false,
      loadingDelete: false,
      loadingAccess: false,
      loading: false,
      error: false,
      params: {
        page: 1,
        search: "",
        letter: null,
        orderBy: "name"
      },
      columns: [
        {
          label: "Nome do analista",
          component: TableCellName
        },
        {
          label: "Detalhes",
          component: TableCellDetails
        },
        {
          label: "Mapa dos caracteres",
          component: TableCellCharactersMap
        },
        {
          label: "Formulário de acompanhamento",
          component: TableCellLifeQuality
        },
        {
          label: "Análise combinada",
          component: TableCellCombinedAnalysis
        },
        {
          label: "Chave mestra",
          component: TableCellMasterKey
        }
      ]
    };
  },
  computed: {
    ...mapState("analysts", ["analysts", "selected", "pagination"])
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    openImportDialog() {
      this.importDialog = true;
      this.importModalSize = "md";
    },
    dispatch() {
      this.loading = true;
      const params = {};
      for (let i in this.params) {
        if (this.params[i]) {
          params[i] = this.params[i];
        }
      }
      this.$store
        .dispatch("analysts/get", params)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setPage(page) {
      this.params.page = page;
      this.dispatch();
    },
    searchLetter(letter) {
      this.params.letter = letter;
      this.dispatch();
    },
    search(term) {
      this.params.search = term;
      this.dispatch();
    },
    removeLetter() {
      this.params.letter = null;
      this.dispatch();
    },
    submitResendAccess() {
      if (!this.selected.length) {
        this.$message.error("Selecione pelo menos 1 analista");
        return;
      }

      this.loadingAccess = true;
      this.$store
        .dispatch(
          "user/resendAccess",
          this.selected.map(a => a.id)
        )
        .then(response => {
          this.dispatch();
          this.$message.success("Os e-mails de acesso foram enviados");
        })
        .catch(errors => {
          this.$message.error("Erro ao reenviar acesso para os analistas");
        })
        .finally(() => {
          this.loadingAccess = false;
        });
    },
    submitDelete() {
      if (!this.selected.length) {
        this.$message.error("Selecione pelo menos 1 analista");
        return;
      }
      this.loadingDelete = true;
      this.$store
        .dispatch(
          "analysts/deleteBatch",
          this.selected.map(a => a.id)
        )
        .then(response => {
          this.$store.commit("analysts/setSelected", []);
          this.dispatch();
          this.$message.success("Os analistas foram deletados");
        })
        .catch(errors => {
          this.$message.error("Erro ao deletar analistas em massa");
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-customer {
  .empty {
    font-size: 14px;
    text-align: center;
  }
  .letter-indicator {
    background: rgba(1, 65, 108, 0.1);
  }

  .customer-indicator {
    border: 1px solid rgba(1, 65, 108, 0.1);
    border-radius: 3px;
  }
}
</style>
