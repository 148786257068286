<template>
  <div class="home-table-cell-char-map">
    <router-link
      v-if="!item.characters_map"
      :to="{ name: 'char-map-form', params: { id: item.id } }"
      type="button"
      class="btn btn-sm row align-items-center"
    >
      <i class="icon-plus mr-2 text-success font-weight-bold"></i>
      <span class="font-weight-bold text-success">Cadastrar</span>
    </router-link>

    <div v-else class="d-flex flex-column" @click="dialog = true">
      <strong>Realizado</strong>
      <small class="text-secondary">
        Data de criação:
        {{ lastUpdate }}
      </small>
      <small class="text-secondary">{{ item.phone }}</small>
    </div>

    <b-modal v-model="dialog" hide-footer hide-header centered>
      <div class="home-table-cell-char-map-modal p-3 text-center">
        <div class="icon-wrapper mb-4">
          <img src="../../assets/images/send-email.svg" />
        </div>
        <h5 class="mb-3">Enviar relatório</h5>
        <div class="text-secondary mb-4">
          Você tem certeza que deseja enviar o relatório dos mapas dos
          caracteres para {{ item.name }}?
        </div>
        <div class="row">
          <div class="col-lg-6">
            <button
              class="btn btn-block btn-outline-secondary"
              @click="dialog = false"
            >
              Cancelar
            </button>
          </div>
          <div class="col-lg-6">
            <button class="btn btn-block btn-success" @click="dialog = false">
              Enviar relatório
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    lastUpdate() {
      return this.item.last_map_update
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.home-table-cell-char-map-modal {
  .icon-wrapper {
    margin: auto;
    max-width: 100px;
    min-width: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid #212631;
  }
}
</style>
