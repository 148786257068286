<template>
  <button class="btn btn-sm home-table-cell-name d-flex" @click="openProfile">
    <div class="mr-2">
      <img
        v-if="item.image_url"
        class="customer-picture"
        :src="item.image_url"
      />
      <span v-else class="customer-picture"><i class="icon-user"></i></span>
    </div>
    <div class="d-flex flex-column justify-content-start">
      <strong class="text-left">{{ item.name }}</strong>
      <small class="text-secondary text-left">{{ item.email }}</small>
      <small class="text-secondary text-left">{{ item.phone }}</small>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    openProfile() {
      this.$router.push({
        name: "customer-profile",
        params: { ...this.item }
      });
    }
  }
};
</script>
