export default {
  setForm(state, form) {
    state.form = form;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  },
  setVersions(state, versions) {
    state.versions = versions;
  },
  setVersion(state, version) {
    state.version = version;
  }
};
