<template>
  <div class="zone-chart card">
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-6 d-flex justify-content-center text-center">
          <strong>Zona de Dor</strong>
        </div>
        <div class="col-6 d-flex justify-content-center text-center">
          <strong>Zona de Recurso</strong>
        </div>
      </div>
      <div class="chart">
        <div class="bg-red"></div>
        <div class="bg-green"></div>
        <div class="bar" v-for="i in 19" :key="i" :style="barStyle(i)"></div>
      </div>
      <div class="axis">
        <span>0%</span>
        <span v-for="z in zones" :key="z.max" :style="axisStyle(z)"
          >{{ z.max }}%</span
        >
      </div>
      <div class="zones">
        <span
          v-for="z in zones"
          :key="z.text"
          :style="zoneStyle(z)"
          v-html="z.text"
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import MasterKeyTraitsMixin from "@/mixins/MasterKeyTraits";

let barsBefore = 0;

const zones = [
  { min: 0, max: 20, bars: 3, text: "Zona de<br/>Doença" },
  { min: 20, max: 35, bars: 4, text: "Zona de<br/>Sofrimento" },
  { min: 35, max: 50, bars: 4, text: "Zona de<br/>Frustração" },
  { min: 50, max: 65, bars: 3, text: "Zona de<br/>Conflito" },
  { min: 65, max: 80, bars: 3, text: "Zona de<br/>Esforço" },
  { min: 80, max: 100, bars: 2, text: "Zona de<br/>Facilidade" }
].map(zone => {
  barsBefore += zone.bars;
  return {
    ...zone,
    barsBeforeEnd: barsBefore,
    barsBeforeStart: barsBefore - zone.bars
  };
});

const breakpoints = zones.reduce((arr, zone) => {
  for (let i = 1; i <= zone.bars; i++) {
    arr = [...arr, zone.min + (i * (zone.max - zone.min)) / zone.bars];
  }
  return arr;
}, []);

export default {
  mixins: [MasterKeyTraitsMixin],
  props: {
    stage: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      zones
    };
  },
  computed: {
    painSum() {
      return this.painData.reduce((acc, cur) => acc + parseInt(cur.percent), 0);
    },
    resourceSum() {
      return 100 - this.painSum;
    }
  },
  methods: {
    isBarColored(i) {
      return breakpoints[i] <= this.resourceSum;
    },

    barColor() {
      const red = [249, 96, 82];
      const yellow = [255, 211, 42];
      const green = [60, 193, 59];
      const color = [0, 0, 0];
      for (let i in color) {
        this.isBarColored(i);
        if (this.resourceSum < 50) {
          // média ponderada entre o vermelho e o amarelo
          color[i] =
            ((50 - this.resourceSum) * red[i] + this.resourceSum * yellow[i]) /
            50;
        } else {
          // média ponderada entre o amarelo e o verde
          color[i] =
            (this.painSum * yellow[i] + (50 - this.painSum) * green[i]) / 50;
        }
      }
      return `rgb(${color.join(",")})`;
    },

    barStyle(n) {
      const i = n - 1;
      const style = {
        height: `${20 + 9 * i}px`,
        left: `calc(${(100 / 19) * i}% + 2px)`
      };
      if (this.isBarColored(i)) {
        style.background = this.barColor();
      }
      return style;
    },

    axisStyle(z) {
      return {
        left: `${(100 * z.barsBeforeEnd) / 19}%`
      };
    },

    zoneStyle(z) {
      return {
        left: `${(100 * z.barsBeforeStart) / 19}%`,
        right: `${100 - (100 * z.barsBeforeEnd) / 19}%`
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.zone-chart {
  .chart {
    position: relative;
    height: 200px;

    .bg-green,
    .bg-red {
      opacity: 0.5;
      position: absolute;
      top: 0;
      bottom: 0;
    }

    .bg-red {
      background: linear-gradient(
        180deg,
        rgba(249, 116, 104, 0.2) 0%,
        rgba(249, 116, 104, 0) 48.76%
      );
      left: 0;
      right: 42.5%;
    }

    .bg-green {
      background: linear-gradient(
        180deg,
        rgba(175, 228, 175, 0.31) 0%,
        rgba(233, 237, 242, 0) 48.76%,
        rgba(175, 228, 175, 0) 48.76%
      );
      left: 58.5%;
      right: 0;
    }

    .bar {
      position: absolute;
      background: #e9edf2;
      border-radius: 16px 16px 2px 2px;
      width: calc(#{100% / 19} - 4px);
      bottom: 0;
    }
  }

  .axis {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 16px;
    color: #aeb6c4;
    position: relative;

    span {
      position: absolute;
      transform: translateX(-50%);
      font-weight: bold;
    }
  }

  .zones {
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 32px;
    color: #212631;
    position: relative;

    span {
      position: absolute;
      text-align: center;
      font-weight: bold;
    }
  }
}
</style>
