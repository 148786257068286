<template>
  <div class="home-table-cell-master-key">
    <button
      v-if="!item.has_master_facts"
      type="button"
      class="btn btn-sm row align-items-center"
      @click="openMasterKey"
    >
      <i class="icon-plus mr-2 text-success font-weight-bold"></i>
      <span class="font-weight-bold text-success">Cadastrar</span>
    </button>

    <div v-else class="d-flex flex-column">
      <strong>Realizado</strong>
      <small class="text-secondary">{{ lastFactDate }}</small>
      <small class="text-secondary">{{ lastReportSend }}</small>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    lastFactDate() {
      return this.item.last_fact_step_update
        ? `Data de criação: ${moment(this.item.last_fact_step_update).format(
            "DD/MM/YYYY [às] HH:mm"
          )}`
        : "Nenhuma etapa concluída";
    },
    lastReportSend() {
      return this.item.last_master_key_report_send
        ? `Ultimo relatório enviado: ${moment(
            this.item.last_master_key_report_send
          ).format("DD/MM/YYYY [às] HH:mm")}`
        : "Nenhuma etapa concluída";
    }
  },
  methods: {
    openMasterKey() {
      if (!this.item.characters_map) {
        this.$message.error(
          "Primeiro realize o mapa dos caracteres deste cliente"
        );
        return;
      }
      this.$router.push({
        name: "master-key",
        params: { id: this.item.id }
      });
    }
  }
};
</script>
