<template>
  <div class="d-flex flex-column">
    <div class="home-search-filter d-flex align-items-center mb-2">
      <i class="icon-search mr-2"></i>
      <input v-model="form.client" placeholder="Pesquisar por cliente" />
      <span>|</span>
      <b-dropdown
        text="Filtrar"
        ref="dropdown"
        variant="link"
        size="lg"
        toggle-class="text-decoration-none"
      >
        <div v-if="loading" class="text-center p-4">
          <b-spinner variant="primary" />
        </div>

        <form v-else class="px-3 py-2" @submit.prevent="submit">
          <h6>Filtrar clientes</h6>
          <form-group
            v-model="form.country"
            id="country"
            label="PAÍS"
            placeholder="Selecione..."
            select
            :options="countries"
            nullable
          />
          <form-group
            v-model="form.uf"
            id="uf"
            label="UF"
            placeholder="Selecione..."
            select
            :options="states"
            nullable
          />

          <form-group
            v-model="form.city"
            id="city"
            label="CIDADE"
            placeholder="Selecione..."
            select
            :options="cities"
            nullable
          />

          <form-group
            v-model="form.company"
            id="company"
            label="EMPRESA"
            placeholder="Selecione..."
            select
            :options="companies"
            nullable
          />

          <form-group
            v-model="form.sector"
            id="sector"
            label="SETOR"
            placeholder="Selecione..."
            select
            :options="sectors"
            nullable
          />

          <form-group
            v-model="form.sortedBy"
            id="sort"
            label="ORDENAR POR"
            placeholder="Selecione..."
            select
            :options="sortOptions"
            nullable
          />

          <form-group
            v-model="form.order"
            id="order"
            label="ORDEM"
            placeholder="Selecione..."
            select
            :options="orderOptions"
            nullable
          />

          <div class="text-center pt-3">
            <button class="btn btn-outline-success btn-block px-4">
              Aplicar filtros
            </button>
          </div>
        </form>
      </b-dropdown>
    </div>
    <div class="home-search-filter d-flex align-items-center">
      <i class="icon-search mr-2"></i>
      <input v-model="form.analyst" placeholder="Pesquisar por analista" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

let timeout = null;

export default {
  props: {
    placeholder: {
      type: String,
      default: "Pesquisar por cliente"
    },
    params: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: true,
      form: {
        country: "",
        uf: "",
        city: "",
        company: "",
        sector: "",
        order: "",
        sortedBy: "",
        analyst: "",
        client: ""
      },
      orderOptions: [
        { name: "Crescente", id: "Crescente" },
        { name: "Decrescente", id: "Decrescente" }
      ],
      sortOptions: [
        { name: "IQV Inicial", id: "IQV Inicial" },
        { name: "IQV Atual", id: "IQV Atual" },
        { name: "Índice de resultado", id: "Índice de resultado" },
        { name: "Chaves mestras conduzidas", id: "Chaves mestras conduzidas" },
        { name: "Chaves mestras concluídas", id: "Chaves mestras concluídas" },
        {
          name: "Nº de formulários respondidos",
          id: "Nº de formulários respondidos"
        },
        {
          name: "Ordenar por (%) Esquizoide",
          id: "Ordenar por (%) Esquizoide"
        },
        { name: "Ordenar por (%) Oral", id: "Ordenar por (%) Oral" },
        { name: "Ordenar por (%) Psicopata", id: "Ordenar por (%) Psicopata" },
        {
          name: "Ordenar por (%) Masoquista",
          id: "Ordenar por (%) Masoquista"
        },
        { name: "Ordenar por (%) Rígido", id: "Ordenar por (%) Rígido" }
      ],
      MAP_SORT_OPTIONS: {
        "IQV Inicial": "initial_iqv",
        "IQV Atual": "current_iqv",
        "Índice de resultado": "improvement_score",
        "Chaves mestras conduzidas": "master_key_driven_count",
        "Chaves mestras concluídas": "master_key_completed_count",
        "Nº de formulários respondidos": "forms_answered",
        "Ordenar por (%) Esquizoide": "percent_e",
        "Ordenar por (%) Oral": "percent_o",
        "Ordenar por (%) Psicopata": "percent_p",
        "Ordenar por (%) Masoquista": "percent_m",
        "Ordenar por (%) Rígido": "percent_r"
      },
      MAP_ORDER_OPTIONS: {
        Crescente: "asc",
        Decrescente: "desc"
      }
    };
  },
  computed: {
    ...mapState("ranking", [
      "countries",
      "states",
      "cities",
      "companies",
      "sectors"
    ])
  },
  mounted() {
    Promise.all([
      this.$store.dispatch("ranking/getCountries"),
      this.$store.dispatch("ranking/getStates"),
      this.$store.dispatch("ranking/getCities"),
      this.$store.dispatch("ranking/getCompanies"),
      this.$store.dispatch("ranking/getSectors")
    ]).finally(() => {
      this.loading = false;
    });
  },
  watch: {
    params(newParams) {
      for (let key in newParams) {
        this.form[key] = newParams[key];
      }
    },
    customerText() {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.search();
      }, 300);
    }
  },
  methods: {
    submit() {
      this.search();
      this.$refs.dropdown.hide(true);
    },
    search() {
      const transform = {};
      if (this.form.sortedBy) {
        transform["sortedBy"] = this.MAP_SORT_OPTIONS[this.form.sortedBy];
      }

      if (this.form.order) {
        transform["order"] = this.MAP_ORDER_OPTIONS[this.form.order];
      }

      this.$emit("search", {
        ...this.form,
        ...transform
      });
    }
  }
};
</script>

<style lang="scss">
.home-search-filter {
  border: 1px solid #aeb6c4;
  border-radius: 4px;
  padding-left: 12px;
  max-height: 40px;
  min-height: 40px;
  width: 100%;

  i,
  span {
    color: #aeb6c4;
  }

  i {
    font-size: 20px;
  }

  span {
    font-size: 24px;
  }

  input {
    display: flex;
    flex: 1;
    border: none;
    width: 100%;
    background: transparent;
  }

  .dropdown-toggle {
    padding: 0 12px;
    max-height: 40px;
    min-height: 40px;
  }

  .form-control {
    border: 1px solid #e1e1e1;
  }

  select {
    width: 200px;
  }
}
</style>
