<template>
  <div :id="id" class="iqv-form-question card">
    <div class="card-body">
      <strong class="d-block mb-4">
        {{ title }}
        <span v-if="mandatory" class="text-danger">*</span>
      </strong>

      <div v-if="type === 'short_answer'" class="short-answer">
        <input v-model="other" class="form-control" />
      </div>

      <div v-if="type === 'linear_scale'" class="linear-scale">
        <div class="d-flex mb-2 flex-wrap">
          <button
            v-for="i in max"
            :key="i"
            class="btn"
            :class="{
              'btn-outline-dark': !value || value.selected_scalar !== i,
              'btn-success': value && value.selected_scalar === i
            }"
            @click="selectScalar(i)"
          >
            {{ i }}
          </button>
        </div>
        <div class="d-flex min-max-text">
          <small>{{ minText }}</small>
          <div class="flex-fill"></div>
          <small>{{ maxText }}</small>
        </div>
      </div>

      <div v-if="type === 'single'" class="single">
        <div
          class="d-flex align-items-center py-2"
          v-for="(alternative, index) in alternatives"
          :key="index"
        >
          <button
            class="btn"
            :class="{
              'btn-outline-dark':
                !value || value.selected_alternative !== alternative.id,
              'btn-outline-success':
                value && value.selected_alternative === alternative.id
            }"
            @click="clickSingle(alternative.id)"
          >
            <span
              v-if="value && value.selected_alternative === alternative.id"
              class="ball"
            ></span>
          </button>
          {{ alternative.option }}
        </div>

        <div class="d-flex align-items-center py-2" v-if="hasOther">
          <button
            class="btn"
            :class="{
              'btn-outline-dark': !otherSelected,
              'btn-outline-success': otherSelected
            }"
            @click="clickOtherSingle"
          >
            <span v-if="otherSelected" class="ball"></span>
          </button>
          Outro:
          <input v-model="other" class="form-control" />
        </div>
      </div>

      <div v-if="type === 'multiple'" class="multiple">
        <div
          class="d-flex align-items-center py-2"
          v-for="(alternative, index) in alternatives"
          :key="index"
        >
          <button
            class="btn"
            :class="{
              'btn-outline-dark':
                !value || !value.selected_alternatives.includes(alternative.id),
              'btn-success':
                value && value.selected_alternatives.includes(alternative.id)
            }"
            @click="clickMultiple(alternative.id)"
          >
            <i
              v-if="
                value && value.selected_alternatives.includes(alternative.id)
              "
              class="icon-check"
            ></i>
          </button>
          {{ alternative.option }}
        </div>

        <div class="d-flex align-items-center py-2" v-if="hasOther">
          <button
            class="btn"
            :class="{
              'btn-outline-dark': !otherSelected,
              'btn-success': otherSelected
            }"
            @click="clickOtherMultiple"
          >
            <i v-if="otherSelected" class="icon-check"></i>
          </button>
          Outro:
          <input v-model="other" class="form-control" />
        </div>
      </div>

      <div v-if="isShowingError" class="d-flex pt-3">
        <div
          class="error-message bg-danger text-light d-flex align-items-center"
        >
          <i class="icon-alert-circle mr-2"></i>
          <small>
            Esta pergunta é obrigatória
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    minText: {
      type: String,
      default: ""
    },
    maxText: {
      type: String,
      default: ""
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
    value: {
      type: [Object, Array],
      default: null
    },
    type: {
      type: String,
      default: "linear_scale"
    },
    alternatives: {
      type: Array,
      default: () => []
    },
    hasOther: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    showError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      other: "",
      otherSelected: false
    };
  },
  computed: {
    isShowingError() {
      if (!this.showError || !this.mandatory) return false;

      if (this.type === "multiple") {
        return (
          !get(this.value, "other_text.length", 0) &&
          !get(this.value, "selected_alternatives.length", 0)
        );
      }

      if (this.type === "single") {
        return (
          !get(this.value, "other_text.length", 0) &&
          !get(this.value, "selected_alternative", 0)
        );
      }

      return !this.value;
    }
  },
  watch: {
    other(text) {
      if (this.type === "short_answer" && !text.length) {
        this.$emit("input", null);
        return;
      }

      let pastNewValue = {};
      if (this.otherSelected) {
        pastNewValue = { ...this.value, other_text: text };
      }
      if (this.type === "short_answer") {
        pastNewValue = { answer: parseFloat(`${text}`.replace(",", ".")) };
      }
      this.$emit("input", {
        question_id: this.id,
        ...pastNewValue
      });
    }
  },
  methods: {
    // linear_scale
    selectScalar(i) {
      this.$emit("input", {
        question_id: this.id,
        selected_scalar: i
      });
    },

    // multiple
    clickMultiple(id) {
      if (!this.value || !this.value.selected_alternatives) {
        this.selectAlternatives([id]);
      } else if (
        this.value.selected_alternatives &&
        Array.isArray(this.value.selected_alternatives) &&
        this.value.selected_alternatives.includes(id)
      ) {
        this.selectAlternatives(
          this.value.selected_alternatives.filter(item => item != id)
        );
      } else {
        this.selectAlternatives([...this.value.selected_alternatives, id]);
      }
    },
    clickOtherMultiple() {
      this.otherSelected = !this.otherSelected;
      this.selectAlternatives(get(this.value, "selected_alternatives", []));
    },
    selectAlternatives(alternatives) {
      if (!alternatives.length) {
        this.$emit("input", null);
        return;
      }

      const data = {
        question_id: this.id,
        selected_alternatives: alternatives
      };
      if (this.otherSelected) {
        data.other_text = this.other;
      }

      this.$emit("input", data);
    },

    // single
    clickSingle(id) {
      this.otherSelected = false;
      this.$emit("input", {
        question_id: this.id,
        selected_alternative: id
      });
    },
    clickOtherSingle(id) {
      this.otherSelected = true;
      this.$emit("input", {
        question_id: this.id,
        other_text: this.other
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.iqv-form-question {
  margin-bottom: 40px;

  .linear-scale {
    margin: -3px -6px;

    button {
      max-width: 32px;
      min-width: 32px;
      max-height: 32px;
      min-height: 32px;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3px 6px;
    }

    small {
      color: #aeb6c4;
    }

    .min-max-text {
      max-width: 428px;
      margin: 3px 6px;
    }
  }

  .multiple {
    button {
      max-width: 28px;
      min-width: 28px;
      max-height: 28px;
      min-height: 28px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      i {
        font-size: 20px;
      }
    }
  }

  .single {
    button {
      max-width: 28px;
      min-width: 28px;
      max-height: 28px;
      min-height: 28px;
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;

      .ball {
        max-width: 14px;
        min-width: 14px;
        max-height: 14px;
        min-height: 14px;
        border-radius: 14px;
        background: #3cc13b;
      }
    }
  }

  .form-control {
    max-height: 28px;
    min-height: 28px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #212631;

    &:focus {
      box-shadow: none;
    }
  }

  .error-message {
    padding: 8px 16px;
    border-radius: 8px;
  }
}
</style>
