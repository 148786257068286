<template>
  <b-spinner></b-spinner>
</template>

<script>
export default {
  mounted() {
    if (this.$user.user.user_type === "admin")
      this.$router.replace({ name: "admin-analysts" });
    else this.$router.replace({ name: "customer" });
  }
};
</script>
