<template>
  <div class="master-key-stage-selector card">
    <div class="card-body d-flex" :class="{ empty: !currentStep }">
      <b-dropdown
        :text="placeholder"
        variant="link"
        class="dropdown dropdown-toggle"
        toggle-class="text-decoration-none"
      >
        <b-dropdown-item
          v-for="(step, index) in currentFact.steps"
          :key="`step-${index}`"
          @click="() => onSelect(step)"
        >
          Etapa {{ step.step }} de 5
        </b-dropdown-item>
      </b-dropdown>

      <button
        class="btn mx-3"
        :class="{
          'btn-outline-danger': !!currentStep,
          'btn-outline-secondary': !currentStep
        }"
        :disabled="!currentStep"
        @click="deleteStep"
      >
        <b-spinner v-if="loading" />
        <span v-if="!loading">Excluir esta etapa</span>
      </button>
      <button
        v-if="currentFact.steps.length < 5"
        class="btn btn-outline-success"
        @click="newStep"
      >
        Nova etapa
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.$store.commit("masterKey/setCurrentStep", null); // for clear store if user create new and exit
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("masterKey", ["currentFact", "currentStep"]),
    placeholder() {
      if (this.currentStep) {
        return `Etapa ${this.currentStep.step}`;
      } else {
        return "Selecione uma etapa...";
      }
    }
  },
  methods: {
    newStep() {
      const last = this.currentFact.steps[this.currentFact.steps.length - 1];
      const unPublished = this.currentFact.steps.filter(
        step => !step.action_character
      );

      if (unPublished.length > 0) {
        this.$message.error("Conclua esta etapa antes de criar uma nova");
        return;
      }

      const newStep = {
        action: "",
        characters: ["E", "O", "P", "M", "R"].map((character, index) => {
          if (last) {
            return {
              ...last.characters[index]
            };
          }
          return { character };
        }),
        notes: "",
        step: this.currentFact.steps.length + 1
      };
      if (this.currentStep) {
        this.$store.commit("masterKey/setLastAction", this.currentStep);
      }
      this.$store.commit("masterKey/setCurrentFact", {
        ...this.currentFact,
        steps: [...this.currentFact.steps, newStep]
      });

      this.$store.commit("masterKey/setCurrentStep", newStep);
    },
    onSelect(step) {
      this.$store.commit("masterKey/setCurrentStep", step);
    },
    deleteStep() {
      if (!this.currentStep) return;

      const steps = this.currentFact.steps.filter(
        s => s.step !== this.currentStep.step
      );

      if (!this.currentStep.action_character) {
        this.$store.commit("masterKey/setCurrentFact", {
          ...this.currentFact,
          steps
        });
        if (this.currentFact.steps.length > 0) {
          this.$store.commit("masterKey/setCurrentStep", {
            ...this.currentFact.steps[this.currentFact.steps.length - 1]
          });
        } else {
          this.$store.commit("masterKey/setCurrentStep", null);
        }
        return;
      }

      this.loading = true;
      this.$store
        .dispatch("masterKey/deleteStep", {
          customer_id: this.customer.id,
          fact_Id: this.currentFact.id,
          step_number: this.currentStep.step
        })
        .then(() => {
          this.$store.commit("masterKey/setCurrentFact", {
            ...this.currentFact,
            steps
          });
          const nextStep = this.currentFact.steps[this.currentStep.step - 1]
            ? this.currentFact.steps[this.currentStep.step - 1]
            : null;
          this.$store.commit("masterKey/setCurrentStep", nextStep);
        })
        .catch(error =>
          this.$message.error("Erro ao deletar um passo da chave mestra")
        )
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.master-key-stage-selector {
  .form-control {
    width: auto;
  }

  button {
    white-space: nowrap;
  }

  .dropdown {
    flex: 1 1 auto;
  }

  .dropdown-toggle {
    background: #ffffff;
    color: #212631;
    border: 1px solid #e9edf2;
    border-radius: 4px;
    text-align: left;
    position: relative;

    &::after {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .empty {
    .dropdown-toggle {
      color: #aeb6c4;
    }
  }
}
</style>
