<template>
  <div class="search-select form-group" :class="{ 'has-value': !!value }">
    <label>{{ label }}</label>
    <b-dropdown
      class="form-control"
      :class="{ 'is-invalid': showError }"
      variant="link"
      toggle-class="text-decoration-none"
      :text="displayText"
    >
      <b-dropdown-form>
        <input
          class="border-0 px-4"
          v-model="term"
          placeholder="Pesquisar..."
          @input="search"
        />
      </b-dropdown-form>

      <b-dropdown-divider />

      <div class="scroll">
        <div v-if="loading" class="px-4 py-2 text-center">
          <b-spinner variant="primary" />
        </div>

        <div
          v-else-if="options.length === 0"
          class="px-4 py-2 text-center small text-secondary"
        >
          <em>Nenhum registro encontrado</em>
        </div>

        <b-dropdown-item
          v-else
          v-for="(option, index) in options"
          :key="index"
          @click="onClickItem(option)"
        >
          {{ option.name }}
        </b-dropdown-item>
      </div>
    </b-dropdown>

    <div v-if="showError" class="invalid-feedback">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
let timeout = null;

export default {
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: [Object, String],
      default: null
    },
    errors: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: "analysts"
    }
  },
  data() {
    return {
      term: "",
      options: [],
      loading: false,
      showError: this.errors.length
    };
  },
  computed: {
    displayText() {
      return this.value ? this.value.name : this.placeholder;
    }
  },
  watch: {
    errors(newVal) {
      if (newVal && !!newVal[0]) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    search() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(this.load, 300);
    },
    load() {
      this.loading = true;
      window.axios
        .get(`/${this.url}?search=${this.term}`)
        .then(response => {
          this.options = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onClickItem(option) {
      this.showError = false;
      this.$emit("input", option);
    }
  }
};
</script>

<style lang="scss" scoped>
.search-select {
  /deep/ .dropdown-toggle {
    background: transparent;
    padding: 0;
    border: none;
    max-height: 34px;
    min-height: 34px;
    text-align: left;
    font-weight: normal;
    color: #aeb6c4;

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &.has-value {
    /deep/ .dropdown-toggle {
      color: #495057;
    }
  }

  /deep/ .dropdown-item {
    font-size: 14px;
    color: #495057;
  }

  .scroll {
    overflow-y: scroll;
    max-height: 300px;
  }
}
</style>
