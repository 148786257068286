<template>
  <div class="view-master-key-data mb-4">
    <!-- personal info end -->
    <div class="d-flex align-items-center flex-column flex-lg-row">
      <img class="img-fluid" src="@/assets/images/profile-key-icon.svg" />
      <div
        class="small-mobile text-center text-lg-left px-lg-5 py-3 py-lg-0 flex-fill"
      >
        <div v-if="!customer.master_key_driven_count" class="text-dark">
          Nenhum fato criado ainda
        </div>
        <div v-if="customer.master_key_driven_count" class="text-dark">
          {{ lastReportInfo }}
        </div>
        <div v-if="customer.master_key_driven_count" class="text-dark">
          {{ lastStepInfo }}
        </div>
      </div>
      <button
        type="button"
        class="btn btn-outline-success px-lg-5"
        @click="openMasterKey"
      >
        <span v-if="customer.master_key_driven_count">Visualizar</span>
        <span v-if="!customer.master_key_driven_count">Nova Chave Mestra</span>
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      shareReportConfirmShow: false
    };
  },
  computed: {
    lastStepInfo() {
      if (!this.customer || !this.customer.last_fact_step_update) {
        return "Nenhum passo completo até o momento";
      }

      return moment(this.customer.last_fact_step_update).format(
        "[Último passo completo:] DD/MM/YYYY [ás] hh:mm"
      );
    },
    lastReportInfo() {
      if (!this.customer || !this.customer.last_master_key_report_send) {
        return "Relatório ainda não enviado para o cliente.";
      }

      return moment(this.customer.last_master_key_report_send).format(
        "[Último relatório enviado:] DD/MM/YYYY [ás] hh:mm"
      );
    }
  },
  methods: {
    openMasterKey() {
      if (!this.customer.characters_map) {
        this.$message.error(
          "Primeiro realize o mapa dos caracteres deste cliente"
        );
        return;
      }
      this.$router.push({
        name: "master-key",
        params: { id: this.customer.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.view-master-key-data {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(104, 0, 223, 0.05);
  border-radius: 8px;
  padding: 24px;

  .small-mobile {
    @media screen and (max-width: 991px) {
      font-size: 14px;
    }
  }
}
</style>
