export default {
  setYear(state, year) {
    state.year = year;
  },
  setMonth(state, month) {
    state.month = month;
  },
  setSchedules(state, schedules) {
    state.schedules = schedules;
  },
  setDaySchedules(state, daySchedules) {
    state.daySchedules = daySchedules;
  },
  setCurrentDay(state, currentDay) {
    state.currentDay = currentDay;
  },
  setPagination(state, pagination) {
    state.pagination = pagination;
  }
};
