export default {
  setFacts(state, facts) {
    state.facts = facts;
  },
  setCurrentFact(state, fact) {
    state.currentFact = fact;
  },
  setCurrentStep(state, step) {
    state.currentStep = step;
  },
  setTraitsPercent(state, traitsPercent) {
    state.traitsPercent = traitsPercent;
  },
  setTraitIndicator(state, traitIndicator) {
    state.traitIndicator = traitIndicator;
  },
  setLastAction(state, lastAction) {
    state.lastAction = lastAction;
  }
};
