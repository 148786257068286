export default {
  getFacts(context, { customer_id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(`/clients/${customer_id}/keys`)
        .then(response => {
          context.commit("setFacts", response.data.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  createFact(context, { customer_id, name }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${customer_id}/keys`, { name })
        .then(resolve)
        .catch(reject);
    });
  },
  updateFactName(context, { customer_id, fact_id, name }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${customer_id}/keys/${fact_id}`, { name })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteFact(context, { customer_id, fact_Id }) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/clients/${customer_id}/keys/${fact_Id}`)
        .then(resolve)
        .catch(reject);
    });
  },
  publishStep(context, { customer_id, fact_Id, step }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${customer_id}/keys/${fact_Id}/steps`, { step })
        .then(resolve)
        .catch(reject);
    });
  },
  deleteStep(context, { customer_id, fact_Id, step_number }) {
    return new Promise((resolve, reject) => {
      window.axios
        .delete(`/clients/${customer_id}/keys/${fact_Id}/steps/${step_number}`)
        .then(resolve)
        .catch(reject);
    });
  },
  downloadPdf(context, { customer_id, fact_Id, steps }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(
          `/clients/${customer_id}/keys/${fact_Id}/report`,
          { steps },
          { responseType: "blob" }
        )
        .then(resolve)
        .catch(reject);
    });
  },
  sendReportToAnalyst(context, { customer_id, fact_Id, steps }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${customer_id}/keys/${fact_Id}/report/analyst`, {
          steps
        })
        .then(resolve)
        .catch(reject);
    });
  },
  sendReportToCustomer(context, { customer_id, fact_Id, steps }) {
    return new Promise((resolve, reject) => {
      window.axios
        .post(`/clients/${customer_id}/keys/${fact_Id}/report/client`, {
          steps
        })
        .then(resolve)
        .catch(reject);
    });
  }
};
