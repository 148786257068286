<template>
  <div class="image-input form-group">
    <button type="button" class="btn btn-sm" @click="openImgSelectBox">
      <div class="position-relative text-dark row pt-2">
        <div class="col-4 mb-2">
          <div
            v-if="!innerValue || innerValue === ''"
            class="placeholder rounded-circle"
          >
            <img src="@/assets/images/pencil.svg" />
          </div>
          <img v-else class="image img-fluid" :src="innerValue" />
        </div>
        <div class="col-8 pl-4 d-flex flex-column justify-content-center">
          <label :for="id">{{ label }}</label>
          <span class="font-weight-bold text-success">Enviar foto</span>
          <input
            id="img-input-file"
            type="file"
            @change="inputImgFileChanged"
          />
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { toBase64 } from "@/functions/helpers";

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      innerValue: this.value
    };
  },
  watch: {
    innerValue(newVal) {
      if (newVal != this.value) {
        this.$emit("input", newVal);
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
      }
    }
  },
  methods: {
    openImgSelectBox() {
      document.getElementById("img-input-file").click();
    },
    async inputImgFileChanged(event) {
      if (event.target.files && event.target.files.length) {
        const arrFiles = Array.from(event.target.files);
        const files = arrFiles.map((file, index) => {
          const src = window.URL.createObjectURL(file);
          return { file, src };
        });

        try {
          const base64Img = await toBase64(files[0].file);
          this.$emit("input", `${base64Img}`);
        } catch (error) {
          this.$message(error);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.image-input {
  .placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    background-color: #e9edf2;
  }

  .image {
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    border-radius: 80px;
    object-fit: cover;
    box-shadow: 0 0 16px rgba(100, 100, 100, 0.1);
  }

  input {
    position: absolute;
    visibility: hidden;
  }
}
</style>
