<template>
  <div class="view-change-password row">
    <div class="col-lg-8">
      <h5 class="mb-4">Alterar senha</h5>

      <form @submit.prevent="submit">
        <form-group
          v-model="form.old_password"
          id="password"
          type="password"
          label="SENHA ATUAL"
          placeholder="Digite sua senha atual"
          :errors="errors.password"
        />
        <form-group
          v-model="form.new_password"
          id="new_password"
          type="password"
          label="NOVA SENHA"
          placeholder="Digite sua nova senha"
          :errors="errors.new_password"
        />
        <form-group
          v-model="form.confirm_new_password"
          id="confirm_password"
          type="password"
          label="CONFIRMAR SENHA"
          placeholder="Confirme sua nova senha"
          :errors="errors.confirm_new_password"
        />

        <div class="row justify-content-between pt-5">
          <div class="col-md-4">
            <button
              type="button"
              class="btn btn-outline-secondary btn-block"
              @click="$router.go(-1)"
            >
              Cancelar
            </button>
          </div>
          <div class="col-md-4">
            <hollow-button
              class=""
              title="Salvar"
              type="submit"
              :loading="loading"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { formFields } from "@/functions/helpers";

export default {
  name: "ChangePassword",
  data() {
    return {
      loading: false,
      ...formFields([
        "password",
        "old_password",
        "new_password",
        "confirm_new_password"
      ])
    };
  },
  methods: {
    resetErrors() {
      for (let i in this.errors) this.errors[i] = [];
    },
    submit() {
      this.resetErrors();

      if (this.form.new_password !== this.form.confirm_new_password) {
        this.errors.confirm_new_password = ["Senhas digitadas são diferentes"];
        return;
      }

      this.loading = true;

      this.$store
        .dispatch("user/changePassword", { ...this.form })
        .then(() => {
          this.$message.success("Senha alterada com sucesso");
          for (let i in this.form) this.form[i] = "";
        })
        .catch(error => {
          this.errors = error.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
