<template>
  <form class="admins-form p-3" @submit.prevent="submit">
    <h5 class="text-center mb-4">
      <span v-if="user">Atualizar o administrador</span>
      <span v-else>Cadastrar o administrador</span>
    </h5>

    <form-group
      v-model="form.name"
      :errors="errors.name"
      id="name"
      label="NOME COMPLETO"
      placeholder="Digite o nome do usuário. Ex: João Silva"
    />

    <form-group
      v-model="form.email"
      :errors="errors.email"
      id="email"
      type="email"
      label="E-MAIL"
      placeholder="Digite o e-mail do usuário. Ex: joaosilva@gmail.com"
    />

    <div class="row pt-4">
      <div class="col-6">
        <button
          type="button"
          class="btn btn-outline-secondary btn-block"
          @click="$emit('close')"
        >
          Cancelar
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-success btn-block">
          <loading :show="loading">
            <span v-if="user">Salvar</span>
            <span v-else>Cadastrar</span>
          </loading>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions/helpers";

export default {
  props: {
    user: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      loading: false,
      ...formFields(["name", "email"], this.user)
    };
  },
  methods: {
    resetErrors() {
      for (let i in this.errors) this.errors[i] = [];
    },
    submit() {
      this.loading = true;
      this.resetErrors();

      let action, data;
      if (this.user) {
        action = "admins/update";
        data = { ...this.user, ...this.form };
      } else {
        action = "admins/create";
        data = this.form;
      }
      this.$store
        .dispatch(action, data)
        .then(() => {
          this.$message.success("Salvo com sucesso");
          this.$emit("saved");
        })
        .catch(error => {
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          } else {
            this.$message.error(error.response.data.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
