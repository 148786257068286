export default {
  setUser(state, user) {
    if (state.user) {
      for (let i in user) {
        state.user[i] = user[i];
      }
    } else {
      state.user = user;
    }
  },
  updateUser(state, data) {
    for (const i in data) {
      state.user[i] = data[i];
    }
  },
  setLogged(state, logged) {
    state.logged = logged;
  },

  reset(state) {
    state.user = null;
    state.logged = false;
  }
};
