<template>
  <div class="import-modal p-3">
    <button class="btn btn-close" @click="$emit('close')">
      <i class="icon-x"></i>
    </button>

    <input
      id="input-file"
      type="file"
      @change="inputImgFileChanged"
      accept=".csv"
    />

    <div v-if="step === 1" class="text-center">
      <img
        class="img-fluid d-block mx-auto mb-4"
        src="../../assets/images/upload.svg"
      />
      <h5 class="mb-3">Importar analistas</h5>
      <p class="mb-4 text-secondary">
        Importar analistas em massa através de uma planilha. Baixe o exemplo
        abaixo, preencha a planilha com os dados do analista e depois clique em
        <strong class="text-dark">Importar arquivo</strong>.
      </p>
      <div class="row">
        <div class="col-6">
          <a
            href="/files/exemplo_cadastro_analista.csv"
            class="btn btn-outline-success btn-block"
          >
            <i class="icon-download"></i> Baixar exemplo
          </a>
        </div>
        <div class="col-6">
          <button class="btn btn-success btn-block" @click="openSelectBox">
            <loading :show="loading">
              <i class="icon-download"></i> Importar arquivo
            </loading>
          </button>
        </div>
      </div>
    </div>

    <template v-if="step === 2">
      <h5 class="mb-3">Planilha importada com sucesso</h5>
      <p class="text-secondary mb-3">
        Revise os dados dos analistas antes de importá-los para o sistema.
      </p>
      <hr />
      <div class="table-responsive">
        <table class="table table-borderless table-hover table-sm">
          <thead>
            <tr>
              <th></th>
              <th v-for="field in fields" :key="field.label">
                {{ field.label }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in data"
              :key="index"
              :class="{ 'has-error': itemHasErrors(item) }"
            >
              <td>
                <b-checkbox
                  v-model="selected[index]"
                  :disabled="itemHasErrors(item)"
                />
              </td>
              <td
                v-for="field in fields"
                :key="field.key"
                :class="{ 'text-danger': item.errors[field.key] }"
                v-tooltip="
                  item.errors[field.key] ? item.errors[field.key][0] : false
                "
              >
                <span v-if="field.formatter" v-html="field.formatter(item)" />
                <span v-else>{{ item[field.key] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex d-lg-block text-right pt-4">
        <button
          class="btn btn-outline-secondary px-lg-5 mr-3"
          @click="$emit('close')"
        >
          Cancelar
        </button>
        <div class="flex-fill"></div>
        <button
          class="btn btn-success"
          @click="confirmImport"
          :disabled="disabled"
        >
          <loading :show="loading">
            Confirmar e importar
          </loading>
        </button>
      </div>
    </template>

    <div v-if="step === 3" class="text-center">
      <img
        class="d-block mb-4 mx-auto"
        src="../../assets/images/success-icon.svg"
      />

      <h5 class="mb-3">Importação concluída!</h5>

      <p class="text-secondary mb-5">
        Sua planilha foi importada com sucesso! O que deseja fazer agora?
      </p>

      <div class="row">
        <div class="col-6">
          <button
            class="btn btn-outline-secondary btn-block"
            @click="$emit('close')"
          >
            Fechar
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-success btn-block" @click="openSelectBox">
            <loading :show="loading">
              <i class="icon-download"></i> Importar nova
            </loading>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get";

export default {
  data() {
    return {
      loading: false,
      step: 1,
      data: [],
      selected: {},
      fields: [
        { label: "Nome do analista", key: "name" },
        { label: "E-mail", key: "email" },
        { label: "Telefone", key: "phone" },
        { label: "CPF", key: "cpf" },
        { label: "País", key: "country" },
        { label: "Cidade", key: "city" },
        { label: "UF", key: "uf" },
        {
          label: "Analista interno",
          key: "internal_analyst",
          formatter: item => (item.internal_analyst ? "Sim" : "Não")
        }
      ]
    };
  },
  computed: {
    disabled() {
      return !Object.values(this.selected).find(v => v);
    }
  },
  methods: {
    openSelectBox() {
      document.getElementById("input-file").click();
    },
    async inputImgFileChanged(event) {
      const file = get(event, "target.files[0]");

      if (!file) return;

      this.loading = true;

      let fileText = await file.text();

      fileText = fileText
        .split("\n")
        .slice(1)
        .map(line => {
          const elements = line.split(",");
          elements[3] = elements[3].replace(/\D/g, ""); // cpf
          elements[7] = elements[7] && elements[7].toLowerCase() === "sim"; // internal
          return elements.join(",");
        })
        .join("\n");

      const blob = new Blob([fileText], {
        type: "text/plain;charset=utf-8"
      });

      this.$store
        .dispatch("analysts/upload", blob)
        .then(response => {
          this.data = response.data.data;
          for (let i in this.data) {
            this.$set(this.selected, i, false);
          }
          this.step = 2;
          this.$emit("size", "xl");
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    itemHasErrors(item) {
      return Object.values(item.errors).length > 0;
    },
    confirmImport() {
      this.loading = true;

      const values = [];
      this.data.forEach((item, index) => {
        if (this.selected[index]) {
          values.push(item);
        }
      });

      this.$store
        .dispatch("analysts/storeBatch", { values })
        .then(() => {
          this.step = 3;
          this.$emit("saved");
          this.$emit("size", "md");
        })
        .catch(error => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.import-modal {
  position: relative;

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 24px;
  }

  #input-file {
    height: 1px;
    width: 1px;
    opacity: 0.01;
    position: absolute;
  }

  table {
    font-size: 12px;
    line-height: 14px;

    th {
      color: #aeb6c4;
      padding-bottom: 12px;
    }

    td {
      vertical-align: middle;
    }

    .has-error {
      background-color: rgba(249, 96, 82, 0.05);

      td {
        background-color: rgba(249, 96, 82, 0.05);
      }

      &:hover {
        background-color: rgba(249, 96, 82, 0.1);

        td {
          background-color: rgba(249, 96, 82, 0.1);
        }
      }

      /deep/ .custom-checkbox {
        opacity: 0.5;
      }
    }
  }

  .btn-success {
    min-width: 150px;
  }
}
</style>
