<template>
  <form class="customer-step-2" @submit.prevent="submit">
    <div class="row justify-content-between">
      <div class="col-lg-6">
        <form-group
          id="nacionalityAddress"
          label="NACIONALIDADE DO ENDEREÇO"
          type="radio"
          v-model="form.nacionalityAddress"
          :isRadioGroup="true"
          :options="[{ name: 'Brasileiro' }, { name: 'Endereço Estrangeiro' }]"
          :errors="theErrors.nacionalityAddress"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="zip_code"
          type="zip_code"
          placeholder="Ex: 05.409-000"
          v-model="form.zip_code"
          :label="
            form.nacionalityAddress === 'Brasileiro' ? 'CEP*' : 'ZIP CODE*'
          "
          :mask="form.nacionalityAddress === 'Brasileiro' ? '#####-###' : ''"
          :errors="theErrors.zip_code"
        />
      </div>
    </div>
    <!-- if the user check nacionalityAddress to out of Brazil, show 2 fields to address -->
    <div v-if="form.nacionalityAddress !== 'Brasileiro'">
      <form-group
        id="address"
        type="address"
        label="ENDEREÇO LINHA 1"
        placeholder="ex: Nome da rua, da companhia ou outro"
        v-model="form.foreign_address"
        :errors="theErrors.foreign_address"
      />
      <form-group
        id="address"
        type="address"
        label="ENDEREÇO LINHA 2"
        placeholder="ex: Apartamento, suite, prédio, andar"
        v-model="form.foreign_address_2"
        :errors="theErrors.foreign_address_2"
      />
    </div>

    <div
      class="row justify-content-between"
      v-if="form.nacionalityAddress === 'Brasileiro'"
    >
      <div class="col-lg-9">
        <form-group
          id="address"
          type="address"
          label="ENDEREÇO"
          placeholder="Digite o endereço completo. Ex: R. Capote Valente"
          v-model="form.address"
          :errors="theErrors.address"
        />
      </div>
      <div class="col-lg-3">
        <form-group
          id="address_number"
          type="address_number"
          label="NÚMERO"
          placeholder="Ex: 100 ou S/N"
          v-model="form.address_number"
          :errors="theErrors.address_number"
        />
      </div>
    </div>

    <div
      class="row justify-content-between"
      v-if="form.nacionalityAddress === 'Brasileiro'"
    >
      <div class="col-lg-6">
        <form-group
          id="address_complement"
          type="address_complement"
          label="COMPLEMENTO"
          placeholder="Ex: 30 andar"
          v-model="form.address_complement"
          :errors="theErrors.address_complement"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="neighborhood"
          type="neighborhood"
          label="BAIRRO"
          placeholder="Ex: Jardins"
          v-model="form.neighborhood"
          :errors="theErrors.neighborhood"
        />
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-lg-6">
        <form-group
          id="country"
          label="PAÍS"
          placeholder="Clique para selecionar o país*"
          v-model="form.country"
          :select="true"
          :options="countryOptions"
          :errors="theErrors.country"
        />
      </div>
      <div class="col-lg-6">
        <form-group
          id="uf"
          label="UF*"
          placeholder="Clique para selecionar o estado"
          v-model="form.uf"
          :select="isBrasil"
          :options="stateOptions"
          :errors="theErrors.uf"
        />
      </div>
    </div>

    <form-group
      v-model="form.city"
      id="city"
      label="CIDADE*"
      placeholder="Clique para selecionar a cidade"
      :select="isBrasil"
      :options="cityOptions"
      :errors="theErrors.city"
    />

    <div class="row justify-content-between pt-5">
      <div class="col-5 col-lg-3">
        <hollow-button
          styleType="cancel"
          title="Voltar"
          type="button"
          @click="$emit('back')"
        />
      </div>
      <div class="col-5 col-lg-3">
        <hollow-button title="Salvar" type="submit" :loading="loading" />
      </div>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions/helpers";
import cep from "cep-promise";
import AddressForm from "@/mixins/AddressForm";

export default {
  mixins: [AddressForm],
  props: {
    formData: {
      type: Object,
      default: null
    },
    formErrors: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ...formFields(
        [
          "nacionalityAddress",
          "zip_code",
          "foreign_address",
          "foreign_address_2",
          "address",
          "address_number",
          "address_complement",
          "neighborhood",
          "country",
          "uf",
          "city"
        ],
        {
          nacionalityAddress: "Brasileiro",
          country: "Brasil",
          ...this.formData
        }
      )
    };
  },
  computed: {
    theErrors() {
      const result = { ...this.errors };
      const formErrors = this.formErrors ? this.formErrors : {};
      for (let i in formErrors) {
        if (result[i]) {
          result[i] = [...result[i], ...formErrors[i]];
        }
      }
      return result;
    }
  },
  watch: {
    "form.nacionalityAddress"(newVal) {
      if (newVal === "Brasileiro") {
        this.form = {
          ...this.form,
          country: "Brasil",
          foreign_address: null,
          foreign_address_2: null
        };
      } else {
        this.form = {
          ...this.form,
          country: "",
          state: "",
          city: "",
          address: null,
          address_complement: null,
          address_number: null
        };
      }
    },
    "form.zip_code"(newVal) {
      if (newVal.length === 8) {
        cep(newVal).then(({ city, neighborhood, state: uf, street }) => {
          const isBrazilianAddress =
            this.form.nacionalityAddress === "Brasileiro";
          this.form = {
            ...this.form,
            city,
            neighborhood,
            uf,
            address: isBrazilianAddress ? street : null,
            foreign_address: isBrazilianAddress ? null : street
          };
        });
      }
    }
  },
  mounted() {
    /* mixin functions */
    this.loadCountries();
    this.loadStatesAndCities(brazilianStates => {
      const { country, uf } = this.form;
      if (country === "Brasil" && uf && uf.length) {
        const state = brazilianStates.states.find(st => st.uf === uf);
        this.cityOptions = state.cities.map(ct => ({ name: ct, id: ct }));
      }
    });
    /* mixin functions */
  },
  methods: {
    reset() {
      for (let i in this.form) {
        this.form[i] = "";
      }
      this.nacionalityAddress = "Brasileiro";
      this.country = "Brasil";
    },
    submit() {
      if (this.validateFields()) {
        this.$emit("submit", this.form);
      }
    },
    validateFields() {
      const required = ["zip_code", "country", "uf", "city"];

      let valid = true;

      for (let i in required) {
        let key = required[i];
        if (!this.form[key]) {
          this.errors[key] = ["Este campo não pode ficar em branco"];
          valid = false;
        }
      }

      if (
        this.form.nacionalityAddress === "Endereço Estrangeiro" &&
        !this.form.foreign_address
      ) {
        this.errors.foreign_address = [
          "Digite pelo menos um endereço estrangeiro, neste campo"
        ];
        valid = false;
      }

      if (this.form.nacionalityAddress === "Brasileiro" && !this.form.address) {
        this.errors.address = ["Este campo não pode ficar em branco"];
        valid = false;
      }

      if (
        this.form.nacionalityAddress === "Brasileiro" &&
        !this.form.address_number
      ) {
        this.errors.address_number = ["Digite o número"];
        valid = false;
      }

      if (!valid) {
        this.$emit("show-warning");
      }

      return valid;
    }
  }
};
</script>
