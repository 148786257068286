<template>
  <div class="iqv-edit-question-alternatives">
    <draggable
      tag="ul"
      :list="alternatives"
      class="list-group"
      handle=".handle"
    >
      <li
        class="list-group-item d-flex align-items-center border-0 px-0 py-1"
        v-for="(element, index) in alternatives"
        :key="index"
      >
        <i class="icon-menu handle"></i>
        <input type="text" class="form-control mx-2" v-model="element.option" />
        <i class="icon-x close" @click="removeAt(index)"></i>
      </li>
    </draggable>

    <div class="d-flex align-items-center pt-3">
      <input
        type="text"
        class="form-control mr-2"
        v-model="newText"
        placeholder="Adicionar opção"
      />
      <button class="btn btn-primary" @click="add" :disabled="!newText">
        <i class="icon-plus"></i>
      </button>
    </div>
  </div>
</template>

<script>
import Draggable from "vuedraggable";

export default {
  components: { Draggable },
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      newText: ""
    };
  },
  computed: {
    alternatives: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    removeAt(index) {
      this.alternatives.splice(index, 1);
    },
    add() {
      if (this.newText) {
        this.alternatives.push({ option: this.newText });
        this.newText = "";
      }
    }
  }
};
</script>
